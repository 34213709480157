/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBarChart({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M205.985 34.178a13.897 13.897 0 00-9.855-4.177l-.118-.001c-7.646 0-13.919 6.222-13.982 13.87a13.88 13.88 0 002.384 7.934l-41.918 42.025a13.904 13.904 0 00-7.77-2.44h-.118c-2.918 0-5.635.905-7.885 2.45L108.15 75.267a13.892 13.892 0 002.451-7.772c.063-7.712-6.159-14.037-13.87-14.1h-.117c-7.647 0-13.92 6.222-13.983 13.87a13.908 13.908 0 002.442 8.003l-33.19 33.19a13.906 13.906 0 00-7.783-2.448h-.117c-7.647 0-13.92 6.222-13.982 13.87-.064 7.711 6.158 14.036 13.869 14.1h.117c7.647 0 13.92-6.222 13.983-13.87a13.91 13.91 0 00-2.437-7.996l33.193-33.192a13.904 13.904 0 007.775 2.442h.117c2.914 0 5.629-.903 7.877-2.445l18.576 18.576a13.89 13.89 0 00-2.445 7.764c-.064 7.711 6.158 14.037 13.87 14.1l.116.001c7.647 0 13.92-6.223 13.983-13.87a13.899 13.899 0 00-2.445-8.008l41.894-42a13.886 13.886 0 007.855 2.489h.118c7.647 0 13.92-6.222 13.983-13.87a13.9 13.9 0 00-4.015-9.922zM177.986 86.996a4 4 0 014-4h23.999a4 4 0 014 4v107.017l-31.999 2.8V86.996zM161.003 198.298v-57.294a4 4 0 00-4-4h-23.999a4 4 0 00-4 4v60.094l31.999-2.8zM110.997 202.673v-65.685a4 4 0 00-4-4h-24a4 4 0 00-3.999 4v68.485l31.999-2.8zM61.999 206.96v-50.957a4 4 0 00-4-4h-24a4 4 0 00-3.999 4v53.757l31.999-2.8z" />
    </svg>
  );
}

export default SvgIconBarChart;
