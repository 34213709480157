/* eslint-disable max-len */
import * as React from 'react';

function SvgIconKnowledgeInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm51.17 129.904L91.28 180l42.981-3.809-.066-11.061c-.02-3.212 2.936-5.666 9.579-7.965l6.572-2.27c5.259-1.818 8.226-6.085 8.226-11.131v-10.967l11.136-5.94-10.109-17.334c-1.742-2.987-2.617-5.855-2.839-9.298C155.31 77.669 136.274 60 113.425 60 89.481 60 70 79.29 70 102.999a42.753 42.753 0 0010.375 27.89c7.204 8.362 10.735 17.853 10.795 29.015zm5.6-30.393a11.292 11.292 0 01-4.843-1.539l-1.593 1.896-2.933-2.456 1.608-1.912a11.275 11.275 0 01-2.303-4.485l-2.526.222-.333-3.807 2.556-.222c.15-1.678.674-3.3 1.535-4.748l-1.994-1.67 2.46-2.927 2.01 1.683a11.315 11.315 0 014.409-2.275l-.231-2.622 3.814-.335.228 2.624c1.669.135 3.287.639 4.736 1.477l1.687-2.006 2.932 2.456-1.672 1.99a11.275 11.275 0 012.336 4.409l2.557-.224.334 3.807-2.526.221a11.24 11.24 0 01-1.488 4.815l1.917 1.605-2.461 2.926-1.9-1.592a11.267 11.267 0 01-4.502 2.356l.215 2.451-3.814.334-.214-2.452zm28.403-17.14a18.885 18.885 0 01-10.588-3.37l-1.996 2.377-2.846-2.389 2.009-2.394a18.872 18.872 0 01-5.041-9.837l-3.189.28-.324-3.702 3.217-.28a18.88 18.88 0 013.37-10.444l-2.535-2.127 2.388-2.847 2.55 2.142a18.886 18.886 0 019.695-5.015l-.293-3.347 3.702-.325.292 3.347a18.875 18.875 0 0110.418 3.256l2.141-2.55 2.846 2.387-2.127 2.536a18.875 18.875 0 015.131 9.699l3.217-.283.325 3.703-3.192.28a18.873 18.873 0 01-3.255 10.562l2.394 2.009-2.388 2.846-2.378-1.996a18.882 18.882 0 01-9.842 5.158l.268 3.059-3.702.324-.267-3.059zm-9.089-18.151c.443 5.066 4.549 8.896 9.349 8.896.264 0 .529-.012.792-.035 5.133-.449 8.916-5.144 8.448-10.487-.444-5.069-4.548-8.898-9.349-8.898-.264 0-.529.011-.792.035-5.133.447-8.916 5.144-8.448 10.489zm-23.055 24.413c.224 2.543 2.288 4.466 4.7 4.466.134 0 .267-.006.399-.017 2.582-.226 4.484-2.583 4.248-5.266-.222-2.543-2.286-4.465-4.7-4.465a4.45 4.45 0 00-.399.017c-2.58.226-4.483 2.583-4.248 5.265z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconKnowledgeInverse;
