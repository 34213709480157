/* eslint-disable max-len */
import * as React from 'react';

function SvgIconYouTube({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M216.061 71.912c-2.305-8.569-9.06-15.323-17.627-17.63C182.783 50 120.179 50 120.179 50s-62.601 0-78.252 4.12c-8.402 2.305-15.322 9.225-17.627 17.792-4.118 15.65-4.118 48.104-4.118 48.104s0 32.618 4.118 48.104c2.307 8.567 9.06 15.321 17.628 17.629 15.815 4.283 78.254 4.283 78.254 4.283s62.601 0 78.252-4.12c8.568-2.306 15.322-9.06 17.629-17.627 4.118-15.651 4.118-48.104 4.118-48.104s.165-32.62-4.12-48.27zm-115.813 78.087V90.033l52.058 29.983-52.058 29.983z" />
    </svg>
  );
}

export default SvgIconYouTube;
