import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '../ButtonGroup/ButtonGroup';
import ButtonLink from '../ButtonLink';
import SectionWrapper from '../SectionWrapper';
import SelfCheckSegment from '../SelfCheckSegment';
import { camel2Kebab } from '../../utils';

import './SectionSelfCheck.scss';
import SelfCheckEvaluationChart from '../SelfCheckEvaluationChart';

/**
 * @return {html}
 */
const SectionSelfCheck = ({ component }) => {
  const { t } = useTranslation();
  const [activeSegment, setActiveSegment] = useState(0);
  const [evaluation, setEvaluation] = useState(null);
  const [evaluationSuccess, setEvaluationSuccess] = useState(true);
  const [showResults, setShowResults] = useState(false);
  component.theme = 'PacificBlue'; // build in later, time permitting
  const theme = camel2Kebab(component.theme);

  const isAlreadyAnswered = window.location.search.split('=').shift() === '?answers';

  const segmentQuestionsAnswered = (questions) => {
    let answered = true;
    questions.forEach(question => {
      const input = document.querySelector(`input[name="${question.uid}"]:checked`);
      if (!input) {
        document.getElementById(`${question.uid}-wrapper`).classList.add('error');
        answered = false;
      }
    });

    return answered;
  };

  const segmentNext = (questions) => {
    if (!segmentQuestionsAnswered(questions)) {
      return;
    }

    const next = activeSegment + 1;
    if (component.segments[next]) {
      setActiveSegment(next);
    } else {
      setActiveSegment(0);
    }
  };

  const segmentPrev = () => {
    const prev = activeSegment - 1;
    if (component.segments[prev]) {
      setActiveSegment(prev);
    } else {
      setActiveSegment(component.segments.length - 1);
    }
  };

  const evaluate = (questions) => {
    if (!segmentQuestionsAnswered(questions)) {
      return;
    }

    const segments = [...component.segments];
    component.answers = [];

    segments.forEach(segment => {
      segment.success = true;
      segment.questions.forEach(question => {
        const inputValue = document.querySelector(`input[name="${question.uid}"]:checked`).getAttribute('value');
        question.value = inputValue;
        component.answers.push(inputValue);

        if (inputValue === 'no') {
          segment.success = false;
          setEvaluationSuccess(false);
        }
      });
    });

    setEvaluation({ segments });
  };

  if (isAlreadyAnswered) {
    const answers = window.location.search.split('=').pop().split('_');
    component.answers = [...answers];

    component.segments.forEach((segment, i) => {
      segment.questions = [];
      segment.success = true;

      for (let j = 1; j < 6; j += 1) { // has to start from 1 or the question key won't match
        if (segment[`question${j}`]) {
          const value = answers.shift();
          segment.questions.push({
            uid: `self-check-id-${component.id}-segment-${i}-question-${j}`,
            question: segment[`question${j}`],
            value,
          });
          if (value === 'no') {
            segment.success = false;
          }
        }
      }
    });
  }

  return (
    <SectionWrapper
      classes={`sw-section-self-check sw-section-self-check-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-self-check-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
          {
            evaluation ? (
              <div className="h6">{t('self-check.evaluation')}</div>
            ) : (
              <div>
                <ol className="segment-list">
                  {
                    component.segments.map((segment, i) => (
                      <li key={`self-check-segment-label-${i}`} className={`h5 ${activeSegment === i ? 'active' : ''}`.trim()}>
                        {segment.title}
                      </li>
                    ))
                  }
                </ol>
              </div>
            )
          }
        </div>
        <div>
          {
            evaluation || isAlreadyAnswered ? (
              <div className="evaluation-wrapper">
                <SelfCheckEvaluationChart
                  key="self-check-evaluation"
                  segments={component.segments}
                />

                <a href={`${window.location.pathname}?answers=${component.answers.join('_')}`} className="btn btn-pacific-blue">
                  Link zu Ihrem Ergebnis
                </a>

                {
                  showResults && (
                    <div className="evaluation-questions">
                      {
                        component.segments.map((segment, i) => (
                          <div key={i} className="segment">
                            <div className="title">
                              <div>{`${i + 1}.`}</div>
                              <div>{segment.title}</div>
                            </div>
                            {
                              segment.questions.map((question, j) => (
                                <div key={j} className="question">
                                  <div>{question.question}</div>
                                  <div>{t(`self-check.${question.value}`)}</div>
                                </div>
                              ))
                            }
                          </div>
                        ))
                      }
                    </div>
                  )
                }

                <div>
                  <ButtonGroup>
                    {
                      showResults ? (
                        <button type="button" className={`btn btn-${theme}`} onClick={() => setShowResults(false)}><i className="icon icon-x1 icon-chevron-up" /> {t('self-check.results-hide')}</button>
                      ) : (
                        <button type="button" className={`btn btn-${theme}`} onClick={() => setShowResults(true)}><i className="icon icon-x1 icon-chevron-down" /> {t('self-check.results-show')}</button>
                      )
                    }
                  </ButtonGroup>
                </div>

                <div className="evaluation-summary">
                  <div className="strapi-markdown">
                    <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                      {component.summary}
                    </ReactMarkdown>
                  </div>
                </div>

                {
                  evaluationSuccess ? (
                    <>
                      <div className="evaluation-success">
                        <div className="evaluation-success strapi-markdown">
                          <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                            {component.evaluationSuccess}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <div>
                        <ButtonGroup>
                          <ButtonLink text={component.evaluationSuccessLinkText} to={component.evaluationSuccessLinkUrl} />
                        </ButtonGroup>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="evaluation-fail">
                        <div className="evaluation-success strapi-markdown">
                          <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                            {component.evaluationFail}
                          </ReactMarkdown>
                        </div>
                      </div>
                      <div>
                        <ButtonGroup>
                          <ButtonLink text={component.evaluationFailLinkText} to={component.evaluationFailLinkUrl} />
                        </ButtonGroup>
                      </div>
                    </>
                  )
                }
              </div>
            ) : (
              <div>
                {
                  component.introduction && (
                    <div className="introduction strapi-markdown">
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component.introduction}
                      </ReactMarkdown>
                    </div>
                  )
                }
                {
                  component.segments.map((segment, i) => {
                    const questions = [];

                    for (let j = 1; j < 6; j += 1) { // has to start from 1 or the question key won't match
                      if (segment[`question${j}`]) {
                        questions.push({
                          uid: `self-check-id-${component.id}-segment-${i}-question-${j}`,
                          question: segment[`question${j}`],
                          value: null,
                        });
                      }
                    }

                    component.segments[i].questions = questions;

                    return (
                      <SelfCheckSegment
                        key={`self-check-segment-${i}`}
                        segment={segment}
                        active={activeSegment === i}
                        next={(component.segments.length - 1 !== i) ? segmentNext : null}
                        prev={(i !== 0) ? segmentPrev : null}
                        evaluation={(component.segments.length - 1 === i) ? evaluate : null}
                        theme={theme}
                      />
                    );
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionSelfCheck;
