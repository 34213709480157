/* eslint-disable max-len */
import * as React from 'react';

function SvgIconIndustryInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm52.643 95.815l-.015-48.819H60.006v43.545L60 180l119.707-10.842v-43.343H92.643zM88.302 61.09c-1.536-.608-3.462-1.328-6.78-1.013v.003c-3.252.312-5.028 1.346-6.432 2.232l-.516.318c-1.11.695-2.037 1.197-4.056 1.389-2.061.194-3.051-.136-4.29-.628l-.456-.18c-1.371-.542-3.066-1.154-5.772-1.04v6.227l.054-.003c1.698-.099 2.622.204 3.732.642l.654.258c1.236.483 2.736 1.002 4.992 1.002a16.74 16.74 0 001.572-.078c3.216-.309 4.992-1.326 6.39-2.198l.375-.24c1.197-.75 2.112-1.294 4.239-1.5 2.04-.196 3.03.128 4.25.608l.496.199c1.365.54 3.05 1.149 5.739 1.044v-6.226l-.057.004c-1.692.09-2.616-.22-3.732-.66l-.402-.16z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconIndustryInverse;
