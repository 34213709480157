/* eslint-disable consistent-return, no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';

import CardEvent from '../CardEvent';
import CardNews from '../CardNews';
import Carousel from '../Carousel';
import CarouselSlideGeneric from '../CarouselSlideGeneric';
import SectionWrapper from '../SectionWrapper';
import { camel2Kebab } from '../../utils';

import './SectionMultiSelect.scss';

const itemComponentSwitch = (item, index, theme) => {
  switch (item._type) {
    case 'generic':
      return (<CarouselSlideGeneric key={`carousel-slide-generic-${index + 1}`} slide={item} />);
    case 'event':
      return (<CardEvent key={`carousel-slide-event-${index + 1}`} item={item} theme={theme} />);
    case 'newsItem':
      return (<CardNews key={`carousel-slide-news-item-${index + 1}`} newsItem={item} />);
    default:
      return <></>;
  }
};

/**
 * @return {html}
 */
const SectionMultiSelect = ({ component }) => {
  const windowSize = useWindowSize();
  const theme = camel2Kebab(component.theme);
  const [carouselSlides, setCarouselSlides] = useState([]);

  const slideItems = [];
  component.slides.forEach(slide => {
    if (slide.title && slide.url && slide.content && slide.image) {
      const generic = {
        _type: 'generic',
        id: slide.id,
        title: slide.title,
        url: slide.url,
        content: slide.content,
        image: slide.image,
      };

      slideItems.push(generic);
    }

    slide.events.map(event => slideItems.push({ _type: 'event', ...event }));
    slide.newsItems.map(newsItem => slideItems.push({ _type: 'newsItem', ...newsItem }));
  });

  useEffect(() => {
    let slide = [];
    const slides = [];
    if (windowSize.width < 640) {
      slideItems.forEach((item) => {
        slides.push([item]);
      });
    } else {
      slideItems.forEach((item, index) => {
        if (index % 2 === 0) {
          slides.push(slide);
        }

        slide.push(item);
        if (slide.length === 2) {
          slide = [];
        }
      });
    }

    setCarouselSlides(slides);
  }, [windowSize]);

  return (
    <SectionWrapper
      classes={`sw-section-multi-select sw-section-multi-select-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-multi-select-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div>
          {
            (slideItems.length > 2) ? (
              <Carousel theme={theme}>
                {
                  carouselSlides.map((items, id) => (
                    <div key={`items-${id + 1}`} className="grid-1x1fr-2x1fr">
                      {
                        items.map((item, idx) => itemComponentSwitch(item, idx, theme))
                      }
                    </div>
                  ))
                }
              </Carousel>
            ) : (
              <div className="grid-1x1fr-2x1fr">
                {
                  slideItems.map((item, idx) => itemComponentSwitch(item, idx, theme))
                }
              </div>
            )
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionMultiSelect;
