/* eslint-disable max-len */
import * as React from 'react';

function SvgIconInternetExplorerInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm120.663 30h4.674c5.999 1.096 9.831 4.35 10.91 10.384v4.674c-.336 1.248-.615 2.555-.894 3.863-.573 2.683-1.146 5.371-2.219 7.564.092-.546.199-1.139.313-1.768.995-5.51 2.491-13.801-.831-17.973-8.41-10.541-29.748 1.938-36.878 6.236 22.899 7.226 38.606 21.635 36.878 53.505h-66.494c-4.262 19.821 28.09 24.311 31.686 6.753h34.291c-7.691 23.364-28.45 38.422-60.785 33.782-8.726 5.631-17.479 11.27-30.134 12.98h-4.157c-8.814-1.237-12.558-7.516-14.023-16.102v-4.674c1.386-26.845 24.196-57.724 49.349-76.37-15.042 5.567-28.44 21.847-38.874 34.524a543.5 543.5 0 01-4.766 5.74c2.99-31.563 23.18-53.97 55.067-51.69.963.067 1.964.304 2.977.544 1.607.38 3.245.768 4.811.499 2.068-.357 4.673-2.089 7.373-3.883 1.878-1.25 3.803-2.529 5.624-3.396 5.341-2.543 11.717-4.552 16.102-5.192zM73.375 170.897c7.27 10.155 28.809-.342 35.326-4.675-14.26-2.876-24.127-10.155-31.686-19.732-.33.779-.712 1.632-1.122 2.543-2.867 6.384-7.008 15.601-2.518 21.864zm65.345-62.381l-32.203.017c-1.246-19.89 34.326-21.337 32.203-.017z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconInternetExplorerInverse;
