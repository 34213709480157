import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import qs from 'qs';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface IJobs {
  jobs: any[];
  status: string;
  error: string;
}

const initialState: IJobs = {
  jobs: [],
  status: Statuses.idle,
  error: null,
};

export const fetchJobs = createAsyncThunk('jobs/fetchJobs', async () => {
  const todayDate = moment().format('YYYY-MM-DD');

  const query = qs.stringify({
    visibleFrom_lte: todayDate,
  });

  const url = `/jobs?${query}`;
  const response = await ApiService.get(url);
  return response.data;
});

export const JobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched Jobs to the store
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectJobs = state => state.jobs.jobs;

export const selectJobBySlug = slug => state => state.jobs.jobs.find(obj => obj.slug === slug);

export default JobsSlice.reducer;
