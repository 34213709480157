/* eslint-disable max-len */
import * as React from 'react';

function SvgIconInternetExplorer({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M188.517 30h-7.012c-6.577.96-16.141 3.973-24.152 7.788-6.657 3.17-14.234 10.01-19.496 10.918-3.828.658-7.946-1.302-11.682-1.565-47.831-3.42-78.114 30.19-82.6 77.536C60.02 105.26 82.87 73.964 109.034 64.282 71.306 92.25 37.09 138.568 35.011 178.836v7.011c2.197 12.879 7.814 22.298 21.035 24.153h6.236c18.982-2.565 32.11-11.024 45.2-19.469 48.503 6.959 79.641-15.629 91.178-50.674h-51.437c-5.393 26.337-53.922 19.601-47.529-10.129h99.742c2.591-47.805-20.969-69.419-55.317-80.259 10.695-6.446 42.701-25.165 55.317-9.353 5.551 6.972 2.131 21.614.776 29.612 2.394-4.893 3.131-11.432 4.67-17.14v-7.013c-1.618-9.05-7.367-13.93-16.365-15.575zm-84.955 159.333c-9.774 6.499-42.083 22.246-52.988 7.012-7.696-10.734 1.512-27.257 5.459-36.61 11.34 14.365 26.139 25.284 47.529 29.598zm45.03-86.559l-48.306.026c-1.868-29.836 51.489-32.006 48.306-.026z" />
    </svg>
  );
}

export default SvgIconInternetExplorer;
