/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSapInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm102.427 115.592c0-2.205-1.879-3.825-3.993-3.825-2.113 0-3.992 1.62-3.992 3.825 0 2.238 1.879 3.884 3.992 3.884 2.114 0 3.993-1.646 3.993-3.884zm-21.84 3.795L180 90H60v59.387h60.587zm14.795-3.795c0-1.815 1.409-3.198 3.052-3.198 1.644 0 3.053 1.383 3.053 3.198 0 1.884-1.409 3.241-3.053 3.241-1.643 0-3.052-1.357-3.052-3.241zm4.931-.93c0-.885-.704-1.275-1.644-1.275h-1.644v4.443h.705v-1.916h.704l1.175 1.916h.704l-1.174-1.954c.47-.08 1.174-.425 1.174-1.214zm-2.583.679v-1.388h.939c.235 0 .94.108.94.669 0 .661-.678.717-1.147.719h-.732zm-73.268-40.49c2.583-2.694 6.81-4.319 11.976-4.319l.161.001c4.197.035 9.283 1.252 13.225 3.162l-4.227 7.156c-3.57-1.701-5.631-2.105-7.6-2.262l-.13-.01-.065-.004-.13-.009-.064-.005-.21-.013-.14-.008-.14-.008-.14-.007-.07-.004c-3.523-.188-5.166 1.071-5.166 2.443-.235 1.653 3.287 3.165 6.34 4.194 4.932 1.531 11.037 3.576 11.977 9.494l8.923-23.493h10.098l10.333 28.091v-28.091l11.742.003c11.506 0 16.673 4.027 16.673 12.8 0 7.881-5.401 12.58-14.56 12.58h-3.992v11.341h-17.378l-1.878-5.423a19.312 19.312 0 01-6.106.951c-2.348 0-4.462-.366-6.34-.995l-1.644 5.467H85.127l1.174-3.154c-.12.06-.178.12-.238.181-.058.058-.116.118-.231.18-2.583 2.156-6.106 3.342-10.098 3.417l-.705.005c-4.696 0-9.393-1.32-13.385-3.565l3.757-7.244c3.992 2.331 6.34 2.855 9.628 2.794 1.879-.04 3.053-.359 3.993-1.202.47-.51.704-1.144.704-1.841 0-1.888-2.583-2.769-5.87-3.786-2.819-.824-5.637-1.949-8.22-3.675-2.583-2.05-3.992-4.624-3.992-8.252 0-2.637 1.174-5.016 2.818-6.89zm43.209 19.726l-3.757-11.854-3.757 11.816c1.174.428 2.348.672 3.757.672 1.409 0 2.583-.23 3.757-.634zm30.529-10.974c0-3.562-2.818-4.715-6.341-4.715h-2.583v9.527h2.583c3.523 0 6.341-1.148 6.341-4.812z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSapInverse;
