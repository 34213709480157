/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSecurityInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm79.733 150S70 163.145 70 113.79V72.433L119.733 60l49.732 12.433v41.357c0 49.355-49.732 66.21-49.732 66.21z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSecurityInverse;
