import React from 'react';

import CardNews from '../CardNews';
import SectionWrapper from '../SectionWrapper';

// store
import store from '../../state/store';
import { fetchNewsItems, selectNewsItems } from '../../state/store/slices/newsItemsSlice';
import { useAppSelector } from '../../state/hooks';

import './SectionNewsItems.scss';

store.dispatch(fetchNewsItems());

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionNewsItems = ({ component }: IProps) => {
  const newsItems = component.newsItems.length ? [...component.newsItems].sort((item1, item2) => {
    if (item1.date < item2.date) return 1;
    if (item1.date > item2.date) return -1;
    return 0;
  }) : useAppSelector(selectNewsItems);

  if (!newsItems.length) {
    return <></>;
  }

  return (
    <SectionWrapper
      classes="sw-section-news-items"
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-news-items-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className="grid-1x1fr-2x1fr">
          {
            newsItems.map(newsItem => (<CardNews key={newsItem.id} newsItem={newsItem} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionNewsItems;
