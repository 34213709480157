import React, { useEffect, useState } from 'react';

import { useInView } from 'react-intersection-observer';

import './CountUp.scss';

const CountUp = ({
  counter, duration = 2500,
}) => {
  const { count, title, percentage } = counter;
  const [state, setState] = useState(0);
  const delayInMillisecondsPerNumber = duration / count;
  const [inViewRef, entry] = useInView({ threshold: 0.5, triggerOnce: true });

  useEffect(() => {
    const interval = setInterval(() => {
      if (state < count && entry) {
        setState(state + 1);
      } else {
        clearInterval(interval);
      }
    }, delayInMillisecondsPerNumber);
    return () => clearInterval(interval);
  }, [entry, state]);

  return (
    <div ref={inViewRef} className="sw-count-up">
      <div className="count">{state}{percentage && '%'}</div>
      <div className="label">{title}</div>
    </div>
  );
};

export default CountUp;
