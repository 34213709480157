/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDeadline({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M61.043 194.961c-7.151-6.401-13.255-13.936-17.987-22.345-7.1-12.61-11.145-27.148-11.145-42.625 0-48.288 39.384-87.433 87.967-87.433s87.968 39.145 87.968 87.433c0 12.932-2.827 25.208-7.898 36.253l-9.255-4.192 1.412 35.756 28.077-22.399-9.421-4.267c5.66-12.55 8.814-26.481 8.814-41.152 0-55.222-44.636-99.99-99.697-99.99-55.06 0-99.696 44.768-99.696 99.991 0 17.466 4.465 33.885 12.313 48.175a100.3 100.3 0 0021.246 26.622v.015c.124.111.255.215.38.326.066.058.13.119.197.177l.004-.004A93.747 93.747 0 0060.063 210l7.532-9.743c-.287-.217-1.397-1.09-1.681-1.31-1.639-1.27-4.87-3.986-4.87-3.986zm58.786 9.468c41.585.001 75.296-33.475 75.296-74.768 0-41.294-33.711-74.769-75.296-74.769-41.585 0-75.296 33.475-75.296 74.769 0 41.293 33.711 74.769 75.296 74.768zm-7.411-122.965h16.317v48.513l22.201 27.249-12.681 10.205-25.837-31.711.296-.238-.296-.001V81.464z" />
    </svg>
  );
}

export default SvgIconDeadline;
