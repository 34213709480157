import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface IJobs {
  publications: any[];
  status: string;
  error: string;
}

const initialState: IJobs = {
  publications: [],
  status: Statuses.idle,
  error: null,
};

export const fetchPublications = createAsyncThunk('publications/fetchPublications', async () => {
  const url = '/publications';
  const response = await ApiService.get(url);
  return response.data;
});

export const PublicationsSlice = createSlice({
  name: 'publications',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPublications.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchPublications.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched news items to the store
        state.publications = action.payload;
      })
      .addCase(fetchPublications.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectPublications = state => state.publications.publications;
export const selectRecentPublications = count => state => state.publications.publications.slice(0, count);
export const selectPublicationBySlug = slug => state => state.publications.publications.find(obj => obj.slug === slug);

export default PublicationsSlice.reducer;
