/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDotsInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm135.607 100.607c5.857-5.858 5.857-15.356 0-21.214-5.858-5.857-15.356-5.857-21.214 0-5.857 5.858-5.857 15.356 0 21.214 5.858 5.858 15.356 5.858 21.214 0zm-45 0c5.858-5.858 5.858-15.356 0-21.214-5.858-5.857-15.356-5.857-21.213 0-5.858 5.858-5.858 15.356 0 21.214 5.857 5.858 15.355 5.858 21.213 0zm-45 0c5.858-5.858 5.858-15.356 0-21.214-5.858-5.857-15.356-5.857-21.214 0-5.858 5.858-5.858 15.356 0 21.214 5.858 5.858 15.356 5.858 21.214 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDotsInverse;
