/* eslint-disable max-len */
import * as React from 'react';

function SvgIconScriptInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm131.25 40H68.75c-4.8 0-8.75 3.95-8.75 8.75v67.5c0 4.8 3.95 8.75 8.75 8.75h38.29c-.59 2.505-2.145 6.57-6.31 10.73a2.494 2.494 0 00-.54 2.725A2.5 2.5 0 00102.5 170h35a2.5 2.5 0 002.31-1.545c.385-.935.17-2.01-.54-2.725-4.155-4.155-5.755-8.22-6.375-10.73h38.355c4.8 0 8.75-3.95 8.75-8.75v-67.5c0-4.8-3.95-8.75-8.75-8.75zM170 135H70V80h100v55zm-57.405-14.586a3.754 3.754 0 002.815 4.495 3.748 3.748 0 004.495-2.815l7.5-32.5a3.754 3.754 0 00-2.815-4.495c-2.02-.44-4.03.795-4.495 2.815l-7.5 32.5zm-8.852-2.92a3.753 3.753 0 01-2.85-1.31l-7.5-8.75a3.752 3.752 0 010-4.885l7.5-8.75a3.743 3.743 0 015.29-.4 3.753 3.753 0 01.405 5.29l-5.405 6.305 5.41 6.31a3.753 3.753 0 01-.405 5.29c-.71.6-1.58.9-2.445.9zm30.06-.906c.71.61 1.575.905 2.44.905a3.735 3.735 0 002.845-1.31l7.5-8.75a3.752 3.752 0 000-4.885l-7.5-8.75a3.753 3.753 0 00-5.29-.405 3.753 3.753 0 00-.405 5.29l5.41 6.31-5.405 6.305a3.753 3.753 0 00.405 5.29z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconScriptInverse;
