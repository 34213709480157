import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

import './SelfCheckEvaluationChart.scss';

/**
 * @return {html}
 */
const SelfCheckEvaluationChart = ({ segments = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="evaluation">
      {
        segments.map((segment, i) => (
          <div key={i} className="segment">
            <div className="title">{`${i + 1}. ${segment.title}`}</div>
            {
              segment.success ? (
                <>
                  <div>
                    <Icon name="Fulfilled" color="#000" width="4rem" height="4rem" />
                  </div>
                  <div className="success">
                    {t('self-check.evaluation-success')}
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Icon name="Deadline" color="#ff0d00" width="4rem" height="4rem" />
                  </div>
                  <div className="fail">
                    {t('self-check.evaluation-fail')}
                  </div>
                </>
              )
            }
          </div>
        ))
      }
    </div>
  );
};

export default SelfCheckEvaluationChart;
