/* eslint-disable max-len */
import * as React from 'react';

function SvgIconNews({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M181.371 87.905l22.203-10.392-3.431-7.381-22.202 10.391 3.43 7.382zm-14.19-23.602l15.73-18.8-6.229-5.24-15.73 18.8 6.229 5.24zm14.952 83.353L132.9 42.075s-3.304-7.087-5.989.257l-17.496 47.863-74.712 34.838s-2.83 1.322-4.078 4.083c-.764 1.62-.987 3.746.253 6.407l14.117 30.274s3.305 7.086 10.392 3.782l15.643-7.295v39.473l24.524-2.145v-48.764l34.608-16.138 47.925 17.368s7.351 2.664 4.046-4.422zm3.353-40.313l-.01 8.141 24.513-.035.011-8.14-24.514.034z" />
    </svg>
  );
}

export default SvgIconNews;
