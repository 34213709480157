/* eslint-disable max-len */
import * as React from 'react';

function SvgIconLinkedIn({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M210.137 210v-.008h.045v-66.015c0-32.295-6.953-57.172-44.708-57.172-18.15 0-30.33 9.96-35.302 19.402h-.525V89.82H93.849v120.172h37.275v-59.505c0-15.667 2.97-30.817 22.373-30.817 19.117 0 19.402 17.88 19.402 31.822V210h37.238zM33.151 89.828h37.32V210h-37.32V89.828zM51.797 30c-11.933 0-21.615 9.682-21.615 21.615 0 11.932 9.682 21.818 21.615 21.818 11.932 0 21.615-9.886 21.615-21.818C73.404 39.682 63.722 30 51.797 30z" />
    </svg>
  );
}

export default SvgIconLinkedIn;
