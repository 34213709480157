/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTargetInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm123.354 30l-19.319 19.322 1.382 8.745-34.42 34.425a11.15 11.15 0 00-3.989-.734c-6.193 0-11.226 5.039-11.226 11.227 0 6.189 5.033 11.231 11.226 11.231 6.184 0 11.222-5.042 11.222-11.231 0-1.82-.435-3.541-1.208-5.063l34.115-34.12 8.762 1.383 19.324-19.322-13.7-2.165L163.354 60zm-22.252 54.245a38.721 38.721 0 014.821 18.739c0 21.459-17.455 38.911-38.911 38.911-21.457 0-38.912-17.452-38.912-38.911 0-21.456 17.455-38.907 38.912-38.907 6.632 0 12.88 1.673 18.354 4.61l5.934-5.935a46.741 46.741 0 00-24.288-6.775C81.088 85.977 60 107.064 60 132.984 60 158.91 81.088 180 107.012 180c25.917 0 47.011-21.09 47.011-47.016a46.744 46.744 0 00-7.008-24.653l-5.913 5.914zm-13.212 18.736c0-1.697-.224-3.341-.61-4.927l6.403-6.401a28.885 28.885 0 012.305 11.328c0 15.985-13 28.991-28.982 28.991-15.983 0-28.988-13.006-28.988-28.991 0-15.981 13.005-28.986 28.988-28.986 3.855 0 7.525.772 10.895 2.146l-6.443 6.444a20.743 20.743 0 00-4.452-.492c-11.517 0-20.89 9.371-20.89 20.888 0 11.518 9.373 20.883 20.89 20.883 11.516 0 20.884-9.365 20.884-20.883z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconTargetInverse;
