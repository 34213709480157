const person2Team = person => {
  const team = {
    name: person.name,
    people: [
      person,
    ],
  };

  return team;
};

export default person2Team;
