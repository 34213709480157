import React from 'react';

import * as Icons from './icons';

const Icon = ({ name, ...rest }) => {
  if (Icons[`Icon${name}`]) {
    const Component = Icons[`Icon${name}`];
    return <Component fill="currentColor" {...rest} />;
  }
  return <></>;
};

export default Icon;
