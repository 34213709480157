import config from '../App.config';

const isExternalLink = to => {
  const externalLinkIndicators = ['http', 'https', 'tel:', 'mailto:', '//'];

  if (config.api.relativeUrl) externalLinkIndicators.push(config.api.relativeUrl);

  return externalLinkIndicators.some(indicator => to.startsWith(indicator));
};

export default isExternalLink;
