/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPadlockListInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm62.852 70.681V91c0-9.624 7.823-17.446 17.446-17.446 9.623 0 17.446 7.822 17.446 17.445v9.672h13.554v-9.672c0-4.182-.823-8.238-2.44-12.072a30.897 30.897 0 00-6.641-9.856 30.796 30.796 0 00-9.856-6.641A30.796 30.796 0 00120.298 60c-4.182 0-8.239.823-12.072 2.44a30.884 30.884 0 00-9.856 6.641 30.783 30.783 0 00-6.641 9.856 30.85 30.85 0 00-2.44 12.072v9.672h13.563zm54.006 5.857H83.745A7.743 7.743 0 0076 114.283V180l88.603-7.755v-57.962a7.743 7.743 0 00-7.745-7.745zm-60.74 53.47a4.735 4.735 0 11.001-9.47 4.735 4.735 0 01-.001 9.47zm0-15.829a4.735 4.735 0 11.001-9.47 4.735 4.735 0 01-.001 9.47zm0-15.838a4.735 4.735 0 010-9.469 4.736 4.736 0 010 9.469zm53.837 29.712h-41.958v-5.538h41.958v5.538zm0-15.839h-41.958v-5.538h41.958v5.538zm0-15.829h-41.958v-5.538h41.958v5.538z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPadlockListInverse;
