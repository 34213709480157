/* eslint-disable max-len */
import * as React from 'react';

function SvgIconRadiationInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm112.124 144.225l-23.241-66.036.006-.003-.75.006a22.22 22.22 0 01-7.704 1.365 22.255 22.255 0 01-6.399-.93L88.692 180l63.432-5.775zM78.75 63.171L73.272 60C68.646 68.007 66 77.304 66 87.219a54.257 54.257 0 007.272 27.219l5.478-3.171a47.96 47.96 0 01-6.423-24.048 47.939 47.939 0 016.423-24.048zm96.126 24.048A54.26 54.26 0 00167.607 60l-5.481 3.171c4.086 7.074 6.42 15.288 6.42 24.048a47.945 47.945 0 01-6.42 24.048l5.481 3.171c4.623-8.007 7.269-17.304 7.269-27.219zM132.29 75.397a16.718 16.718 0 10-23.644 23.643 16.718 16.718 0 0023.644-23.643zm-34.478-1.24l-5.034-2.907h-.003A31.78 31.78 0 0088.5 87.219a31.772 31.772 0 004.275 15.969l5.037-2.907a26.006 26.006 0 01-3.498-13.062 25.991 25.991 0 013.498-13.062zm50.29-2.906l-.001-.001h.003l-.002.001zm4.274 15.968a31.779 31.779 0 00-4.274-15.968l-5.038 2.906a25.99 25.99 0 013.495 13.062 26 26 0 01-3.498 13.059l5.04 2.91a31.789 31.789 0 004.275-15.969z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconRadiationInverse;
