/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMenu({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={20.167}
        d="M30.182 188h180m-180-135h180-180zm0 67.5h180-180z"
      />
    </svg>
  );
}

export default SvgIconMenu;
