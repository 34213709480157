import React from 'react';
import { Helmet } from 'react-helmet-async';

// Utils
import { apiBaseUrl, formatUrl, nullIfWhiteSpace } from '../../utils';

const getActualImageUrl = (image) => {
  let actualImageUrl = nullIfWhiteSpace(image?.url);

  if (actualImageUrl) actualImageUrl = `${apiBaseUrl()}${actualImageUrl}`;

  return actualImageUrl;
};

const getActualRedirectUrl = (meta) => {
  let actualRedirectUrl = nullIfWhiteSpace(meta.redirectUrl);

  if (actualRedirectUrl) actualRedirectUrl = formatUrl(actualRedirectUrl);

  return actualRedirectUrl;
};

const HeaderHelmet = ({ meta, header, mainMenu }) => {
  const actualTitle = nullIfWhiteSpace(meta.title);
  const actualDescription = nullIfWhiteSpace(meta.description);
  const actualKeywords = nullIfWhiteSpace(meta.keywords);
  const actualRedirectUrl = getActualRedirectUrl(meta);
  const actualImageUrl = getActualImageUrl(header?.image);

  return (
    <Helmet>
      {actualTitle && <title>{actualTitle}</title>}
      {actualDescription && <meta name="description" content={actualDescription} />}
      {actualKeywords && <meta name="keywords" content={actualKeywords} />}
      {actualRedirectUrl && <meta httpEquiv="refresh" content={`${meta.redirectSeconds ?? '0'}; url=${actualRedirectUrl}`} />}

      <meta property="og:type" content="website" />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:locale:alternate" content="en_GB" />
      {actualImageUrl && <meta property="og:image" content={actualImageUrl} />}
      {actualTitle && <meta property="og:title" content={actualTitle} />}
      {actualDescription && <meta property="og:description" content={actualDescription} />}

      <meta name="twitter:card" content={actualImageUrl ? 'summary_large_image' : 'summary'}></meta>
      {actualImageUrl && <meta property="twitter:image" content={actualImageUrl} />}
      {actualTitle && <meta property="twitter:title" content={actualTitle} />}
      {actualDescription && <meta property="twitter:description" content={actualDescription} />}

      <link rel="icon" type="image/png" href={mainMenu?.favicon ? `${apiBaseUrl()}${mainMenu.favicon.url}` : '/favicon-96x96.png'} />
    </Helmet>
  );
};

export default HeaderHelmet;
