/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCheckList({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M110.236 93.631h54.389c7.151 0 7.151-11.097 0-11.097h-54.389c-7.151 0-7.151 11.097 0 11.097zm54.389 19.606h-54.389c-7.151 0-7.151 11.097 0 11.097h54.389c7.151 0 7.151-11.097 0-11.097zm0 28.777h-54.389c-7.151 0-7.151 11.097 0 11.097h54.389c7.151 0 7.151-11.097 0-11.097zm0 30.699h-54.389c-7.151 0-7.151 11.097 0 11.097h54.389c7.151 0 7.151-11.097 0-11.097zM86.04 76.053l-1.575 2.278c-1.927 2.77-3.911 5.61-5.576 8.602a1.641 1.641 0 01-.08-.089l-1.02-1.18c-1.936-2.211-5.669-1.479-6.876 1.185a4.23 4.23 0 00.675 4.58l1.083 1.257c1.393 1.62 2.845 3.302 4.483 4.811a4.333 4.333 0 002.912 1.146 4.306 4.306 0 004.057-2.97c1.411-4.226 4.328-8.35 7.142-12.34.648-.928 1.3-1.838 1.917-2.739a3.918 3.918 0 00.284-4.101c-1.375-2.615-5.668-3.01-7.426-.44zm-2.663 30.233l-1.576 2.282c-1.935 2.765-3.91 5.61-5.584 8.602l-.07-.089-1.03-1.185c-1.927-2.206-5.66-1.469-6.867 1.185-.345.748-.465 1.579-.345 2.393a4.24 4.24 0 001.02 2.193l1.082 1.252c1.394 1.624 2.837 3.306 4.484 4.811a4.274 4.274 0 002.903 1.154 4.316 4.316 0 004.066-2.974c1.411-4.225 4.319-8.349 7.133-12.34.657-.927 1.3-1.842 1.917-2.738a3.928 3.928 0 00.293-4.102c-1.376-2.614-5.673-3.014-7.426-.444zm0 30.943l-1.576 2.282c-1.935 2.77-3.91 5.61-5.584 8.602l-.07-.089-1.03-1.18c-1.927-2.211-5.66-1.474-6.867 1.185a4.23 4.23 0 00.674 4.581l1.083 1.256c1.394 1.62 2.837 3.302 4.484 4.812a4.305 4.305 0 002.903 1.145c1.837 0 3.466-1.199 4.066-2.974 1.411-4.221 4.319-8.35 7.133-12.336a214.46 214.46 0 001.917-2.738 3.921 3.921 0 00.293-4.102c-1.376-2.61-5.673-3.009-7.426-.444zm0 31.591l-1.576 2.282c-1.935 2.765-3.91 5.611-5.584 8.602l-.07-.088-1.03-1.19c-1.927-2.211-5.66-1.474-6.867 1.19a4.237 4.237 0 00.675 4.585l1.082 1.247c1.394 1.625 2.837 3.312 4.484 4.812a4.274 4.274 0 002.903 1.154 4.316 4.316 0 004.066-2.974c1.411-4.221 4.319-8.349 7.133-12.336.657-.927 1.3-1.842 1.917-2.738a3.925 3.925 0 00.293-4.102c-1.376-2.61-5.673-3.018-7.426-.444z" />
      <path d="M171.315 22.82a10.002 10.002 0 00-6.961-2.82H53c-5.523 0-10 4.477-10 10v190h154.023V51.98c0-2.706-1.097-5.296-3.039-7.18l-22.669-21.98zm12.391 183.859H56.316V33.316h105.232v21.13h22.158v152.233z" />
    </svg>
  );
}

export default SvgIconCheckList;
