/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBird({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M138.649 40c.607.166 1.209.332 1.806.5l1.187.335.589.168 1.165.338 1.152.34c.573.17 1.14.342 1.702.514l1.117.344c.74.23 1.471.462 2.192.695l1.075.35c.534.175 1.063.351 1.586.528l1.041.355c1.894.651 3.717 1.313 5.47 1.983l.949.366c9.579 3.737 16.989 7.758 22.231 12.064l.511.422.501.422c.165.14.329.28.491.42l.481.418c.239.208.473.416.704.624l.458.414.447.413.22.205.433.41c.285.274.564.546.836.817l.404.405c.465.473.91.942 1.335 1.408l.36.398.35.398c3.738 4.293 5.76 8.313 6.067 12.06l24.673 16.338h-24.673c-.127.346-.25.707-.368 1.084l-.116.381c-.039.129-.076.26-.113.392l-.11.402-.107.412-.104.422-.1.433-.098.443-.047.225-.092.458-.09.468-.086.478-.042.243-.081.494-.078.503-.038.256-.073.519-.036.264-.068.534-.033.271-.064.55-.061.559-.057.57-.054.581-.051.59-.048.6-.045.611-.041.621-.02.314-.036.637-.034.646-.03.656-.014.333-.025.671-.023.682-.019.693-.016.702-.013.713-.009.722-.007.733-.003.743v.708l.003.672.008 1.026.014 1.048.006.354.013.715.016.725.029 1.105.022.749.038 1.142.028.773.031.783.051 1.192.037.807.06 1.229.043.831.046.841.048.85.076 1.294.111 1.758.09 1.344.095 1.366.101 1.388.143 1.884.113 1.438.119 1.46.125 1.482.13 1.503.183 2.038.192 2.077.202 2.116.158 1.611.164 1.634.055.549.013.42.021.832c.028 1.238.037 2.436.027 3.593l-.01.766c-.07 4.305-.412 8.036-1.025 11.193a40.657 40.657 0 01-4.749 12.664L20.182 200 138.649 40zm32.628 33.134c-2.904 0-5.258 2.31-5.258 5.159 0 2.849 2.354 5.158 5.258 5.158s5.259-2.31 5.259-5.158c0-2.85-2.355-5.159-5.259-5.159z" />
    </svg>
  );
}

export default SvgIconBird;
