import React from 'react';
import CarouselContainer from './CarouselContainer';
import CarouselChild from './CarouselChild';

const Carousel = ({ children = [], theme = 'default' }) => (
  <CarouselContainer theme={theme} interval={999999}>
    {
      children.map((child, index) => (
        <CarouselChild key={`key-${index + 1}`}>
          {child}
        </CarouselChild>
      ))
    }
  </CarouselContainer>
);

export default Carousel;
