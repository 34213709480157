/* eslint-disable max-len */
import * as React from 'react';

function SvgIconXmlInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm135.432 62.474L144.86 60.707a2.308 2.308 0 00-1.663-.707H97.633c-5.1 0-9.248 4.147-9.248 9.244v32.295H69.919c-3.815 0-6.919 3.102-6.919 6.917v34.627c0 3.814 3.104 6.917 6.919 6.917h18.466v20.772c0 5.088 4.148 9.228 9.248 9.228h69.196c5.099 0 9.248-4.137 9.248-9.223V94.075c0-.597-.231-1.17-.645-1.6zM143.769 66.23l25.097 26.078h-25.097V66.23zm-73.85 79.155a2.305 2.305 0 01-2.303-2.302v-34.627a2.305 2.305 0 012.303-2.302h62.316a2.305 2.305 0 012.304 2.302v34.627a2.306 2.306 0 01-2.304 2.302H69.919zm101.542 25.392c0 2.541-2.078 4.608-4.632 4.608H97.633c-2.555 0-4.633-2.069-4.633-4.613V150h39.235c3.815 0 6.919-3.103 6.919-6.917v-34.627c0-3.815-3.104-6.917-6.919-6.917H93V69.244a4.636 4.636 0 014.633-4.629h41.521v30a2.307 2.307 0 002.307 2.308h30v73.854zm-90.722-46.654l-4.928-7.336h-4.228l6.888 10.08-6.664 9.8h4.228l4.704-7.056 4.676 7.056h4.2l-6.664-9.8 6.888-10.08h-4.2l-4.9 7.336zm27.313-.504v13.048h3.864v-19.88H107.8l-5.936 11.312-5.88-11.312H91.84v19.88h3.864v-13.048l5.096 9.716h2.156l5.096-9.716zm8.015-6.832v19.88h14v-3.388h-10.136v-16.492h-3.864z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconXmlInverse;
