/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExclamationMarkInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm91.543 107.399H108V63a3 3 0 013-3h17.544a3 3 0 013 3l-.001 74.399zM108 180l23.543-2.06.001-20.59H108V180z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconExclamationMarkInverse;
