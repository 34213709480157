import React from 'react';

import Image from '../Image';

import './ContactPerson.scss';

/**
 * @return {html}
 */
const ContactPerson = ({ person }) => (
  <div className="sw-contact-person">
    <Image
      alternativeText={person.image?.alternativeText}
      caption={person.image?.caption}
      image={person.image?.formats.small || person.image?.formats.thumbnail}
    />
  </div>
);

export default ContactPerson;
