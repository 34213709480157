/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDeadlineInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm33.724 121.57a52.7 52.7 0 0010.793 13.406s1.94 1.63 2.922 2.392l.33.257c.255.201.569.446.679.529L83.929 174a56.188 56.188 0 01-3.445-2.819l-.002.003-.061-.055a5.127 5.127 0 00-.164-.143l-.122-.104v-.009A60.195 60.195 0 0167.388 154.9C62.68 146.326 60 136.474 60 125.994 60 92.861 86.781 66 119.818 66s59.818 26.86 59.818 59.994c0 8.803-1.892 17.161-5.289 24.691l5.653 2.561-16.846 13.439-.847-21.454 5.553 2.515a51.997 51.997 0 004.739-21.752c0-28.972-23.631-52.459-52.781-52.459s-52.78 23.487-52.78 52.459a51.97 51.97 0 006.686 25.576zm91.242-25.774c0 24.776-20.227 44.862-45.177 44.862-24.951 0-45.178-20.086-45.178-44.862 0-24.776 20.227-44.861 45.178-44.86 24.95 0 45.177 20.084 45.177 44.86zm-39.834-28.918h-9.79v32.411h.177l-.177.143 15.502 19.027 7.609-6.124-13.321-16.349V96.878z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDeadlineInverse;
