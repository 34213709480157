/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTestTubes({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M114.008 30v8.474h-8.482v145.62A25.97 25.97 0 0179.556 210a25.967 25.967 0 01-25.969-25.529V38.473h-8.586V30h69.007zm81 0v8.474h-8.581v136.719a25.964 25.964 0 01-25.974 25.901 25.962 25.962 0 01-25.965-25.524l-.005-.377V38.473h-8.482V30h69.007zm-99 9H63.006v77.999h33.002V39zm81 0h-33.003v42.282h33.003V39z" />
    </svg>
  );
}

export default SvgIconTestTubes;
