/* eslint-disable max-len */
import * as React from 'react';

function SvgIconLinkedInInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm20 44.41C60 66.455 66.455 60 74.41 60c7.95 0 14.405 6.455 14.41 14.41 0 7.955-6.455 14.545-14.41 14.545S60 82.365 60 74.41zM179.97 180v-.005h.03v-44.01c0-21.53-4.635-38.115-29.805-38.115-12.1 0-20.22 6.64-23.535 12.935h-.35V99.88h-23.865v80.115h24.85v-39.67c0-10.445 1.98-20.545 14.915-20.545 12.745 0 12.935 11.92 12.935 21.215V180h24.825zM61.98 99.885h24.88V180H61.98V99.885z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconLinkedInInverse;
