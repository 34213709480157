const iconList = [
  'Accompany',
  'AccompanyInverse',
  'Apple',
  'AppleInverse',
  'BarChart',
  'BarChartInverse',
  'Bird',
  'BirdInverse',
  'Budget',
  'BudgetInverse',
  'Calendar',
  'CalendarInverse',
  'CellPhone',
  'CellPhoneInverse',
  'Chain',
  'ChainInverse',
  'CheckList',
  'CheckListInverse',
  'Coins',
  'CoinsInverse',
  'Contract',
  'ContractInverse',
  'DataAnalytics',
  'DataAnalyticsInverse',
  'DataDisruption',
  'DataManagement',
  'DataManagementInverse',
  'DataTheft',
  'Database',
  'DatabaseInverse',
  'Deadline',
  'DeadlineInverse',
  'Decrease',
  'DecreaseInverse',
  'Dialog',
  'DialogInverse',
  'Directions',
  'DirectionsInverse',
  'Dots',
  'DotsInverse',
  'Excel',
  'ExcelInverse',
  'ExclamationMark',
  'ExclamationMarkInverse',
  'Experience',
  'ExperienceInverse',
  'ExternalLink',
  'ExternalLinkInverse',
  'Fingerprint',
  'FingerprintInverse',
  'Fulfilled',
  'FulfilledInverse',
  'Gears',
  'GearsInverse',
  'HeartPlus',
  'HeartPlusInverse',
  'Idea',
  'IdeaInverse',
  'Industry',
  'IndustryInverse',
  'Instagram',
  'Internet',
  'InternetExplorer',
  'InternetExplorerInverse',
  'InternetInverse',
  'Knowledge',
  'KnowledgeInverse',
  'Location',
  'LocationInverse',
  'Mentoring',
  'MentoringInverse',
  'Milestone',
  'MilestoneInverse',
  'News',
  'NewsInverse',
  'Outlook',
  'OutlookInverse',
  'PadlockList',
  'PadlockListInverse',
  'Party',
  'PartyInverse',
  'Pdf',
  'PdfInverse',
  'Person',
  'PersonInverse',
  'Phone',
  'PhoneInverse',
  'Print',
  'Public',
  'PublicInverse',
  'PublicTransport',
  'PublicTransportInverse',
  'Quality',
  'QualityInverse',
  'QuestionMark',
  'QuestionMarkInverse',
  'Radiation',
  'RadiationInverse',
  'Sabotage',
  'Sap',
  'SapInverse',
  'Script',
  'ScriptInverse',
  'Search',
  'SearchInverse',
  'Security',
  'SecurityInverse',
  'Share',
  'Spring',
  'SpringInverse',
  'Startup',
  'StartupInverse',
  'Structure',
  'StructureInverse',
  'Syncwork',
  'SyncworkInverse',
  'SystemOverload',
  'Target',
  'TargetInverse',
  'TeamEvents',
  'TeamEventsInverse',
  'Technology',
  'TechnologyInverse',
  'TestTubes',
  'TestTubesInverse',
  'Unfulfilled',
  'UnfulfilledInverse',
  'Word',
  'WordInverse',
  'Xml',
  'XmlInverse',
];

export default iconList;
