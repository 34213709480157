import React from 'react';

import CardPublication from '../CardPublication';
import SectionWrapper from '../SectionWrapper';
import { camel2Kebab } from '../../utils';

// store
import store from '../../state/store';
import { fetchPublications, selectPublications } from '../../state/store/slices/publicationsSlice';
import { useAppSelector } from '../../state/hooks';

import './SectionPublication.scss';

store.dispatch(fetchPublications());

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionPublication = ({ component }: IProps) => {
  const publications = component.publications.length ? component.publications : useAppSelector(selectPublications);
  const theme = camel2Kebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-publication sw-section-publication-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-icon-caption-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className="grid-1x1fr-2x1fr-3x1fr">
          {
            publications.map(publication => (<CardPublication key={publication.id} card={publication} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionPublication;
