/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSearchInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm125.087 82.543a52.318 52.318 0 01-11.276 32.526l21.818 21.818a6.182 6.182 0 01-8.742 8.742l-21.819-21.818a52.315 52.315 0 01-32.525 11.276C83.524 165.087 60 141.562 60 112.543S83.525 60 112.543 60c29.019 0 52.544 23.525 52.544 52.543zm-52.544 40.184c22.193 0 40.183-17.99 40.183-40.184 0-22.192-17.99-40.182-40.183-40.182-22.192 0-40.183 17.99-40.183 40.182 0 22.194 17.99 40.184 40.183 40.184z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSearchInverse;
