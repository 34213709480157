/* eslint-disable max-len */
import * as React from 'react';

function SvgIconWordInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm87.5 49.005h48.75c2.07 0 3.75 1.68 3.75 3.75v75c0 2.07-1.68 3.75-3.75 3.75H127.5v11.25c0 1.11-.495 2.175-1.357 2.888a3.739 3.739 0 01-3.083.795l-60-11.25a3.746 3.746 0 01-3.06-3.683v-82.5a3.74 3.74 0 013.06-3.682l60-11.25c1.08-.225 2.227.082 3.083.795a3.741 3.741 0 011.357 2.887v11.25zm-18.772 60.42l3.75-33.757a3.755 3.755 0 00-3.316-4.14c-2.002-.218-3.907 1.252-4.14 3.307L103.005 123l-5.745-15.315c-1.103-2.925-5.933-2.925-7.028 0l-6.097 16.268-1.672-11.73a3.745 3.745 0 00-4.245-3.18 3.747 3.747 0 00-3.18 4.237l3.75 26.25a3.742 3.742 0 003.307 3.203c1.74.18 3.315-.803 3.915-2.408l7.74-20.64 7.74 20.64a3.76 3.76 0 003.975 2.4 3.752 3.752 0 003.263-3.3zm18.772 14.58h45v-67.5h-45v7.5h33.75c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75H127.5v7.5h33.75c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75H127.5v7.5h33.75c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75H127.5v7.5h33.75c2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75H127.5v7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconWordInverse;
