/* eslint-disable max-len */
import * as React from 'react';

function SvgIconScript({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M113.296 127.364a5.632 5.632 0 01-4.222-6.743l11.25-48.75c.697-3.03 3.712-4.882 6.742-4.222a5.632 5.632 0 014.223 6.742l-11.25 48.75a5.624 5.624 0 01-6.743 4.223zM91.521 114.276a5.628 5.628 0 004.275 1.965c1.298 0 2.603-.45 3.668-1.35 2.355-2.025 2.632-5.58.607-7.935l-8.115-9.465 8.108-9.457a5.63 5.63 0 00-.608-7.935 5.615 5.615 0 00-7.935.6l-11.25 13.125a5.629 5.629 0 000 7.327l11.25 13.125zM140.886 114.882a5.587 5.587 0 003.66 1.358 5.605 5.605 0 004.268-1.965l11.25-13.125a5.63 5.63 0 000-7.328l-11.25-13.125a5.63 5.63 0 00-7.935-.607c-2.355 2.025-2.633 5.58-.608 7.935l8.115 9.465-8.107 9.457a5.63 5.63 0 00.607 7.935z" />
      <path
        fillRule="evenodd"
        d="M43.306 45h153.75c7.2 0 13.125 5.925 13.125 13.125v101.25c0 7.2-5.925 13.125-13.125 13.125h-57.532c.93 3.765 3.33 9.863 9.562 16.095a3.767 3.767 0 01.81 4.087 3.749 3.749 0 01-3.465 2.318h-52.5a3.75 3.75 0 01-3.465-2.318 3.74 3.74 0 01.81-4.087c6.248-6.24 8.58-12.337 9.465-16.095H43.306c-7.2 0-13.125-5.925-13.125-13.125V58.125c0-7.2 5.925-13.125 13.125-13.125zm1.875 97.5h150V60h-150v82.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconScript;
