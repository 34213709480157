/* eslint-disable max-len */
import * as React from 'react';

function SvgIconLocation({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M198.999 100.732c0-44.587-35.37-80.732-79-80.732S41 56.145 41 100.732C41 145.319 108.949 220 119.999 220c9.416 0 79-74.681 79-119.268zm-39.518-1.673c0 21.806-17.677 39.483-39.482 39.483-21.806 0-39.482-17.677-39.482-39.483 0-21.805 17.677-39.482 39.482-39.482 21.806 0 39.482 17.677 39.482 39.482z" />
    </svg>
  );
}

export default SvgIconLocation;
