import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import { formatUrl, isExternalLink } from '../../utils';

import './ButtonIcon.scss';

/**
 * @return {html}
 */
const ButtonIcon = ({ to = null, name, ...rest }) => {
  if (!to) {
    return (
      <div className="sw-button-icon">
        <span>
          <Icon name={name} {...rest} />
        </span>
      </div>
    );
  }

  const isExternal = isExternalLink(to);
  const formattedTo = formatUrl(to);

  return (
    <div className="sw-button-icon">
      {
        isExternal && (
          <Link to={{ pathname: formattedTo }} target="_blank">
            <Icon name={name} {...rest} />
          </Link>
        )
      }

      {
        !isExternal && (
          <Link to={formattedTo}>
            <Icon name={name} {...rest} />
          </Link>
        )
      }
    </div>
  );
};

export default ButtonIcon;
