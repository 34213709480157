/* eslint-disable max-len */
import * as React from 'react';

function SvgIconQualityInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm114.257 51.145l3.846 2.794a16.099 16.099 0 015.848 18l-1.468 4.521 1.359 4.183a16.109 16.109 0 01-5.852 18.011l-3.559 2.586-1.448 4.457a16.07 16.07 0 01-5.724 7.944l12.187 29.569-13.784-7.852-8.401 9.867-7.837-25.817-.139.102c-4.933 3.584-11.196 3.354-16.514.676l-8.787 27.904-8.402-7.215L81.8 180l11.99-35.591c-3.192-1.958-5.711-4.974-6.925-8.712l-1.382-4.253-3.84-2.79a16.11 16.11 0 01-5.852-18.011l1.466-4.514-1.36-4.19a16.1 16.1 0 015.848-18l3.564-2.59 1.446-4.451c2.157-6.637 9.094-11.131 16.073-11.131h4.68l3.705-2.692a16.1 16.1 0 0118.927 0l3.704 2.692h4.466c6.979 0 12.412 4.494 14.569 11.13l1.379 4.248zm-33.782 60.99c19.374 0 35.08-15.705 35.08-35.079 0-19.374-15.706-35.08-35.08-35.08s-35.08 15.706-35.08 35.08 15.706 35.079 35.08 35.079zm.092-64.504c16.111 0 29.171 13.06 29.171 29.17 0 16.111-13.06 29.171-29.171 29.171-16.11 0-29.17-13.06-29.17-29.171 0-16.11 13.06-29.17 29.17-29.17zm7.302 32.232l11.675-8.448-14.411.028-4.427-13.715-4.427 13.715-14.411-.028 11.675 8.448-4.479 13.697 11.642-8.492 11.643 8.492-4.48-13.697z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconQualityInverse;
