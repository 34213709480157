/* eslint-disable max-len */
import * as React from 'react';

function SvgIconClose({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={17.067}
        d="M56.182 56l128 128m-128 0l128-128-128 128z"
      />
    </svg>
  );
}

export default SvgIconClose;
