/* eslint-disable max-len */
import * as React from 'react';

function SvgIconWord({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M204.557 58.508h-73.125V41.633a5.61 5.61 0 00-2.037-4.332c-1.282-1.068-3.003-1.53-4.623-1.192l-90 16.875a5.61 5.61 0 00-4.59 5.524v123.75c0 2.7 1.923 5.028 4.59 5.523l90 16.875a5.25 5.25 0 001.035.102 5.627 5.627 0 005.625-5.625v-16.875h73.125a5.627 5.627 0 005.625-5.625v-112.5a5.627 5.627 0 00-5.625-5.625zM108.898 98.5l-5.625 50.637a5.63 5.63 0 01-5.591 4.995 5.64 5.64 0 01-5.265-3.645l-11.61-30.96-11.61 30.96c-.9 2.407-3.263 3.881-5.873 3.611a5.614 5.614 0 01-4.961-4.804l-5.625-39.375a5.62 5.62 0 014.77-6.356c3.082-.45 5.929 1.699 6.367 4.77l2.51 17.595 9.145-24.401c1.643-4.388 8.888-4.388 10.542 0l8.617 22.972 3.026-27.247c.35-3.083 3.207-5.288 6.21-4.962a5.632 5.632 0 014.973 6.21zm90.034 72.507h-67.5v-11.25h50.625a5.627 5.627 0 005.625-5.625 5.627 5.627 0 00-5.625-5.625h-50.625v-11.25h50.625a5.627 5.627 0 005.625-5.625 5.627 5.627 0 00-5.625-5.625h-50.625v-11.25h50.625a5.627 5.627 0 005.625-5.625 5.627 5.627 0 00-5.625-5.625h-50.625v-11.25h50.625a5.627 5.627 0 005.625-5.625 5.627 5.627 0 00-5.625-5.625h-50.625v-11.25h67.5v101.25z" />
    </svg>
  );
}

export default SvgIconWord;
