/* eslint-disable no-plusplus, jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import CardJob from '../CardJob/CardJob';
import SectionWrapper from '../SectionWrapper';

// store
import store from '../../state/store';
import { fetchJobs, selectJobs } from '../../state/store/slices/jobsSlice';
import { useAppSelector } from '../../state/hooks';

import './SectionJobList.scss';

store.dispatch(fetchJobs());

interface IProps {
  component: any;
}

const sortJobsByTargetPersons = (jobs) => {
  const targetPersons = {
    STUDENT: undefined,
    GRADUATE: undefined,
    PROFESSIONAL: undefined,
  };
  const jobsByTargetPersons = {};
  for (let i = 0; i < jobs.length; i++) {
    // eslint-disable-next-line array-callback-return
    jobs[i].targetPersons.map(targetPerson => {
      if (typeof jobsByTargetPersons[targetPerson.key] === 'undefined') {
        jobsByTargetPersons[targetPerson.key] = [];
      }
      targetPersons[targetPerson.key] = targetPerson;
      jobsByTargetPersons[targetPerson.key].push(jobs[i]);
    });
  }

  return {
    targetPersons,
    jobsByTargetPersons,
  };
};

const sortJobsByDate = (jobs) => {
  const sortedJobs = [...jobs].sort((a, b) => {
    if (a.availableSince < b.availableSince) return 1;
    if (a.availableSince > b.availableSince) return -1;
    return 0;
  });
  return sortedJobs;
};

/**
 * @return {html}
 */
const SectionJobList = ({ component }: IProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const jobsAll = useAppSelector(selectJobs);
  const [jobs, setJobs] = useState(jobsAll);
  const { targetPersons, jobsByTargetPersons } = sortJobsByTargetPersons(jobsAll);

  const filterJobs = (targetPerson) => {
    if (targetPerson) {
      setJobs(sortJobsByDate(jobsByTargetPersons[targetPerson]));
    } else {
      setJobs(sortJobsByDate(jobsAll));
    }
  };

  useEffect(() => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const targetPerson = url.searchParams.get('targetPerson');
    filterJobs(targetPerson);

    const element = document.getElementById(location.hash.replace('#', ''));
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  const state = {
    from: location.pathname,
  };

  if (!jobs.length) {
    return <></>;
  }

  return (
    <SectionWrapper
      classes="sw-section-job-list"
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-job-list-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className="grid-1x1fr">
          {
            !isEmpty(targetPersons) && (
              <div className="target-persons-filter-links">
                {
                  (targetPersons.STUDENT) && (
                    <button type="button" onClick={() => filterJobs('STUDENT')}>{t('target-persons.STUDENT')}</button>
                  )
                }

                {
                  (targetPersons.GRADUATE) && (
                    <button type="button" onClick={() => filterJobs('GRADUATE')}>{t('target-persons.GRADUATE')}</button>
                  )
                }

                {
                  (targetPersons.PROFESSIONAL) && (
                    <button type="button" onClick={() => filterJobs('PROFESSIONAL')}>{t('target-persons.PROFESSIONAL')}</button>
                  )
                }
              </div>
            )
          }

          {
            [...jobs].map(job => (
              <Link
                key={job.id}
                to={{
                  pathname: `/jobs/${job.slug}`,
                  state,
                }}
              >
                <CardJob job={job} />
              </Link>
            ))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionJobList;
