/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPadlockList({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M91.265 71.666v16.136H68.66v-16.12c0-6.97 1.372-13.747 4.067-20.12 2.597-6.165 6.325-11.683 11.069-16.427a51.48 51.48 0 0116.426-11.069A51.472 51.472 0 01120.342 20c6.97 0 13.731 1.355 20.104 4.05a51.294 51.294 0 0116.426 11.069 51.467 51.467 0 0111.069 16.426 51.471 51.471 0 014.067 20.121v16.12h-22.59v-16.12c0-16.039-13.038-29.076-29.076-29.076-16.039 0-29.077 13.037-29.077 29.076z" />
      <path
        fillRule="evenodd"
        d="M59.42 97.564h121.855c7.132 0 12.908 5.777 12.908 12.909v96.603L46.511 220.001V110.473c0-7.132 5.776-12.909 12.908-12.909zm12.73 81.227a7.892 7.892 0 007.89 7.89 7.892 7.892 0 007.89-7.89 7.892 7.892 0 00-7.89-7.891 7.892 7.892 0 00-7.89 7.891zm0-26.382a7.891 7.891 0 0015.78 0 7.892 7.892 0 00-7.89-7.89 7.892 7.892 0 00-7.89 7.89zm0-26.398a7.892 7.892 0 007.89 7.891 7.892 7.892 0 007.89-7.891 7.892 7.892 0 00-7.89-7.89 7.892 7.892 0 00-7.89 7.89zm27.689 57.411h69.931v-9.23H99.839v9.23zm0-26.398h69.931v-9.23H99.839v9.23zm0-26.382h69.931v-9.229H99.839v9.229z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPadlockList;
