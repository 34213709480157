/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExcel({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M30.177 190.006V50.001L134.023 30v180L30.177 190.006zm53.605-57.676l12.08 22.839 18.728-.007-21.614-35.356 21.025-34.767H96.403L85.257 105.87c-1.073 2.707-1.807 4.742-2.202 6.113h-.194c-.623-2.278-1.322-4.251-2.104-5.912L70.732 85.045H51.514l20.443 35.066-22.347 35.058h18.824l12.122-22.638c.852-2.188 1.392-3.822 1.62-4.895h.187c.491 2.278.962 3.842 1.42 4.694zM140.948 50.772h63.429c3.192 0 5.802 2.706 5.802 6.023V183.21c0 3.316-2.61 6.023-5.802 6.023h-63.429v-20.769h20.769v-13.846h-20.769v-13.847h20.769v-13.846h-20.769v-13.846h20.769V99.233h-20.769V85.491h20.984V71.43h-20.984V50.77zm27.693 117.692h27.692v-13.846h-27.692v13.846zm0-27.693h27.692v-13.846h-27.692v13.846zm0-27.692h27.692V99.233h-27.692v13.846zm0-27.692h27.692V71.54h-27.692v13.846z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconExcel;
