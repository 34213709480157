/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExternalLinkInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm134.545 30H130.91a5.455 5.455 0 100 10.909h30.467l-56.141 56.142a5.454 5.454 0 107.713 7.713l56.142-56.141v30.467a5.454 5.454 0 0010.909 0V65.454A5.455 5.455 0 00174.545 60zm-21.82 54.542a5.454 5.454 0 00-5.454 5.455v49.09H70.909V92.725h49.09a5.454 5.454 0 000-10.91H65.455A5.455 5.455 0 0060 87.27v87.271a5.455 5.455 0 005.454 5.455h87.271a5.455 5.455 0 005.455-5.455v-54.544a5.455 5.455 0 00-5.455-5.455z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconExternalLinkInverse;
