import React from 'react';

import * as Icons from '../icons';

const YouTubeIcon = ({ ...rest }) => {
  const Component = Icons.IconYouTube;

  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '22%',
        height: '50px',
        width: '70px',
        padding: 0,
        margin: 0,
      }}
    >
      <Component
        title={null}
        titleId={null}
        fill="currentColor"
        style={{
          color: '#DE4924',
          height: '5rem',
          left: '-5px',
          position: 'relative',
          top: '-15px',
          width: 'auto',
        }}
        {...rest}
      />
    </div>
  );
};

export default YouTubeIcon;
