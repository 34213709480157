import React from 'react';

import CardIcon from '../CardIcon';
import SectionWrapper from '../SectionWrapper';

import './SectionIconCard.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionIconCard = ({ component }: IProps) => (
  <SectionWrapper
    classes="sw-section-icon-card"
    collapseBottom={component.collapseBottom}
    collapseTop={component.collapseTop}
    id={component.anchorId || `section-icon-card-${component.id}`}
  >
    <div className="grid-1fr-1fr2fr grid-gap">
      <div>
        {
          component.title && (
            <>
              <h2 className="h5">{component.title}</h2>
              {
                component.subTitle && (<div className="h6">{component.subTitle}</div>)
              }
            </>
          )
        }
      </div>

      <div className={component.variant === 'FullWidth' ? 'grid-1x1fr' : 'grid-1x1fr-2x1fr'}>
        {
          component.cards.map(iconCard => (<CardIcon key={iconCard.id} card={iconCard} />))
        }
      </div>
    </div>
  </SectionWrapper>
);

export default SectionIconCard;
