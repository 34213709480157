import { useReducer, useEffect } from 'react';

// defines the time for the animation between slides in milliseconds
const transitionTime = 500;
// defines the threshold when to accept a swipe
// const threshold = 0.3;
// defines the limit for swiping (max. the next full and a bit)
// const limit = 1.2;
// animation to be used when bouncing back
const elastic = `transform ${transitionTime}ms cubic-bezier(0.68, -0.55, 0.265, 1.55)`;
// animation to be used when automatically sliding
const smooth = `transform ${transitionTime}ms ease`;

const initialCarouselState = {
  offset: 0,
  desired: 0,
  active: 0,
};

const previous = (length, current) => (current - 1 + length) % length;
const next = (length, current) => (current + 1) % length;

const carouselReducer = (state, action) => {
  switch (action.type) {
    case 'jump':
      return { ...state, desired: action.desired };
    case 'next':
      return { ...state, desired: next(action.length, state.active) };
    case 'prev':
      return { ...state, desired: previous(action.length, state.active) };
    case 'done':
      return { ...state, offset: NaN, active: state.desired };
    case 'drag':
      return { ...state, offset: action.offset };
    default:
      return state;
  }
};

const useCarousel = (length, interval, options = {}) => {
  // @ts-ignore
  const { slidesPresented = 1 } = options;
  const shadowSlides = 2 * slidesPresented;
  const n = Math.max(1, Math.min(slidesPresented, length));
  const totalWidth = 100 / n;
  const [state, dispatch] = useReducer(carouselReducer, initialCarouselState);

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'next', length }), interval);
    return () => clearTimeout(id);
  }, [state.offset, state.active]);

  useEffect(() => {
    const id = setTimeout(() => dispatch({ type: 'done' }), transitionTime);
    return () => clearTimeout(id);
  }, [state.desired]);

  const style = {
    transform: 'translateX(0)',
    width: `${totalWidth * (length + shadowSlides)}%`,
    left: `-${(state.active + 1) * totalWidth}%`,
  };

  if (state.desired !== state.active) {
    const dist = Math.abs(state.active - state.desired);
    const pref = Math.sign(state.offset || 0);
    const dir = (dist > length / 2 ? 1 : -1) * Math.sign(state.desired - state.active);
    const shift = (totalWidth * (pref || dir)) / (length + shadowSlides);
    // @ts-ignore
    style.transition = smooth;
    style.transform = `translateX(${shift}%)`;
  } else if (!Number.isNaN(state.offset)) {
    if (state.offset !== 0) {
      style.transform = `translateX(${state.offset}px)`;
    } else {
      // @ts-ignore
      style.transition = elastic;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return [state.active, n => dispatch({ type: 'jump', desired: n }), style];
};

export default useCarousel;
