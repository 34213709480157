import React from 'react';

import './CardQuote.scss';

/**
 * @return {html}
 */
const CardQuote = ({ card }) => {
  const isQuote = !(!card.name || !card.position);

  return (
    <div className="sw-card-quote">
      <div>
        {
          isQuote && (
            <div>
              <svg className="quote-symbol" fill="currentColor" viewBox="0 0 107 70" aria-hidden="true">
                <path d="M36.98 70L47 39.69V0H0v38.488h12.406L6.203 70H36.98zm60 0L107 39.69V0H60v38.488h12.406L66.203 70H96.98z" />
              </svg>
            </div>
          )
        }
        <div className="quote-text">
          {card.quote}
        </div>
        {
          isQuote && (
            <div className="quote-attribution">
              <div>{card.name}</div>
              <svg className="forward-slash" fill="currentColor" viewBox="0 0 20 20">
                <path d="M11 0h3L9 20H6l5-20z" />
              </svg>
              <div>{card.position}</div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default CardQuote;
