import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import qs from 'qs';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface IJobs {
  newsItems: any[];
  status: string;
  error: string;
}

const initialState: IJobs = {
  newsItems: [],
  status: Statuses.idle,
  error: null,
};

export const fetchNewsItems = createAsyncThunk('newsItems/fetchNewsItems', async () => {
  const todayDate = moment().format('YYYY-MM-DD');

  const query = qs.stringify({
    visibleFrom_lte: todayDate,
    _sort: 'date:desc',
  });

  const url = `/news-items?${query}`;
  const response = await ApiService.get(url);
  return response.data;
});

export const NewsItemsSlice = createSlice({
  name: 'newsItems',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNewsItems.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchNewsItems.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched news items to the store
        state.newsItems = action.payload;
      })
      .addCase(fetchNewsItems.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectNewsItems = state => state.newsItems.newsItems;
export const selectRecentNewsItems = count => state => state.newsItems.newsItems.slice(0, count);
export const selectNewsItemBySlug = slug => state => state.newsItems.newsItems.find(obj => obj.slug === slug);

export default NewsItemsSlice.reducer;
