/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPublicTransport({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M63.809 20.547c-2.813.898-6.407 3.047-8.477 5.117C52.598 28.36 50.957 31.29 49.98 35c-.78 3.047-.78 4.14-.78 75 0 78.398-.118 74.141 2.187 79.023 2.734 5.743 10.508 11.055 16.132 11.055 1.368 0 1.25.156-8.554 9.961L49.004 220h30.86l9.96-9.961 9.961-9.961h39.453l9.961 9.961L159.16 220h30.86l-9.961-9.961c-9.805-9.805-9.922-9.961-8.555-9.961 5.625 0 13.398-5.312 16.133-11.055 2.343-4.961 2.226-.546 2.109-80.234l-.117-72.969-.859-2.226c-2.422-6.367-6.602-10.547-12.618-12.696L173.613 20l-54.101.04c-42.578 0-54.453.116-55.703.507zM139.434 45v4.688H99.59v-9.376h39.844V45zm29.687 55.078v29.688H69.902V70.391h99.219v29.687zm-81.445 50.586c3.984.664 8.36 4.18 10.273 8.32.899 1.914 1.055 2.735 1.055 5.938 0 3.281-.117 4.023-1.133 6.133-1.367 3.007-4.414 5.976-7.539 7.461-1.914.898-2.813 1.054-5.781 1.054-3.125 0-3.79-.117-6.133-1.25a15.291 15.291 0 01-7.227-7.07c-.976-1.992-1.093-2.617-1.093-6.328 0-3.945.039-4.219 1.328-6.563 2.265-4.179 6.445-7.226 10.781-7.89 1.953-.313 2.54-.274 5.469.195zm71.679.469c3.594 1.289 6.524 3.906 8.516 7.656.938 1.836 1.055 2.422 1.055 6.133s-.117 4.336-1.094 6.328a15.286 15.286 0 01-7.227 7.07c-2.343 1.133-3.007 1.25-6.132 1.25-2.969 0-3.868-.156-5.782-1.054-3.125-1.485-6.171-4.454-7.539-7.461-1.015-2.11-1.132-2.852-1.132-6.133 0-3.203.156-4.024 1.054-5.938 3.242-6.953 11.172-10.351 18.281-7.851z" />
    </svg>
  );
}

export default SvgIconPublicTransport;
