/* eslint-disable */
import React, { useEffect, useState } from 'react';

const useHasStatisticsConsentWithLocalStorage = localStorageKey => {
  const hasDocument = typeof document !== 'undefined';

  const [value, setValue] = useState(
    hasDocument
      ? localStorage.getItem(localStorageKey) || null
      : null,
  );

  useEffect(() => {
    if (hasDocument && value !== null) {
      localStorage.setItem(localStorageKey, value);
    }
  }, [value]);

  return [value, setValue];
};

const useStatisticsConsent = () => {
  const [hasStatisticsConsent, setHasStatisticsConsent] = useHasStatisticsConsentWithLocalStorage('hasStatisticsConsent');

  const boolStrings = {
    TRUE: 'true',
    FALSE: 'false',
  };

  const result = {
    hasStatisticsConsent: null,
    // @ts-ignore
    giveStatisticsConsent: () => setHasStatisticsConsent(boolStrings.TRUE),
    // @ts-ignore
    denyStatisticsConsent: () => setHasStatisticsConsent(boolStrings.FALSE),
  };

  if (hasStatisticsConsent === boolStrings.TRUE) result.hasStatisticsConsent = true;
  else if (hasStatisticsConsent === boolStrings.FALSE) result.hasStatisticsConsent = false;

  return result;
};

export default useStatisticsConsent;
