/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';
import useStatisticsConsent from '../../hooks/useStatisticsConsent';
import ButtonStandard from '../ButtonStandard/ButtonStandard';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './CookieConsentBox.scss';

declare global {
  interface Window {
    dataLayer: any;
  }
}

const CookieConsentBox = ({ googleTagManagerId, isVisible = true }) => {
  const mainMenu = useAppSelector(selectMainMenu);
  const { t } = useTranslation();

  const theme = mainMenu.theme.toLowerCase();
  const isTechbarTheme = theme === 'techbar';

  if (isVisible === false) {
    return null;
  }

  const { pathname, hash } = useLocation();
  const { hasStatisticsConsent, giveStatisticsConsent, denyStatisticsConsent } = useStatisticsConsent();
  const hasDocument = typeof document !== 'undefined';

  useEffect(() => {
    if (hasDocument && hasStatisticsConsent === true) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'PageView',
        pathName: pathname + hash,
      });
    }
  }, [pathname, hasStatisticsConsent]);

  useEffect(() => {
    if (!hasDocument) return;

    const ccbElement = document.getElementById('cookieConsentBox');
    // eslint-disable-next-line no-restricted-globals
    const isPrinting = hasDocument && location.pathname.toLowerCase().endsWith('/print');

    if (ccbElement && !isPrinting) {
      // Setting style.display directly sometimes doesn't work as intended, which is why setTimeout is used
      // eslint-disable-next-line no-return-assign
      setTimeout(() => ccbElement.style.display = '', 0);
    }
  }, []);

  return (
    <>
      {
        hasStatisticsConsent === true && googleTagManagerId && googleTagManagerId.length > 0 && (
          <Helmet>
            <script>
              {`
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${googleTagManagerId}');
              `}
            </script>
          </Helmet>
        )}
      {
        hasStatisticsConsent === null && (
          <>
            <div
              id="cookieConsentBox"
              className={`sw-cookie-consent-box sw-cookie-consent-box-${theme}`}
            >
              <div>
                <span dangerouslySetInnerHTML={{ __html: t('cookie-consent-dialog') }} />
                {
                  mainMenu.dataProtectionPage ? (
                    <span><a className="font-weight-700" href={`/${mainMenu.dataProtectionPage.slug}`} rel="noreferrer nofollow">{t('common.data-security-policy')}</a>.</span>
                  ) : (
                    <span>{t('common.data-security-policy')}.</span>
                  )
                }
              </div>
              <ButtonGroup>
                <ButtonStandard onClick={giveStatisticsConsent} text={t('common.accept')} variant={isTechbarTheme ? 'pacific-blue' : 'pacific-blue-dark'} />
                <ButtonStandard onClick={denyStatisticsConsent} text={t('common.decline')} variant={isTechbarTheme ? 'orange' : 'pacific-blue'} />
              </ButtonGroup>
              {
                mainMenu.legalNoticePage &&
                (
                <div className="impressum-link">
                  <a className="font-weight-700" href={`/${mainMenu.legalNoticePage.slug}`} rel="noreferrer nofollow">{t('common.impressum')}</a>
                </div>
                )
              }
            </div>
          </>
        )}
    </>
  );
};

export default CookieConsentBox;
