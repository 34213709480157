import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';

const ShareIconWhatsapp = ({ sharedUrl }) => {
  const whatsAppShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(sharedUrl)}`;

  return (<ButtonIcon to={whatsAppShareUrl} name="WhatsApp" title="WhatsApp" height="2rem" width="2rem" />);
};

export default ShareIconWhatsapp;
