import React from 'react';

import { apiBaseUrl } from '../../utils';

import './ImageFeature.scss';

/**
 * @return {html}
 */
const ImageFeature = ({ image, classes = '' }) => (
  <div className={`sw-image-feature ${classes}`.trim()}>
    <img
      alt={image.alternativeText}
      src={`${apiBaseUrl()}${image.url}`}
      title={image.caption}
    />
  </div>
);

export default ImageFeature;
