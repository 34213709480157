/* eslint-disable max-len */
import * as React from 'react';

function SvgIconStartupInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm136.399 84.6c-6.6-17.4-24-26.4-43.2-22.8C114.6 73.2 88.799 60.6 63 60c-1.8 0-3 1.2-3 3 .6 25.8 13.2 51.6 31.2 70.199-3 18.6 5.4 36.6 22.8 43.2-3-7.8-3.6-16.8-1.2-25.8v.001c3 1.799 6 3.599 9 4.799 1.2.6 2.4.6 3.6-.6l29.999-29.999c.6-.6.6-1.8 0-3-1.2-3-3-6-4.8-8.4 9-2.4 18-1.8 25.8 1.2zm-65.999-4.2c-4.8 4.8-12 4.8-16.8 0-4.8-4.8-4.8-12 0-16.8 4.8-4.8 12-4.8 16.8 0 4.8 4.8 4.8 12 0 16.8zm67.8 36.002l-12.6-12.6c-2.4-2.4-6-2.4-8.4 0-2.4 2.4-2.4 6 0 8.4l12.6 12.6c2.4 2.4 6 2.4 8.4 0 2.4-2.4 2.4-6 0-8.4zM154.802 169.8l-12.6-12.6c-2.4-2.4-6-2.4-8.4 0-2.4 2.4-2.4 6 0 8.4l12.6 12.6c2.4 2.4 6 2.4 8.4 0 2.4-2.4 2.4-6 0-8.4zm16.195-6.606l-17.399-17.4c-2.4-2.4-6-2.4-8.4 0-2.4 2.4-2.4 6 0 8.4l17.4 17.4c2.4 2.4 5.999 2.4 8.399 0 2.4-2.4 2.4-6 0-8.4z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconStartupInverse;
