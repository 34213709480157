/* eslint-disable max-len */
import * as React from 'react';

function SvgIconXingInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm66.82 102.955L148.465 60H176.5l-41.645 72.955L161.69 180h-28.035l-26.835-47.045zM68.795 82.5h26.43l15.29 26.93L90.43 142.5H64l20.085-33.07-15.29-26.93z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconXingInverse;
