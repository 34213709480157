/* eslint-disable max-len */
import * as React from 'react';

function SvgIconYouTubeInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm126.951 50.57a15.033 15.033 0 0110.577 10.577c2.57 9.39 2.471 28.961 2.471 28.961s0 19.473-2.47 28.863a15.033 15.033 0 01-10.578 10.576c-9.39 2.472-46.951 2.472-46.951 2.472s-37.463 0-46.952-2.57a15.036 15.036 0 01-10.577-10.577C60 139.581 60 120.01 60 120.01s0-19.473 2.471-28.863c1.383-5.14 5.535-9.292 10.576-10.675C82.437 78 120 78 120 78s37.562 0 46.952 2.57zm-27.676 39.44l-31.235-17.99v35.979l31.235-17.989z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconYouTubeInverse;
