import React from 'react';

import useCarousel from './useCarousel';
import CarouselChild from './CarouselChild';

import './CarouselContainer.scss';

const makeIndices = (start, delta, num) => {
  const indices = [];
  while (indices.length < num) {
    indices.push(start);
    // eslint-disable-next-line no-param-reassign
    start += delta;
  }

  return indices;
};

const CarouselIndicator = ({ active, theme, ...props }) => (
  <li {...props} className={`carousel-indicator carousel-indicator-${theme} ${active ? 'active' : ''}`} />
);

const CarouselContainer = ({
  children,
  interval = 5000,
  slidesPresented = 1,
  theme,
}) => {
  const slides = React.Children.toArray(children);
  const { length } = slides;
  const numActive = Math.min(length, slidesPresented);
  const [active, setActive, style] = useCarousel(length, interval, { slidesPresented: numActive });
  const beforeIndices = makeIndices(slides.length - 1, -1, numActive);
  const afterIndices = makeIndices(0, +1, numActive);

  return (
    length > 0 && (
      <div className="carousel-container">
        <ol className="carousel-indicators">
          {
            slides.map((_, index) => (
              <CarouselIndicator onClick={() => setActive(index)} key={`key-${index + 1}`} active={active === index} theme={theme} />
            ))
          }
        </ol>
        <div className="carousel-track" style={style}>
          {
            beforeIndices.map(i => (
              <CarouselChild key={i}>{slides[i]}</CarouselChild>
            ))
          }
          {
            slides.map((slide, index) => (
              <CarouselChild key={`key-${index + 1}`}>{slide}</CarouselChild>
            ))
          }
          {
            afterIndices.map(i => (
              <CarouselChild key={i}>{slides[i]}</CarouselChild>
            ))
          }
        </div>
      </div>
    )
  );
};

export default CarouselContainer;
