/* eslint-disable max-len */
import * as React from 'react';

function SvgIconRadiation({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M132.924 102.284l34.862 99.054L72.638 210l38.016-107.06c3.114.93 6.348 1.4 9.598 1.395 4.064 0 7.956-.72 11.556-2.047l1.125-.009-.009.005zM49.508 30l8.217 4.757a71.91 71.91 0 00-9.635 36.072c0 13.14 3.51 25.46 9.635 36.072l-8.217 4.756A81.387 81.387 0 0138.6 70.829C38.6 55.956 42.569 42.01 49.508 30zm141.502 0a81.397 81.397 0 0110.904 40.829c0 14.872-3.969 28.818-10.904 40.828l-8.221-4.756a71.93 71.93 0 009.63-36.072c0-13.14-3.501-25.461-9.63-36.072L191.01 30zm-70.708 15.75a25.078 25.078 0 110 50.157 25.078 25.078 0 010-50.157zm-41.535 1.125l7.55 4.36a38.989 38.989 0 00-5.246 19.594 39.009 39.009 0 005.247 19.593l-7.556 4.36a47.66 47.66 0 01-6.412-23.953 47.67 47.67 0 016.412-23.954h.005zm82.984 0a47.661 47.661 0 016.413 23.954 47.684 47.684 0 01-6.413 23.953l-7.56-4.365a38.985 38.985 0 005.247-19.588 38.988 38.988 0 00-5.242-19.593l7.56-4.361h-.005z" />
    </svg>
  );
}

export default SvgIconRadiation;
