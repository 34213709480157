/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExperienceInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm140 46v66.9l-10.97.961c.037-.45.061-.905.061-1.365 0-9.054-7.326-16.393-16.364-16.393-9.037 0-16.363 7.339-16.363 16.393a16.442 16.442 0 00.539 4.182L60 153.42V76c0-5.523 4.477-10 10-10h100c5.523 0 10 4.477 10 10zm-60 50.103H76.364v5.47H120v-5.47zM76.364 87.92h87.272v-5.53H76.364v5.53zm0 21.785h87.272v-5.459H76.364v5.459zm57.827 64.228l6.789-17.635.743-1.67a16.302 16.302 0 008.08 3.994l-4.952 13.95-3.55-3.671-7.11 5.032zm30.679-31.447c0 6.718-5.437 12.164-12.143 12.164s-12.142-5.446-12.142-12.164 5.436-12.164 12.142-12.164c6.706 0 12.143 5.446 12.143 12.164zm-8.756 16.049a16.303 16.303 0 007.437-3.75l6.319 15.88-6.239-3.439-3.751 4.545-3.766-13.236z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconExperienceInverse;
