const isIPv4Host = host => {
  let h = host;
  const regexExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
  const delimiter = ':';

  // check if a port number is present (i.e. tld.local:3000 or 127.0.0.1:3000)
  if (host.includes(delimiter)) {
    // eslint-disable-next-line prefer-destructuring
    h = host.split(delimiter)[0];
  }

  return regexExp.test(h);
};

export default isIPv4Host;
