import React from 'react';
import { useLocation } from 'react-router-dom';

import JobPageDetails from '../JobPageDetails';

import './JobPageContent.scss';

interface IState {
  from: string;
}

/**
 * @return {html}
 */
const JobPageContent = ({ data }) => {
  const location = useLocation<IState>();
  const from = location.state?.from;

  return (
    <main className="sw-page-content">
      <JobPageDetails job={data} from={from} />
    </main>
  );
};

export default JobPageContent;
