/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBudgetInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm140 57.482v58.703l-10.926.994.005-.124c.006-.129.011-.258.011-.393V98.687s0-11.779-11.478-11.779h-80.68s-.204.001-.551.027C76.745 76.008 87.84 76 87.84 76h80.681C180 76 180 87.482 180 87.482zM70.915 92.42l81.814-.055c10.908.055 10.907 11.011 10.907 11.011v50.949L60 163.6v-60.225c.006-10.956 10.915-10.956 10.915-10.956zm35.898 45.073c-1.915-1.414-3.397-3.251-4.445-5.514h11.067l1.814-4.484h-14.151a15.565 15.565 0 01-.181-2.425v-.666h14.453l1.814-4.484h-15.481c.361-1.248.89-2.441 1.572-3.545a12.283 12.283 0 012.48-2.909 11.295 11.295 0 013.356-1.969c1.27-.485 2.691-.728 4.263-.728.968 0 1.955.112 2.963.333 1.01.224 1.986.58 2.903 1.061.927.485 1.794 1.142 2.6 1.97.806.828 1.492 1.848 2.057 3.06l6.41-4.424c-1.412-2.788-3.559-5.05-6.441-6.787-2.882-1.737-6.299-2.606-10.25-2.606-2.822 0-5.392.455-7.71 1.363-2.319.91-4.354 2.121-6.108 3.636a20.276 20.276 0 00-4.354 5.273 22.141 22.141 0 00-2.45 6.272h-2.962l-1.814 4.484h4.233v.424c0 .93.06 1.818.18 2.666h-3.386l-1.874 4.485h6.289a23.655 23.655 0 002.872 5.817 21.38 21.38 0 004.385 4.757 21.399 21.399 0 005.654 3.242c2.076.809 4.303 1.212 6.682 1.212 1.733 0 3.508-.222 5.322-.666a21.914 21.914 0 005.11-1.939 18.884 18.884 0 004.293-3.121 12.937 12.937 0 002.934-4.273l-6.834-3.938c-.484 1.172-1.129 2.172-1.935 2.999a11.315 11.315 0 01-5.624 3.182 13.441 13.441 0 01-3.024.363c-2.54 0-4.768-.706-6.682-2.121z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconBudgetInverse;
