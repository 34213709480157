import * as React from 'react';

function SvgIconSystemOverload({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 400 400"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <rect x="203.842" y="250.014" width="11.516" height="33.248" />
          <rect x="183.758" y="250.014" width="11.358" height="33.248" />
          <path d="M226.225,271.736h-9.052v13.33h-15.146v-13.33h-2.465h-2.632v13.33h-14.978v-13.33h-9.218
            c-1.153,2.633-2.307,5.432-3.619,8.558c-1.479,3.461-3.126,7.247-4.939,11.526c11.851,0,23.535,0,35.219,0
            c11.852,0,23.703,0,35.386,0c-1.804-4.279-3.451-8.065-4.771-11.526C228.53,277.168,227.377,274.369,226.225,271.736z"
          />
          <path d="M241.861,145.333c1.972-0.819,3.952-2.139,5.433-3.46c1.646-1.145,4.111-0.819,5.265,0.827
            c1.321,1.479,0.986,3.953-0.66,5.098c-1.479,1.154-3.125,2.307-4.772,3.293l1.48,8.4l3.292-8.893l7.898,7.573l-2.958-10.866
            l8.558,0.334l-6.911-4.278l5.599-10.698l-9.385,6.084l-3.294-9.377l-2.466,9.052l-8.725-6.586l4.771,9.712l-8.884,1.321
            L241.861,145.333z"
          />
          <path d="M296.011,107.146H102.946c-3.451,0-6.251,2.968-6.251,6.419v128.383c0,3.453,2.8,6.421,6.251,6.421
            h193.064c3.461,0,6.252-2.968,6.252-6.421V113.565C302.263,110.114,299.472,107.146,296.011,107.146z M291.731,237.669H107.226
            V117.845h184.505V237.669z"
          />
          <path d="M170.102,221.372c19.088,10.372,42.792,3.293,53.164-15.796c8.716-16.13,4.939-35.554-7.908-47.405
            l6.419-12.01l-7.07-3.954c2.299-4.772,4.605-5.6,6.913-4.772c2.8,1.153,5.925,4.605,8.391,7.73c1.813,2.14,3.46,3.954,4.939,4.94
            c1.814,1.154,4.112,1.647,6.912,0.987c2.465-0.493,5.433-1.973,9.052-4.604c0.827-0.661,0.985-1.814,0.334-2.801
            c-0.66-0.818-1.981-0.986-2.8-0.325c-2.968,2.298-5.434,3.618-7.413,3.944c-1.639,0.334-2.959,0-4.113-0.493
            c-0.818-0.651-2.298-2.299-3.943-4.279c-2.802-3.293-6.262-7.237-9.88-8.716c-4.279-1.647-8.392-0.503-11.851,6.577l-6.42-3.451
            l-6.585,12.01c-16.95-4.279-35.219,3.292-43.946,19.424C143.934,187.306,151.17,211.167,170.102,221.372z M200.056,192.079
            c2.307-4.279,7.572-5.759,11.685-3.461c4.278,2.14,5.925,7.405,3.617,11.684c-2.298,4.278-7.563,5.768-11.842,3.46
            C199.395,201.455,197.749,196.19,200.056,192.079z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconSystemOverload;
