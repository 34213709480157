/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPersonInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm123.249 91.634v50.571L76 180v-58.366c0-12.563 14.244-12.563 14.244-12.563h58.761c14.244 0 14.244 12.563 14.244 12.563zM119.624 60c12.047 0 21.812 9.766 21.812 21.812 0 12.047-9.765 21.812-21.812 21.812-12.046 0-21.812-9.765-21.812-21.812 0-12.046 9.766-21.812 21.812-21.812z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPersonInverse;
