/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCellPhone({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M177.082 206.927V33.072S177.082 20 164.01 20H76.072S63 20 63 33.072v173.856S63 220 76.072 220h87.938s13.072 0 13.072-13.072v-.001zM139.054 39.014h-38.026v-9.502h38.027l-.001 9.502zm19.014 152.108H82.014V48.553h76.054v142.569z" />
    </svg>
  );
}

export default SvgIconCellPhone;
