/* eslint-disable max-len */
import * as React from 'react';

function SvgIconAccompanyInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm20 93.209v53.868l65.663-5.789v-48.079s.001-11.566-11.504-11.566H71.504S60 111.643 60 123.209zm76.106-44.661c0 9.139 7.37 16.547 16.46 16.547 9.091 0 16.46-7.408 16.46-16.547 0-9.14-7.369-16.548-16.46-16.548-9.09 0-16.46 7.408-16.46 16.548zm-64.779 6.227c0 11.891 9.589 21.53 21.416 21.53 11.828 0 21.416-9.639 21.416-21.53 0-11.89-9.588-21.53-21.416-21.53-11.827 0-21.416 9.64-21.416 21.53zm59.646 38.644v47.401h.001L180 166.499v-54.144s0-11.566-11.504-11.566h-31.682s-10.42.008-11.42 9.988c5.571 4.697 5.579 12.642 5.579 12.642z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconAccompanyInverse;
