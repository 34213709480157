/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDialog({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M211.364 139.552a10 10 0 008.816-9.93V51c0-5.523-4.477-10-10-10H102.942c-5.523 0-10 4.477-10 10v17.548H32.589s-12.41 0-12.41 12.41v76.008s0 12.409 12.362 11.314l60.42-5.351v35.229l25.116-37.453 17.075-1.513s12.361-1.095 12.361-13.504v-.089l18.093-1.605v47.968l33.271-50.921 12.487-1.489zm-96.01-16.793a9.046 9.046 0 110-18.092 9.046 9.046 0 010 18.092zm-53.94-9.046a9.046 9.046 0 01-9.047 9.046 9.046 9.046 0 119.047-9.046zm31.478 0a9.046 9.046 0 11-18.092 0 9.046 9.046 0 0118.093 0zm118.288 13.016V130l-18.378 1.849-18.009 27.471v-25.863l-27.28 2.436V80.957s0-12.409-12.409-12.409h-33.067V50.206H211.18v76.523z" />
    </svg>
  );
}

export default SvgIconDialog;
