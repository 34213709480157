import React from 'react';

import './Brand.scss';

interface IBrand {
  iconComponent?: React.ReactNode;
  suffix?: string;
}

const Brand: React.FC<IBrand> = ({ iconComponent, suffix }) => (
  <div className="sw-brand">
    <div>{iconComponent}</div>
    {
      suffix && (
        <div className="suffix">
          <div>{suffix}</div>
        </div>
      )
    }
  </div>
);

Brand.defaultProps = {
  iconComponent: 'syncwork',
  suffix: undefined,
};

export default Brand;
