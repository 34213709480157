/* eslint-disable max-len */
import * as React from 'react';

function SvgIconAppleInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm80.559 53.704c-.105 1.312-1.267 2.476-2.582 2.587 0 0-10.243.852-17.382-6.285-7.145-7.145-6.286-17.387-6.286-17.387.108-1.313 1.27-2.477 2.585-2.587 0 0 10.219-.852 17.375 6.292 7.135 7.142 6.29 17.38 6.29 17.38zm12.179-2.334c-4.217 4.838-7.555 9.88-9.664 13.403 25.263-11.14 44.703 3.681 44.703 28.961 0 26.183-18.708 66.359-47.396 53.944C93.142 189.682 73 149.914 73 123.734c0-25.063 21.625-39.477 44.376-29.186 7.358-19.091 13.61-28.06 13.61-28.06.792-1.053 2.417-1.46 3.612-.902l8.452 3.95c1.196.557 1.308 1.663.257 2.458 0 0-6.993 5.27-10.564 9.376h-.005z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconAppleInverse;
