/* eslint-disable max-len */
import * as React from 'react';

function SvgIconIndustry({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M78.812 55.495l.023 73.227h130.597v65.015L29.871 210l.009-89.189V55.494h48.932zm-16.659-25.38c4.977-.473 7.866.607 10.17 1.52l.603.24c1.674.66 3.06 1.124 5.598.99l.085-.005v9.337c-4.031.158-6.56-.756-8.608-1.566l-.742-.297c-1.832-.72-3.317-1.206-6.377-.913-3.19.31-4.563 1.125-6.358 2.25l-.563.36c-2.097 1.31-4.76 2.835-9.585 3.298-.783.076-1.57.115-2.358.117-3.383 0-5.633-.778-7.487-1.503l-.981-.387c-1.665-.657-3.051-1.111-5.598-.963l-.081.005v-9.342c4.059-.171 6.601.747 8.658 1.561l.683.27c1.859.738 3.344 1.233 6.435.94 3.029-.287 4.42-1.039 6.084-2.083l.774-.477c2.106-1.327 4.77-2.88 9.648-3.348v-.004z" />
    </svg>
  );
}

export default SvgIconIndustry;
