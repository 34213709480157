/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSpringInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm122.272 142.771a9.825 9.825 0 008.77-10.629c-.47-5.372-5.178-9.354-10.541-8.941l-82.22 7.193v.001l-.06.006c-5.323.553-9.237 5.277-8.77 10.629.47 5.371 5.179 9.354 10.542 8.941l82.22-7.194v-.001l.059-.005zm-.207-23.293a9.825 9.825 0 008.77-10.629c-.47-5.371-5.178-9.354-10.541-8.941l-82.22 7.193v.001l-.06.006c-5.323.553-9.237 5.277-8.77 10.629.47 5.371 5.179 9.354 10.542 8.941l82.22-7.194.059-.006zm-.207-23.293a9.825 9.825 0 008.77-10.629c-.47-5.371-5.178-9.354-10.541-8.941l-82.22 7.194-.059.006c-5.324.553-9.238 5.277-8.77 10.629.47 5.371 5.178 9.354 10.541 8.941l82.221-7.194h-.001l.059-.006zm.414-23.293c5.324-.553 9.238-5.277 8.77-10.629-.47-5.371-5.178-9.354-10.541-8.94l-82.22 7.193-.06.006c-5.323.553-9.237 5.277-8.77 10.629.47 5.372 5.179 9.354 10.542 8.941l82.22-7.193v-.001l.059-.006zm-.207-23.293a9.825 9.825 0 008.77-10.629c-.47-5.371-5.178-9.353-10.541-8.94l-82.22 7.193-.06.006c-5.323.553-9.237 5.277-8.77 10.63.47 5.37 5.179 9.353 10.542 8.94l82.22-7.193v-.001l.059-.006z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSpringInverse;
