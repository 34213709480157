import React from 'react';

import { apiBaseUrl } from '../../utils';

import './CardCustomer.scss';

/**
 * @return {html}
 */
const CardCustomer = ({ card }) => (
  <div className="sw-card-customer">
    <img
      alt={card.image.alternativeText}
      src={`${apiBaseUrl()}${card.image.url}`}
      title={card.image.caption}
    />
  </div>
);

export default CardCustomer;
