/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBarChartInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm137.323 32.785a9.264 9.264 0 00-6.57-2.785h-.078c-5.098 0-9.28 4.148-9.322 9.246a9.264 9.264 0 001.59 5.29l-27.946 28.016a9.268 9.268 0 00-5.18-1.626l-.078-.001a9.255 9.255 0 00-5.257 1.635L112.1 90.177a9.261 9.261 0 001.634-5.18c.042-5.142-4.106-9.359-9.246-9.4l-.079-.001c-5.098 0-9.28 4.148-9.322 9.246a9.272 9.272 0 001.629 5.336l-22.128 22.127a9.273 9.273 0 00-5.188-1.632h-.078c-5.098 0-9.28 4.148-9.322 9.246-.042 5.141 4.106 9.358 9.246 9.4h.078c5.099 0 9.28-4.148 9.323-9.246a9.274 9.274 0 00-1.625-5.331l22.129-22.128a9.27 9.27 0 005.183 1.628h.078a9.254 9.254 0 005.251-1.63l12.385 12.384a9.254 9.254 0 00-1.631 5.176c-.042 5.141 4.106 9.358 9.247 9.4h.078c5.097 0 9.279-4.148 9.321-9.246a9.27 9.27 0 00-1.629-5.339l27.928-28a9.26 9.26 0 005.238 1.66h.078c5.098 0 9.28-4.148 9.322-9.247.02-2.49-.93-4.84-2.677-6.615zm-18.665 35.212a2.666 2.666 0 012.666-2.666h16a2.666 2.666 0 012.666 2.666v71.345l-21.332 1.866v-73.21zm-11.323 36.005v38.197l-21.332 1.866v-40.063a2.667 2.667 0 012.666-2.666h16a2.667 2.667 0 012.666 2.666zm-33.337 41.114v-43.791a2.667 2.667 0 00-2.667-2.666H95.332a2.667 2.667 0 00-2.667 2.666v45.657l21.333-1.866zm-32.666-31.114v33.971L60 179.84v-35.838a2.667 2.667 0 012.667-2.667h15.999a2.667 2.667 0 012.666 2.667z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconBarChartInverse;
