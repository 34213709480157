import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

// utils
import { formatUrl, isExternalLink } from '../../utils';

const DynamicLink = ({ children, to, ...rest }) => {
  if (isExternalLink(to)) {
    return (<a href={formatUrl(to)} rel="noreferrer nofollow" target="_blank" {...rest}>{children}</a>);
  }

  const isHashLink = to.includes('#');
  return isHashLink ? <NavHashLink smooth to={to} {...rest}>{children}</NavHashLink> : <Link to={formatUrl(to)} {...rest}>{children}</Link>;
};

export default DynamicLink;
