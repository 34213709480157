/* eslint-disable react/no-danger, array-callback-return, jsx-a11y/label-has-associated-control, no-prototype-builtins, max-len */
import React, { useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';

import FormControlWrapper from '../FormControlWrapper';

import './FormContact.scss';

function isValidEmail(email) {
  const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
  return !!reg.test(email);
}

const validateForm = (form, items = []) => {
  const validationErrors = [];

  items.map(item => {
    if (item.required) {
      if (item.type === 'text' && !form[item.id].value) {
        validationErrors[item.id] = `Die ${item.title} ist ungültig.`;
      }

      if (item.type === 'email' && !isValidEmail(form[item.id].value)) {
        validationErrors[item.id] = `Die ${item.title} ist ungültig.`;
      }

      if (item.type === 'checkbox' && !form[item.id].checked) {
        validationErrors[item.id] = `Die ${item.title} ist ungültig.`;
      }
    }
  });

  return {
    hasErrors: Object.keys(validationErrors).length > 0,
    validationErrors,
  };
};

const removeValidationError = (validationErrors, key) => {
  delete validationErrors[key];
  return validationErrors;
};

const FormContact = ({ component }) => {
  const { t } = useTranslation();

  let items =
    [
      {
        autoComplete: 'given-name',
        helpText: null,
        id: 'VORNAME',
        required: true,
        title: 'Vorname',
        type: 'text',
      },
      {
        autoComplete: 'family-name',
        id: 'NACHNAME',
        required: true,
        title: 'Nachname',
        type: 'text',
      },
      {
        autoComplete: 'email',
        id: 'EMAIL',
        required: true,
        title: 'E-Mail',
        type: 'email',
      },
    ];

  // american spelling for autocomplete
  const organisationField = {
    autoComplete: 'organization',
    id: 'ORGANISATION',
    required: false,
    title: 'Organisation',
    type: 'text',
  };

  const phoneNumberField = {
    autoComplete: 'tel',
    id: 'TELEFONNUMMER',
    required: false,
    title: 'Telefonnummer',
    type: 'text',
  };

  if (component.organisation) {
    items = [...items, organisationField];
  }

  if (component.phoneNumber) {
    items = [...items, phoneNumberField];
  }

  const messageField = {
    autoComplete: 'off',
    helpText: null,
    id: 'NACHRICHT',
    required: false,
    title: 'Ihre Nachricht',
    type: 'textarea',
  };

  const optInField = {
    type: 'checkbox',
    id: 'OPT_IN',
    required: true,
  };

  const botField = {
    type: 'checkbox',
    id: 'accept_all',
    required: true,
  };

  const validationItems = [...items, optInField];

  const form = useRef(null);
  const [resultMessage, setResultMessage] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(form.current);
    const { hasErrors, validationErrors } = validateForm(event.target, validationItems);

    if (hasErrors) {
      setErrors(validationErrors);
      return;
    }

    setIsSending(true);

    const requestOptions = { method: 'POST', body: formData, redirect: 'follow' };
    // @ts-ignore
    fetch(`${component.url}?isAjax=1`, requestOptions)
      .then(response => {
        setIsSending(false);
        return response.json();
      })
      .then(result => setResultMessage(result.message))
      // eslint-disable-next-line no-console
      .catch(error => console.log('error', error));
  };

  const handleControlFocus = (event) => {
    const validationErrors = removeValidationError({ ...errors }, event.target.id);
    setErrors(validationErrors);
  };

  if (items.length <= 0) {
    return <></>;
  }

  const showSuccessMessage = resultMessage && component.successMessage;

  return (
    <>
      {!showSuccessMessage && (
        <div className="sw-form-contact">
          <form ref={form} onSubmit={handleSubmit} action="#" method="GET" encType="multipart/form-data">
            <div className="grid-1x1fr">
              <div className="grid-1x1fr-2x1fr">
                {
                  items.map((item, key) => {
                    const formControlClasses = `form-control ${errors?.hasOwnProperty(item.id) ? 'error' : ''}`.trim();

                    return (
                      <div key={`item-${key + 1}`}>
                        <label htmlFor={item.id} className="label">{item.title} <span>({item.required ? t('common.required') : t('common.optional')})</span></label>
                        <FormControlWrapper helpText={item?.helpText}>
                          <input
                            autoComplete={item.autoComplete}
                            className={formControlClasses}
                            id={item.id}
                            maxLength={255}
                            name={item.id}
                            type={item.type}
                            onFocus={handleControlFocus}
                          />
                        </FormControlWrapper>
                      </div>
                    );
                  })
                }
              </div>
              {
                component.message && (
                  <div className="message">
                    <label htmlFor={messageField.id} className="label">{messageField.title} <span>({messageField.required ? t('common.required') : t('common.optional')})</span></label>
                    <FormControlWrapper helpText={messageField.helpText}>
                      <textarea
                        id={messageField.id}
                        name={messageField.id}
                        className={`form-control ${errors?.hasOwnProperty(messageField.id) ? 'error' : ''}`.trim()}
                      />
                    </FormControlWrapper>
                  </div>
                )
              }
              <div className="form-footer">
                {
                  component.infoMessage && (
                    <div className="info-message">
                      <div className="strapi-markdown">
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                          {component.infoMessage}
                        </ReactMarkdown>
                      </div>
                    </div>
                  )
                }
                <div className="opt-in">
                  <div>
                    <label className="checkbox">
                      <input
                        id={optInField.id}
                        name={optInField.id}
                        type={optInField.type}
                        value={1}
                        onFocus={handleControlFocus}
                      />
                      <span className={`checkmark ${errors?.hasOwnProperty(optInField.id) ? 'error' : ''}`.trim()} />
                      <div className="strapi-markdown">
                        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                          {component.optInMessage}
                        </ReactMarkdown>
                      </div>
                    </label>
                  </div>

                  <div className="bot-field">
                    <input
                      autoComplete="off"
                      id={botField.id}
                      name={botField.id}
                      tabIndex={-1}
                      type={optInField.type}
                      value={1}
                    />
                  </div>
                </div>
                <div className="buttons">
                  <div className="sw-button-group">
                    {
                      !resultMessage && !isSending && (
                        <button
                          type="submit"
                          className="btn btn-pacific-blue no-border no-box-shadow"
                        >
                          {t('labels.send')}
                        </button>
                      )}
                    {
                      isSending && (
                        <button
                          type="button"
                          className="btn btn-pacific-blue no-border no-box-shadow"
                        >
                          {t('labels.sending')}
                        </button>
                      )}
                    {
                      resultMessage && (
                        <button
                          type="button"
                          className="btn btn-pacific-blue no-border no-box-shadow"
                        >
                          {t('labels.sent')}
                        </button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
      {
        showSuccessMessage && (
          <div className="sw-form-contact-success">
            {component.successMessage}
          </div>
        )
      }
    </>
  );
};

export default FormContact;
