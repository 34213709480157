/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTeamEvents({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M110.1 57.599c.237 1.84.88 3.654 2.052 5.256l4.893 6.687-5.776 7.894a6.297 6.297 0 01-2.763 2.142l-20.156 8.04.02 100.541a7.592 7.592 0 01-7.595 7.594 7.592 7.592 0 01-7.595-7.594v-45.266h-5.538v45.266c0 4.196-3.4 7.594-7.595 7.594a7.592 7.592 0 01-7.595-7.594v-.567V186.321v-1.075-1.288l.001-.473v-1.009-1.659l.001-1.196.01-40.433.001-1.01V136.822v-.407l.001-1.076v-1.446-24.512L42.27 131.72a6.327 6.327 0 01-8.386 3.13 6.331 6.331 0 01-3.129-8.386l21.102-46.235c1.152-2.518 3.742-3.853 6.35-3.607.293-.04 22.601-.174 23.493-.18h.028c.111 0 .205.06.316.065l20.084-8.011 7.973-10.897zm7.51-6.579c2.819-2.075 6.778-1.45 8.844 1.371l11.782 16.104 20.083 8.011h.262c1.179.002 6.154.019 11.232.04l.709.002c5.789.024 11.489.053 11.637.073 2.611-.246 5.195 1.093 6.347 3.607l21.103 46.236a6.33 6.33 0 01-11.515 5.256l-10.197-22.339.015 78.777a7.593 7.593 0 01-7.595 7.595 7.593 7.593 0 01-7.595-7.595v-45.265h-5.539v45.265a7.593 7.593 0 01-7.595 7.595 7.593 7.593 0 01-7.595-7.595v-.737-1.404l.001-1.742v-1.338c.004-17.801.016-67.655.019-87.678v-1.517-.71l.001-1.926v-3.488l-20.157-8.04a6.295 6.295 0 01-2.763-2.143l-12.855-17.572a6.324 6.324 0 011.371-8.843zM70.41 44c8.173 0 14.801 6.622 14.801 14.8 0 8.172-6.628 14.795-14.8 14.795-8.178 0-14.798-6.623-14.798-14.795 0-8.178 6.62-14.8 14.798-14.8zm99.542 0c8.178 0 14.797 6.622 14.797 14.8 0 8.172-6.619 14.795-14.797 14.795-8.172 0-14.8-6.623-14.8-14.795 0-8.178 6.628-14.8 14.8-14.8z" />
    </svg>
  );
}

export default SvgIconTeamEvents;
