/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCellPhoneInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm114.449 37.843V172.157c0 7.843-7.843 7.843-7.843 7.843H93.843C86 180 86 172.157 86 172.157V67.843C86 60 93.843 60 93.843 60h52.763c7.843 0 7.843 7.843 7.843 7.843zm-45.632 3.565h22.816v-5.701h-22.816v5.701zm-11.409 91.265h45.633V77.132H97.408v85.541z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCellPhoneInverse;
