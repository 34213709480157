/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPdfInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm135.618 62.474l-30.35-31.767a2.288 2.288 0 00-1.651-.707H98.382c-5.063 0-9.181 4.147-9.181 9.244v32.295H70.869c-3.788 0-6.869 3.102-6.869 6.917v34.627c0 3.814 3.081 6.917 6.869 6.917H89.2v20.772c0 5.088 4.119 9.228 9.18 9.228h68.696c5.062 0 9.181-4.137 9.181-9.223V94.075c0-.597-.23-1.17-.64-1.6zM144.184 66.23l24.915 26.078h-24.915V66.23zm-73.315 79.155a2.297 2.297 0 01-2.287-2.302v-34.627a2.297 2.297 0 012.287-2.302h61.865a2.296 2.296 0 012.286 2.302v34.627a2.297 2.297 0 01-2.286 2.302H70.869zm100.807 25.392a4.609 4.609 0 01-4.599 4.608H98.382c-2.536 0-4.6-2.069-4.6-4.613V150h38.952c3.787 0 6.869-3.103 6.869-6.917v-34.627c0-3.815-3.082-6.917-6.869-6.917H93.783V69.244c0-2.552 2.063-4.629 4.599-4.629h41.22v30a2.3 2.3 0 002.291 2.308h29.783v73.854zM78.632 116.12V136h3.836v-6.664h4.67c1.241 0 2.316-.317 3.224-.952a6.695 6.695 0 002.14-2.464 6.88 6.88 0 00.78-3.192c0-.821-.158-1.624-.474-2.408a7.089 7.089 0 00-1.334-2.128 6.269 6.269 0 00-1.973-1.512 5.6 5.6 0 00-2.502-.56h-8.367zm8.283 9.828h-4.447v-6.44h4.28c.464 0 .89.121 1.28.364.407.243.731.607.972 1.092.26.485.39 1.073.39 1.764 0 .635-.112 1.204-.334 1.708-.222.485-.519.859-.89 1.12-.37.261-.787.392-1.25.392zm9.036-9.828V136h7.172c1.982 0 3.697-.411 5.142-1.232a8.47 8.47 0 003.391-3.472c.797-1.493 1.196-3.248 1.196-5.264 0-1.829-.362-3.491-1.084-4.984a8.297 8.297 0 00-3.253-3.584c-1.445-.896-3.243-1.344-5.392-1.344h-7.172zm12.342 6.524c.463.971.695 2.1.695 3.388 0 1.251-.232 2.38-.695 3.388a5.438 5.438 0 01-1.974 2.352c-.871.56-1.936.84-3.196.84h-3.336v-13.104h3.336c1.241 0 2.297.28 3.168.84.871.541 1.539 1.307 2.002 2.296zm7.556-6.524V136h3.836v-8.232h7.839v-3.136h-7.839v-5.124h9.423v-3.388h-13.259z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPdfInverse;
