/* eslint-disable max-len */
import * as React from 'react';

function SvgIconStructure({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M206.666 189.512v-18.239s0-8.314-8.314-8.314h-4.723v-46.427s0-8.314-8.227-8.314h-61.304v-43.35h4.724s8.313 0 8.313-8.314v-18.24s0-8.314-8.313-8.314h-18.139s-8.313 0-8.313 8.314v18.24s0 8.314 8.313 8.314h4.724v43.35H54.103s-8.227 0-8.227 8.314v58.601h-4.724s-8.313 0-8.313 8.313v18.241s0 8.313 8.314 8.313H59.29s8.314 0 8.314-8.313v-18.241s0-8.313-8.314-8.313h-4.723V118.24c0-1.33 1.33-1.33 1.33-1.33h59.509v52.262h-4.724s-8.313 0-8.313 8.313v18.239s0 8.314 8.313 8.314h18.139s8.313 0 8.313-8.314v-18.239s0-8.313-8.313-8.313h-4.724V116.91h59.509c1.331 0 1.331 1.33 1.331 1.33v44.719h-4.724s-8.313 0-8.313 8.314v18.24s0 8.313 8.313 8.313h18.138s8.314 0 8.314-8.313" />
    </svg>
  );
}

export default SvgIconStructure;
