import React from 'react';

import './SectionWrapper.scss';

/**
 * @return {html}
 */
const SectionWrapper = ({
  children,
  classes = '',
  collapseBottom = false,
  collapseTop = false,
  ...rest
}) => {
  // note the spacing on collapse classes, this an exception to the convention used elsewhere
  const className = `sw-section-wrapper${collapseTop ? ' collapse-top' : ''}${collapseBottom ? ' collapse-bottom' : ''} ${classes}`.trim();

  return (
    <section className={className} {...rest}>
      <div className="content-container">
        {children}
      </div>
    </section>
  );
};

export default SectionWrapper;
