/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDots({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M212.859 102.322c9.763 9.763 9.763 25.593 0 35.356-9.763 9.763-25.592 9.763-35.355 0-9.763-9.763-9.763-25.593 0-35.356 9.763-9.763 25.592-9.763 35.355 0zm-75 0c9.763 9.763 9.763 25.593 0 35.356-9.763 9.763-25.592 9.763-35.355 0-9.763-9.763-9.763-25.593 0-35.356 9.763-9.763 25.592-9.763 35.355 0zm-75 0c9.763 9.763 9.763 25.593 0 35.356-9.763 9.763-25.592 9.763-35.355 0-9.763-9.763-9.763-25.593 0-35.356 9.763-9.763 25.592-9.763 35.355 0z" />
    </svg>
  );
}

export default SvgIconDots;
