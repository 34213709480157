/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCalendarInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm140 56.803v76.363L60 174.075V86.803c0-10.91 10.91-10.91 10.91-10.91h10.908V65h16.364v10.894h43.636V65h16.364v10.894h10.909C180 75.894 180 86.803 180 86.803zM70.91 162.197l98.181-8.974V97.712H70.909v64.485zm10.908-53.576h16.364v10.916H81.818v-10.916zm0 21.805h16.364v10.916H81.818v-10.916zm30-21.805h16.364v10.916h-16.364v-10.916zm0 21.805h16.364v10.916h-16.364v-10.916zm30-21.805h16.364v10.916h-16.364v-10.916zm0 21.805h16.364v10.916h-16.364v-10.916z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCalendarInverse;
