import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link, useLocation } from 'react-router-dom';

import Image from '../Image';

import './CardNews.scss';

/**
 * @return {html}
 */
const CardNews = ({ newsItem }) => {
  const location = useLocation();

  const state = {
    from: location.pathname,
  };

  const date = new Date(newsItem.date).toLocaleDateString('de-DE');

  return (
    <div className="sw-card-news">
      <Link
        key={newsItem.id}
        to={{
          pathname: `/news/${newsItem.slug}`,
          state,
        }}
      >
        <Image
          alternativeText={newsItem.image?.alternativeText}
          aspectRatio="21:9"
          caption={newsItem.image?.caption}
          image={newsItem.image?.formats.small || newsItem.image?.formats.thumbnail}
        />
        <div className="details">
          <div className="font-weight-600">
            <span>{date}</span>
          </div>
          <div className="h6">{newsItem.title}</div>
        </div>
      </Link>
      <div className="strapi-markdown">
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {newsItem.teaser}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default CardNews;
