/* eslint-disable max-len */
import * as React from 'react';

function SvgIconNewsInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm135.716 61.676l-14.802 6.927-2.287-4.92 14.802-6.929 2.287 4.922zm-13.775-21.34l-10.487 12.533-4.153-3.494 10.487-12.534 4.153 3.494zM128.6 68.05l32.822 70.387c2.203 4.724-2.698 2.948-2.698 2.948l-31.949-11.578-23.072 10.759v32.509l-16.35 1.43v-26.316l-10.429 4.863c-4.724 2.204-6.927-2.521-6.927-2.521l-9.411-20.183c-.828-1.773-.678-3.191-.17-4.271.833-1.84 2.72-2.721 2.72-2.721l49.808-23.226 11.663-31.909c1.79-4.896 3.993-.171 3.993-.171zm35.051 48.939l.007-5.427 16.342-.023-.007 5.427-16.342.023z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconNewsInverse;
