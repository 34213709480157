/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDirectionsInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm124.054 41.846l14.31 11.21c3.682 2.884 0 5.768 0 5.768l-14.31 11.209c-.89.698-1.563.951-2.075.937v.003H132V70.966h29.979v.003c.992.03 2.075.877 2.075.877zM126 173.125l-12 1.081V65h12v108.125zm-18-48.14H78.02v-.003c-.51.014-1.184-.239-2.074-.936l-14.31-11.209s-3.681-2.885 0-5.769l14.31-11.21s1.083-.847 2.075-.876v-.002H108v30.005z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDirectionsInverse;
