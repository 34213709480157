/* eslint-disable max-len */
import * as React from 'react';

function SvgIconParty({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M132.148 106.122a5.272 5.272 0 010-7.458c4.156-4.156 10.024-3.343 14.22-2.56-.783-4.196-1.596-10.064 2.56-14.22 4.156-4.157 10.024-3.343 14.22-2.56-.783-4.195-1.597-10.063 2.56-14.22 4.156-4.157 10.024-3.343 14.219-2.56-.783-4.195-1.596-10.063 2.561-14.22 4.156-4.156 10.024-3.343 14.219-2.56-.783-4.195-1.596-10.063 2.56-14.22a5.274 5.274 0 017.489 7.427c-.495.996-.122 2.769.315 4.847.752 3.578 1.694 8.06-1.278 11.032-2.956 2.955-7.39 2.033-10.971 1.289-2.046-.425-3.813-.793-4.865-.345-.449 1.056-.081 2.825.346 4.872.745 3.579 1.667 8.007-1.29 10.964-2.956 2.956-7.377 2.035-10.954 1.29-2.05-.428-3.822-.797-4.882-.346-.449 1.056-.081 2.825.346 4.872.745 3.579 1.668 8.007-1.29 10.964-2.956 2.956-7.38 2.034-10.958 1.289-2.049-.427-3.82-.796-4.879-.345-.449 1.056-.08 2.825.346 4.872.746 3.579 1.668 8.007-1.289 10.964-2.969 2.968-7.438 2.028-11.013 1.276-2.083-.438-3.863-.812-4.866-.313a5.272 5.272 0 01-7.426-.031zM120.972 87.483c24.398-24.395 16.534-49.103 16.189-50.143a5.274 5.274 0 00-10.005 3.336c.063.193 6.198 19.512-13.644 39.35a5.276 5.276 0 000 7.457 5.272 5.272 0 007.46 0zM86.041 81.946l19.684 19.684c-2.043 27.519 7.937 54.116 26.388 73.17l-21.009 7.879c-30.111-23.584-41.022-64.732-25.063-100.733z" />
      <path d="M68.694 105.926l-17.27 46.061v.004c4.227 16.882 13.515 31.806 26.663 43.062l21.667-8.124c-23.014-20.612-34.373-50.931-31.06-81.003zM45.582 167.57l-13.236 35.293c-1.582 4.215 2.541 8.385 6.788 6.789l27.925-10.47a94.598 94.598 0 01-21.477-31.612zM116.012 111.934c.809 22.377 10.554 43.523 27.042 58.763h.004l15.398-5.772c3.597-1.35 4.581-5.966 1.878-8.67l-44.322-44.321zM161.989 128.504a5.273 5.273 0 11-7.457-7.458c24.397-24.398 49.103-16.536 50.143-16.19a5.274 5.274 0 01-3.336 10.006c-.193-.064-19.51-6.198-39.35 13.642zM161.989 53.915a5.272 5.272 0 000-7.457 5.273 5.273 0 00-7.457 0 5.272 5.272 0 000 7.457 5.272 5.272 0 007.457 0zM200.817 87.468a5.273 5.273 0 11-10.547 0 5.273 5.273 0 0110.547 0zM184.357 135.949a5.273 5.273 0 107.458 7.457 5.273 5.273 0 00-7.458-7.457zM109.779 53.913a5.272 5.272 0 010 7.458 5.272 5.272 0 01-7.457 0 5.272 5.272 0 010-7.458 5.272 5.272 0 017.457 0z" />
    </svg>
  );
}

export default SvgIconParty;
