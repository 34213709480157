/* eslint-disable max-len */
import * as React from 'react';

function SvgIconLocationInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm80.399 30c26.179 0 47.4 21.687 47.4 48.439S126.049 180 120.399 180C113.769 180 73 135.191 73 108.439 73 81.687 94.222 60 120.399 60zm0 71.125c13.084 0 23.69-10.606 23.69-23.689 0-13.084-10.606-23.69-23.69-23.69-13.083 0-23.689 10.606-23.689 23.69 0 13.083 10.606 23.689 23.689 23.689z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconLocationInverse;
