/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDataManagement({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M105.389 123.708h18.279v-7.266l11.865 9.246-11.865 10.218v-7.274h-18.279v-4.924zM220.176 64.017c0 11.693-32.227 12.133-40.191 12.018l-.713-.01c-9.012-.114-39.474-.5-39.474-12.008 0-11.506 30.462-11.893 39.474-12.008l.713-.009.706.01c9.008.113 39.485.497 39.485 12.007z" />
      <path d="M179.225 80.572l.704.01c6.949.115 30.727-.222 40.142-7.795l.109 33.003c-.036.034-.454.388-.454.388-9.655 7.308-32.932 7.63-39.793 7.519l-.785-.012c-7.719-.115-29.766-.445-39.043-7.507h-.206l-.055-31.74-.045 31.497V72.827c9.119 7.317 31.701 7.636 39.426 7.745z" />
      <path d="M179.929 118.599l-.73-.011c-7.749-.112-30.291-.436-39.4-7.749v33.112l.045-31.5.055 31.742h.206c9.302 7.08 31.445 7.392 39.106 7.5l.722.011c6.861.112 30.138-.21 39.793-7.511 0 0 .418-.354.454-.394l-.109-33c-9.415 7.578-33.193 7.916-40.142 7.8z" />
      <path d="M179.146 155.689l.782.012c6.949.117 30.727-.221 40.143-7.803l.109 33.008-.454.385c-9.656 7.307-32.932 7.632-39.793 7.515l-.759-.011c-7.696-.111-29.782-.43-39.07-7.504h-.205l-.056-31.739-.045 31.498v-33.105c9.089 7.293 31.55 7.628 39.348 7.744zM59.651 89.113l.712-.01.748.01c9.062.119 39.445.516 39.445 12.009 0 11.691-32.226 12.132-40.193 12.017l-.704-.009c-9.002-.114-39.48-.498-39.48-12.008 0-11.508 30.465-11.895 39.472-12.01z" />
      <path d="M60.31 117.689l-.704-.01c-7.727-.109-30.308-.428-39.426-7.746v33.105l.046-31.498.052 31.743h.207c9.276 7.059 31.314 7.391 39.038 7.507l.79.012c6.86.108 30.137-.214 39.791-7.519 0 0 .419-.354.456-.39l-.107-33.002c-9.418 7.581-33.197 7.918-40.143 7.798z" />
      <path d="M59.537 155.693l.773.012c6.946.113 30.725-.225 40.143-7.801l.107 33.001c-.037.036-.456.389-.456.389-9.654 7.307-32.93 7.628-39.79 7.515l-.715-.01c-7.655-.107-29.808-.418-39.114-7.505h-.207l-.052-31.739-.046 31.498v-33.108c9.091 7.3 31.566 7.633 39.357 7.748z" />
    </svg>
  );
}

export default SvgIconDataManagement;
