import React from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import ButtonLink from '../ButtonLink/ButtonLink';
import Icon from '../Icon';

import './CardContent.scss';

/**
 * @return {html}
 */
const CardContent = ({ card }) => (
  <div className="sw-card-content">
    <div className="header">
      {
        card.icon && (
          <div>
            <Icon name={card.icon} width="3rem" height="3rem" />
          </div>
        )
      }
      <div>
        <h3 className="h6">{card.title}</h3>
      </div>
    </div>
    <div className="content strapi-markdown">
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
        {card.content}
      </ReactMarkdown>
    </div>
    <div className="footer">
      {
        (card.linkText && card.linkUrl) && (
          <ButtonLink text={card.linkText} to={card.linkUrl} variant="pacific-blue" />
        )
      }
    </div>
  </div>
);

export default CardContent;
