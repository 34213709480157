import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

import ShareIconFacebook from './ShareIconFacebook';
import ShareIconLinkedIn from './ShareIconLinkedIn';
import ShareIconTwitter from './ShareIconTwitter';
import ShareIconWhatsapp from './ShareIconWhatsapp';
import ShareIconXing from './ShareIconXing';

import './SocialMediaShare.scss';

/**
 * @return {html}
 */
const SocialMediaShare = ({ sharedUrl = null }) => {
  const actualSharedUrl = typeof window !== 'undefined' ? sharedUrl ?? window.location.href : null;

  if (!actualSharedUrl) {
    return (<></>);
  }

  return (
    <div className="sw-social-media-share">
      <ButtonGroup>
        <ButtonIcon to="https://www.instagram.com/syncworkag" name="Instagram" title="Instagram" height="2rem" width="2rem" />
        <ShareIconLinkedIn sharedUrl={actualSharedUrl} />
        <ShareIconXing sharedUrl={actualSharedUrl} />
        <ShareIconTwitter sharedUrl={actualSharedUrl} />
        <ShareIconFacebook sharedUrl={actualSharedUrl} />
        <ShareIconWhatsapp sharedUrl={actualSharedUrl} />
      </ButtonGroup>
    </div>
  );
};

export default SocialMediaShare;
