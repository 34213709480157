/* eslint-disable max-len */
import * as React from 'react';

function SvgIconFulfilledInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40.004 30.008c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10V206.05L40.473 220.008h-.47v-190zM158.81 64c5.382 0 8.559 1.976 10.445 4.313l-9.698 8.141v-.01h-10.138l-.058-.001-.064-.002H82.705h-.053l-.085.003H72.445v85.057l87.112-7.792V118.04l12.444-10.443.001 58.351L60 175.967V77.199C60 64 73.192 64 73.192 64h85.618zM180 88.616l-64.062 53.755-26.248-31.28 9.54-8.255 18.276 21.991 54.495-45.743L180 88.616z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconFulfilledInverse;
