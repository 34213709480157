import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import JobPageContent from '../JobPageContent/JobPageContent';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';

// Services
import ApiService from '../../services/ApiService';

// Store
import { selectJobBySlug } from '../../state/store/slices/jobsSlice';
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

// Utils
import { formatUrl, person2Team } from '../../utils';

import './JobPage.scss';

const buildData = (job) => {
  const header = {
    title: job.title,
    subTitle: null,
    image: null,
    imageOverlay: 'None',
    theme: 'Syncwork',
  };

  return {
    header,
    ...job,
    team: person2Team(job.contactPerson),
  };
};

const JobPage = ({ useCareerDomainRedirect }) => {
  // @ts-ignore
  const { slug } = useParams();
  const mainMenu = useAppSelector(selectMainMenu);
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [data, setData] = useState(null);
  const setDataAndPossiblyScroll = (data2) => {
    setData(data2);
    scrollToHash(scrolledRef, hash);
  };

  // retrieve the job from the store, this should have been previously fetched when the jobs list was loaded
  const job = useAppSelector(selectJobBySlug(slug));

  useEffect(() => {
    if (useCareerDomainRedirect) {
      window.location.href = formatUrl(`https://karriere.syncwork.de/jobs/${slug}`);
      return;
    }

    // if no job in the store (direct use of url) fetch it from the API
    if (!job) {
      ApiService.get(`/jobs?slug=${slug}`).then(response => {
        if (response.data.length) {
          setDataAndPossiblyScroll(buildData(response.data[0]));
        }
      });
    } else {
      setDataAndPossiblyScroll(buildData(job));
    }
  }, [slug, job]);

  if (!data) {
    return (<></>);
  }

  // show loader as long there is no site menu
  if (!mainMenu) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <CookieConsentBox googleTagManagerId={mainMenu.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={data.header} mainMenu={mainMenu} meta={data.meta} />
      <div className="sw-page">
        <PageHeader data={data} />
        <JobPageContent data={data} />
        <PageFooter team={data.team} />
      </div>
    </>
  );
};

export default JobPage;
