import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import Image from '../Image';

import './CardImageText.scss';

/**
 * @return {html}
 */
const CardImageText = ({ card }) => (
  <div className="sw-card-image-text">
    <div className="image">
      <Image
        alternativeText={card.image?.alternativeText}
        caption={card.image?.caption}
        image={card.image?.formats.small || card.image?.formats.thumbnail}
      />
    </div>
    <div className="strapi-markdown">
      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
        {card.content}
      </ReactMarkdown>
    </div>
  </div>
);

export default CardImageText;
