import React from 'react';

import './ButtonGroup.scss';

/**
 * @return {html}
 */
const ButtonGroup = ({ children }) => (
  <div className="sw-button-group">
    {children}
  </div>
);

export default ButtonGroup;
