import React from 'react';

import { useTranslation } from 'react-i18next';
import ContactPerson from '../ContactPerson/ContactPerson';
import SectionWrapper from '../SectionWrapper';

import './ContactTeam.scss';

/**
 * @return {html}
 */
const ContactTeam = ({ team }) => {
  const { t } = useTranslation();

  const address = team.address || team.people[0].address;
  const phoneBusiness = team.phoneBusiness || team.people[0].phoneBusiness;
  const phoneMobile = team.phoneMobile || team.people[0].phoneMobile;
  const email = team.email || team.people[0].email;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const contactTeamTitleTKey = (team) => {
    if (team.people.length === 1) {
      return (team.people[0].gender === 'Female') ? 'contact-team.your-contact-f' : 'contact-team.your-contact-m';
    }

    return 'contact-team.your-contacts';
  };

  return (
    <SectionWrapper>
      <div className="sw-team">
        <header>
          <div className="team-heading">{t(contactTeamTitleTKey(team))}</div>
        </header>

        <div className="persons">
          {
            team.people.map(person => (
              <ContactPerson key={person.id} person={person} />
            ))
          }
          <div className="contact">
            <div className="font-weight-700">
              {team.name}
            </div>
            {
              team.description && (
                <div>
                  {team.description}
                </div>
              )
            }
            {
              address && (
                <div className="mb-1">
                  <div>
                    {address.addressLine1}
                  </div>
                  {
                    address.addressLine2 && (
                      <div>
                        {address.addressLine2}
                      </div>
                    )
                  }
                  <div>
                    {address.postcode} {address.city}
                  </div>
                </div>
              )
            }
            {
              phoneBusiness && (
                <div>
                  <a className="sw-link" href={`tel:${phoneBusiness}`} rel="noreferrer nofollow" target="_blank">
                    {phoneBusiness}
                  </a>
                </div>
              )
            }
            {
              phoneMobile && (
                <div>
                  <a className="sw-link" href={`tel:${phoneMobile}`} rel="noreferrer nofollow" target="_blank">
                    {phoneMobile}
                  </a>
                </div>
              )
            }
            {
              email && (
                <div>
                  <a className="sw-link" href={`mailto:${email}`} rel="noreferrer nofollow" target="_blank">
                    {email}
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default ContactTeam;
