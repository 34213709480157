import React from 'react';

import Image from '../Image';
import DynamicLink from '../DynamicLink';
import { apiBaseUrl } from '../../utils';

import './CardPublication.scss';

const OverlayComponent = ({ title, summary }) => (
  <div className="overlay-component">
    <div className="h6">{title}</div>
    <div>{summary}</div>
  </div>
);

/**
 * @return {html}
 */
const CardPublication = ({ card }) => (
  <div className="sw-card-publication">
    <DynamicLink
      key={card.id}
      to={`${apiBaseUrl()}${card.file.url}`}
    >
      <Image
        alternativeText={card.image?.alternativeText}
        aspectRatio="9:13"
        caption={card.image?.caption}
        image={card.image?.formats.small || card.image?.formats.thumbnail}
        overlayComponent={<OverlayComponent title={card.title} summary={card.summary} />}
      />
    </DynamicLink>
  </div>
);

export default CardPublication;
