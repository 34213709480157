/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPublic({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M69.255 101.154l-.004 105.921-39.07 2.876V101.154h39.074zm69.714 0V201.94l-39.168 2.88V101.154h39.168zm71.118 0v95.657l-40.104 2.947v-98.604h40.104zM119.813 30l90.369 49.68-180-.018L119.813 30z" />
    </svg>
  );
}

export default SvgIconPublic;
