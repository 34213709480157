/* eslint-disable max-len */
import * as React from 'react';

function SvgIconApple({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M124.597 77.955c3.516-5.871 9.08-14.275 16.107-22.338h.008c5.952-6.844 17.608-15.627 17.608-15.627 1.751-1.324 1.563-3.168-.428-4.096l-14.088-6.584c-1.992-.931-4.7-.252-6.02 1.504 0 0-10.419 14.947-22.682 46.765-37.919-17.15-73.961 6.872-73.961 48.645 0 43.633 33.57 109.913 78.968 89.906 47.814 20.691 78.993-46.269 78.993-89.906 0-42.133-32.399-66.836-74.505-48.269z" />
      <path d="M116.103 63.818c2.192-.184 4.128-2.124 4.304-4.312 0 0 1.407-17.063-10.484-28.966-11.927-11.907-28.959-10.487-28.959-10.487-2.192.184-4.127 2.123-4.308 4.311 0 0-1.431 17.071 10.476 28.979 11.9 11.895 28.971 10.475 28.971 10.475z" />
    </svg>
  );
}

export default SvgIconApple;
