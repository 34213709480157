/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPublicTransportInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm41.797 33.398c1.242-1.242 3.398-2.53 5.086-3.07.75-.234 7.875-.305 33.422-.305L152.766 60l1.523.54c3.609 1.288 6.117 3.796 7.57 7.616l.516 1.336.07 43.781c.063 42.51.125 45.131-.85 47.282-.122.268-.259.529-.415.859-1.641 3.445-6.305 6.633-9.68 6.633-.82 0-.75.094 5.133 5.976l5.976 5.977h-18.515l-5.977-5.977-5.976-5.976h-23.672l-5.977 5.976L96.516 180H78l5.977-5.977c5.882-5.882 5.953-5.976 5.132-5.976-3.375 0-8.039-3.188-9.68-6.633-.152-.323-.286-.579-.405-.842-.963-2.126-.907-4.713-.907-46.572 0-42.516 0-43.172.469-45 .586-2.227 1.57-3.984 3.21-5.602zm50.461 14.414v-5.625h-23.906v5.626h23.906zm17.812 48.047V90.234H90.539v35.625h59.531zm-42.703 17.532c-1.148-2.485-3.773-4.594-6.164-4.993-1.758-.281-2.11-.304-3.281-.117-2.602.399-5.11 2.227-6.469 4.735-.773 1.406-.797 1.57-.797 3.937 0 2.227.07 2.602.656 3.797a9.171 9.171 0 004.336 4.242c1.407.68 1.805.75 3.68.75 1.781 0 2.32-.094 3.469-.633 1.875-.89 3.703-2.671 4.523-4.476.61-1.266.68-1.711.68-3.68 0-1.922-.094-2.414-.633-3.562zm41.953-.118c-1.195-2.25-2.953-3.82-5.109-4.593-4.266-1.5-9.023.539-10.969 4.711-.539 1.148-.633 1.64-.633 3.562 0 1.969.071 2.414.68 3.68.82 1.805 2.649 3.586 4.523 4.476 1.149.539 1.688.633 3.469.633 1.875 0 2.274-.07 3.68-.75a9.173 9.173 0 004.336-4.242c.586-1.195.656-1.57.656-3.797 0-2.226-.07-2.578-.633-3.68z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPublicTransportInverse;
