/* eslint-disable max-len */
import * as React from 'react';

function SvgIconIdeaInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm125.546 108.05a4.053 4.053 0 013.022-3.363l11.415-2.864-9.338-12.499a4.065 4.065 0 01-.809-2.435v-8.694c.444-32.645-33.363-56.61-63.84-45.396 9.95 11.454 9.074 30.39-2.061 40.852a13.362 13.362 0 00-3.888 9.392l-.053 11.843-.002.475-.02 4.569c0 6.713-5.147 12.176-11.648 12.71l6.132 6.156a4.069 4.069 0 011.064 3.854L88.708 180l44.608-3.917 1.996-8.014a4.045 4.045 0 013.922-3.074h14.716c4.425 0 8.233-3.315 8.859-7.711l2.737-19.234zM67.68 97.829a2.84 2.84 0 00-.056-.054C53.522 84.272 63.486 60.01 82.937 60.09c19.518-.04 29.35 24.137 15.331 37.77l-.023.023c-3.984 3.973-6.273 9.482-6.284 15.119l-.055 4.176H73.988v-4.135c0-5.672-2.3-11.217-6.308-15.214zm10.926 36.737a4.627 4.627 0 01-4.618-4.636v-4.635h17.897v4.635c0 2.527-2.035 4.636-4.618 4.636h-8.661z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconIdeaInverse;
