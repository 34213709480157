import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';

const ShareIconXing = ({ sharedUrl }) => {
  const xingShareUrl = `https://www.xing.com/app/user?op=share;url=${encodeURIComponent(sharedUrl)}`;

  return (<ButtonIcon to={xingShareUrl} name="Xing" title="Xing" height="2rem" width="2rem" />);
};

export default ShareIconXing;
