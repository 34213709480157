/* eslint-disable react/no-danger, array-callback-return, jsx-a11y/label-has-associated-control, no-prototype-builtins, max-len, no-plusplus, no-restricted-syntax */
import React, { useEffect, useState } from 'react';

import { isValidEmail } from '../../utils';

import './FormSendInBlue.scss';

const validateForm = (formData) => {
  const validationErrors = [];

  for (const entry of formData.entries()) {
    if (entry[0] === 'EMAIL' && !isValidEmail(entry[1])) {
      validationErrors.push(entry);
    }
  }

  return {
    hasErrors: Object.keys(validationErrors).length > 0,
    validationErrors,
  };
};

const decomposeFormString = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  doc.querySelectorAll('[style]').forEach(el => el.removeAttribute('style'));
  const body = doc.getElementsByTagName('body')[0];
  return {
    sibFormHtml: body.innerHTML,
  };
};

const addInputError = (name) => {
  const elements = document.getElementsByName(name);
  for (let i = 0; i < elements.length; i++) {
    elements[i].replaceWith(elements[i].cloneNode(true)); // remove all event listeners
    elements[i].classList.add('error');
    elements[i].addEventListener('focus', () => {
      elements[i].classList.remove('error');
    });
  }
};

const spinnerClass = 'progress-indicator__icon';
const showSpinner = () => {
  const spinners = document.getElementsByClassName(spinnerClass);
  spinners[0].classList.remove('sib-hide-loader-icon');
};

const hideSpinner = () => {
  const spinners = document.getElementsByClassName(spinnerClass);
  spinners[0].classList.add('sib-hide-loader-icon');
};

/**
 * @return {html}
 */
const FormSendInBlue = ({ form }) => {
  const [resultMessage, setResultMessage] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const { hasErrors, validationErrors } = validateForm(formData);

    if (hasErrors) {
      validationErrors.map(error => {
        addInputError(error[0]);
      });
      return;
    }

    showSpinner();

    const requestOptions = { method: 'POST', body: formData, redirect: 'follow' };
    // @ts-ignore
    fetch(`${event.target.action}?isAjax=1`, requestOptions)
      .then(response => {
        hideSpinner();
        return response.json();
      })
      .then(
        (result) => {
          setResultMessage(result.message);
          document.querySelector('#anmeldung')?.scrollIntoView({ behavior: 'smooth' });
        },
      )
      // eslint-disable-next-line no-console
      .catch(error => console.log('error', error));
  };

  const { sibFormHtml } = decomposeFormString(form);

  useEffect(() => {
    const documentForm = document.getElementById('sib-form');
    if (documentForm) {
      documentForm.onsubmit = (event) => {
        handleSubmit(event);
      };
    }
  }, []);

  return (
    <>
      {
        !resultMessage && (
          <div className="sw-form-send-in-blue" dangerouslySetInnerHTML={{ __html: sibFormHtml }} />
        )
      }
      {
        resultMessage && (
          <div className="sw-form-send-in-blue-success">
            {resultMessage}
          </div>
        )
      }
    </>
  );
};

export default FormSendInBlue;
