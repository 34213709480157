/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPerson({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M192.701 207.008v-84.285s0-20.938-23.74-20.938H71.025s-23.74 0-23.74 20.938V220l145.416-12.992zM156.346 56.354c0-20.078-16.275-36.354-36.353-36.354-20.078 0-36.354 16.276-36.354 36.354s16.276 36.353 36.354 36.353 36.353-16.275 36.353-36.353z" />
    </svg>
  );
}

export default SvgIconPerson;
