/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExcelInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm20 43.334v93.337L129.23 180V60L60 73.334zm43.79 70.112l-8.053-15.226c-.305-.568-.619-1.611-.947-3.129h-.124c-.153.715-.513 1.804-1.08 3.263l-8.082 15.092H72.955l14.898-23.372-13.629-23.377h12.813l6.683 14.017c.521 1.108.987 2.423 1.403 3.941h.129c.263-.913.752-2.27 1.468-4.075l7.43-13.888h11.732l-14.016 23.179 14.409 23.571-12.485.004zm72.342-69.598h-42.285V87.62h13.989v9.374h-13.989v9.161h13.846v9.231h-13.846v9.231h13.846v9.231h-13.846v9.23h13.846v9.231h-13.846v13.846h42.285c2.128 0 3.868-1.804 3.868-4.015V77.863c0-2.21-1.74-4.015-3.868-4.015zm-5.363 78.461h-18.461v-9.231h18.461v9.231zm0-18.461h-18.461v-9.231h18.461v9.231zm0-18.462h-18.461v-9.231h18.461v9.231zm0-18.461h-18.461v-9.231h18.461v9.23z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconExcelInverse;
