/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDirections({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M207.727 65.083L186.263 48.27s-1.625-1.27-3.113-1.315v-.005h-44.968v45.01h44.968v-.004c.768.02 1.778-.359 3.113-1.405l21.464-16.814s5.523-4.326 0-8.653zM111.181 201.81l18.001-1.622V38h-18.001v163.81zm-53.968-73.832h44.969V82.969h-44.97v.004c-1.486.044-3.112 1.315-3.112 1.315l-21.464 16.814c-5.523 4.326 0 8.653 0 8.653L54.1 126.569c1.336 1.046 2.345 1.425 3.113 1.404v.005z" />
    </svg>
  );
}

export default SvgIconDirections;
