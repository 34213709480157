/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMentoring({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M220.182 179.324v-57.657s0-15.141-14.975-15.141h-78.816s-2.664.005-5.743 1.139c-1.733-.707-3.808-1.139-6.31-1.139H35.115s-14.933 0-14.933 15.143v75.766l90.909-8.251v-67.081c0-8.784 5.105-12.423 9.36-13.944 8.787 3.44 8.822 13.944 8.822 13.944v65.449l90.909-8.228zM67.559 161.708h7.084v8.789h-7.084v-8.789zm20.868-24.454c0 1.359-.16 2.558-.478 3.598a10.02 10.02 0 01-1.372 2.834c-.6.853-1.308 1.623-2.106 2.293a24.242 24.242 0 01-2.744 1.974c-.842.504-1.693.992-2.553 1.464-.852.468-1.628.987-2.33 1.561a7.589 7.589 0 00-1.723 1.974c-.446.743-.67 1.645-.67 2.707h-7.02c0-2.081.34-3.949 1.022-5.605.68-1.656 2.084-3.099 4.212-4.33a46.601 46.601 0 012.36-1.306 19.182 19.182 0 002.745-1.751 10.052 10.052 0 002.265-2.388c.617-.913.926-1.964.926-3.152 0-.892-.17-1.677-.51-2.357a5.38 5.38 0 00-1.341-1.719 5.589 5.589 0 00-1.947-1.052 7.537 7.537 0 00-2.265-.349c-.936 0-1.787.159-2.553.477a8.733 8.733 0 00-2.074 1.21 10.224 10.224 0 00-1.659 1.656c-.49.616-.904 1.2-1.244 1.751l-5.552-3.821a12.347 12.347 0 012.201-3.502 13.459 13.459 0 013.127-2.58c1.17-.7 2.435-1.231 3.797-1.591a16.36 16.36 0 014.212-.542c1.617 0 3.212.234 4.787.701a12.156 12.156 0 014.244 2.228c1.254 1.02 2.275 2.325 3.063 3.917.786 1.592 1.18 3.492 1.18 5.7zm80.83 16.136h-7.241v-28.508h7.241v28.508zm0 16.914h-7.241v-9.565h7.241v9.565zm23.652-100.086c0-15.032-12.211-27.218-27.273-27.218s-27.273 12.186-27.273 27.218c0 15.031 12.211 27.217 27.273 27.217s27.273-12.185 27.273-27.217zM102 70.323c0-14.974-12.21-27.112-27.273-27.112-15.062 0-27.273 12.138-27.273 27.112 0 14.974 12.21 27.112 27.273 27.112C89.789 97.435 102 85.297 102 70.323" />
    </svg>
  );
}

export default SvgIconMentoring;
