import React from 'react';

import './FormControlRadioButton.scss';

/**
 * @return {html}
 */
const FormControlRadioButton = ({
  id, name, value, label, checked,
}) => (
  <div className={`sw-form-control-radio-button sw-form-control-radio-button--${value}`}>
    <label htmlFor={id}>
      <input type="radio" id={id} name={name} value={value} defaultChecked={checked} />
      <span>{label}</span>
    </label>
  </div>
);

export default FormControlRadioButton;
