/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDecrease({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M194.393 108.255l-.039-.001h-31.23a3.914 3.914 0 01-3.913-3.913 3.914 3.914 0 013.913-3.913h21.856l-63.75-63.75a3.916 3.916 0 010-5.533 3.916 3.916 0 015.533 0l63.752 63.752v-21.86a3.914 3.914 0 013.913-3.913 3.914 3.914 0 013.913 3.913v31.228a3.9 3.9 0 01-1.144 2.847 3.898 3.898 0 01-2.762 1.143h-.042zM34 209.834l46.957-4.108V65.303a4 4 0 00-4-4H38a4 4 0 00-4 4v144.531zM34 209.834l.014.166H34v-.166zM96.605 204.357l46.957-4.109v-87.985a4 4 0 00-4-4h-38.957a4 4 0 00-4 4v92.094zM159.224 198.878l46.957-4.108v-35.559a4 4 0 00-4-4h-38.957a4 4 0 00-4 4v39.667z" />
    </svg>
  );
}

export default SvgIconDecrease;
