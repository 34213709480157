/* eslint-disable max-len */
import * as React from 'react';

function SvgIconQuestionMarkInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm64.306 126.968a4 4 0 00-4 4V180h18.878v-19.032a4 4 0 00-4-4h-10.878zm50.337-54.659c.85-2.725 1.275-5.868 1.275-9.43 0-5.784-1.049-10.765-3.146-14.938-2.099-4.172-4.82-7.593-8.163-10.264-3.345-2.67-7.116-4.615-11.309-5.841A45.375 45.375 0 00120.544 60a44.37 44.37 0 00-11.224 1.419c-3.63.947-7.002 2.336-10.12 4.172a35.787 35.787 0 00-8.332 6.76 31.767 31.767 0 00-4.43 6.211c-.918 1.681-.292 3.743 1.294 4.817l8.634 5.844c1.875 1.269 4.426.725 5.803-1.071.302-.394.616-.793.943-1.198a26.928 26.928 0 014.422-4.34c1.642-1.277 3.487-2.336 5.527-3.17 2.041-.835 4.308-1.252 6.803-1.252 2.041 0 4.052.308 6.038.918 1.982.613 3.712 1.53 5.187 2.754 1.472 1.225 2.662 2.727 3.571 4.506.906 1.781 1.36 3.838 1.36 6.175 0 3.116-.823 5.87-2.465 8.262a26.53 26.53 0 01-6.038 6.259 51.247 51.247 0 01-7.313 4.589c-2.495 1.281-4.592 2.42-6.292 3.422-5.671 3.228-9.412 7.009-11.225 11.349-1.815 4.339-2.72 9.236-2.72 14.687h18.707c0-2.78.595-5.146 1.785-7.093 1.191-1.946 2.721-3.672 4.592-5.174a40.97 40.97 0 016.207-4.089 211.083 211.083 0 006.803-3.839c2.722-1.669 5.158-3.392 7.313-5.174a31.453 31.453 0 005.612-6.008c1.587-2.225 2.807-4.699 3.657-7.427z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconQuestionMarkInverse;
