import * as React from 'react';

function SvgIconSabotage({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 400 400"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <path d="M273.774,217.245c-5.066-11.399-17.416-16.149-34.042-24.383h-0.791
            c6.175-5.375,11.717-11.075,16.625-17.25c-9.975-11.241-28.017-17.1-56.354-16.15c-28.179-0.95-46.225,4.909-56.354,16.15
            c5.067,6.175,10.608,11.875,16.778,17.25h-0.791c-16.621,8.234-28.967,12.984-34.033,24.383
            c-20.104,44.801-16.779,52.709,5.7,71.392c-1.108,0.316-1.9,1.425-1.9,2.533v8.709c0,1.425,1.267,2.691,2.846,2.691h135.667
            c1.583,0,2.85-1.267,2.85-2.691v-8.709c0-1.108-0.791-2.217-1.899-2.533C290.55,269.954,293.875,262.046,273.774,217.245z
            M175.149,162.312c0.95,2.85,4.434,5.066,8.55,5.066c4.75,0,8.546-2.85,8.704-6.333c2.217,0,4.434,0,6.809,0
            c2.379,0,4.754,0,6.971,0c0.149,3.484,3.949,6.333,8.699,6.333c4.117,0,7.601-2.217,8.393-5.066
            c2.217,0.316,4.116,0.633,6.008,1.108c-2.366,20.109-8.858,33.717-16.617,40.683h-26.75c-7.758-6.966-14.092-20.574-16.784-40.683
            C171.191,162.945,173.091,162.628,175.149,162.312z M133.517,213.762c0-4.433,3.642-7.916,7.916-7.916H257.15
            c4.274,0,7.916,3.483,7.916,7.916v67.117c0,4.274-3.642,7.758-7.916,7.758h-9.816v-1.108h-17.101v1.108h-61.887v-1.108H151.25
            v1.108h-9.818c-4.274,0-7.916-3.483-7.916-7.758V213.762z M268.233,291.17v8.709c0,0.476-0.476,0.95-1.108,0.95H131.458
            c-0.629,0-1.104-0.475-1.104-0.95v-8.709c0-0.633,0.475-0.95,1.104-0.95h19.792v1.108h17.096v-1.108h61.887v1.108h17.101v-1.108
            h19.791C267.758,290.22,268.233,290.537,268.233,291.17z"
          />
          <path d="M199.212,157.72c28.337-0.949,46.379,4.909,56.354,16.151
            c-1.108-26.275-17.259-78.675-56.354-76.617c-38.942-2.059-55.087,50.342-56.354,76.617
            C152.987,162.628,171.033,156.771,199.212,157.72z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconSabotage;
