/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPdf({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M57.234 144v-29.82h12.642c1.373 0 2.632.28 3.78.84a9.48 9.48 0 012.982 2.268c.868.952 1.54 2.016 2.016 3.192a9.546 9.546 0 01.715 3.612c0 1.68-.392 3.276-1.177 4.788a10.062 10.062 0 01-3.233 3.696c-1.373.952-2.996 1.428-4.873 1.428h-7.055V144h-5.797zm5.797-15.078h6.72c.7 0 1.33-.196 1.89-.588.56-.392 1.007-.952 1.343-1.68.337-.756.505-1.61.505-2.562 0-1.036-.197-1.918-.589-2.646-.364-.728-.854-1.274-1.47-1.638a3.6 3.6 0 00-1.931-.546H63.03v9.66zM83.402 144v-29.82h10.836c3.248 0 5.964.672 8.148 2.016 2.184 1.316 3.822 3.108 4.914 5.376 1.092 2.24 1.638 4.732 1.638 7.476 0 3.024-.602 5.656-1.806 7.896-1.204 2.24-2.912 3.976-5.124 5.208-2.184 1.232-4.774 1.848-7.77 1.848H83.402zm19.698-14.952c0-1.932-.35-3.626-1.05-5.082-.7-1.484-1.708-2.632-3.024-3.444-1.316-.84-2.912-1.26-4.788-1.26h-5.04v19.656h5.04c1.904 0 3.514-.42 4.83-1.26 1.316-.868 2.31-2.044 2.982-3.528.7-1.512 1.05-3.206 1.05-5.082z"
        clipRule="evenodd"
      />
      <path d="M113.467 114.18V144h5.796v-12.348h11.844v-4.704h-11.844v-7.686h14.238v-5.082h-20.034z" />
      <path
        fillRule="evenodd"
        d="M157.917 31.061l45.857 47.65c.621.646.968 1.506.968 2.4v115.055c0 7.628-6.223 13.834-13.872 13.834H87.075c-7.648 0-13.871-6.21-13.871-13.842V165h-27.7c-5.722 0-10.377-4.654-10.377-10.375v-51.942c0-5.72 4.655-10.375 10.378-10.375h27.699V43.866C73.204 36.221 79.427 30 87.075 30h68.348c.94 0 1.841.383 2.494 1.061zm36.008 47.4l-37.644-39.116v39.117h37.644zM42.05 154.625a3.458 3.458 0 003.455 3.452h93.474a3.458 3.458 0 003.455-3.452v-51.942a3.457 3.457 0 00-3.455-3.452H45.505a3.458 3.458 0 00-3.455 3.452v51.942zm148.82 48.452c3.831 0 6.949-3.1 6.949-6.911V85.385h-45a3.462 3.462 0 01-3.462-3.462v-45H87.075c-3.831 0-6.949 3.114-6.949 6.943v48.442h58.853c5.723 0 10.379 4.654 10.379 10.375v51.942c0 5.721-4.656 10.375-10.379 10.375H80.126v31.158c0 3.815 3.118 6.919 6.95 6.919H190.87z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPdf;
