/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPhoneInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm37.876 41.41c3.547-3.364 9.43-7.894 12.61-10.077h.001c3.181-2.184 3.658-2.021 7.078 3.046 3.417 5.069 9.776 15.04 12.892 20.064 1.893 3.052 2.716 4.333 2.425 5.267-.337 1.078-2.161 1.691-5.542 4.033-20.83 14.427 12.757 63.117 34.081 49.503 3.464-2.211 4.725-3.648 5.864-3.556.989.079 1.885 1.311 4.05 4.189 3.556 4.731 10.55 14.28 14.034 19.304 3.483 5.024 3.46 5.522.215 7.612-3.244 2.09-9.708 5.77-14.199 7.753-32.625 14.401-99.53-82.456-73.508-107.139z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPhoneInverse;
