import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Brand from '../Brand/Brand';
import DynamicLink from '../DynamicLink';
import SvgIconSyncwork from '../Logos/SvgIconSyncwork';
import SvgLogoTechbar from '../Logos/SvgLogoTechbar';

// store
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './MainMenu.scss';

const MainMenuItems = ({
  menuItems,
  toggleActiveMenu,
  active,
}) => (
  <>
    {
      menuItems.map(menuItem => (
        <div key={menuItem.id}>
          {
            menuItem.url ? (
              (menuItem.url === 'https://syncwork.de/' || menuItem.url === 'https://www.syncwork.de/') ? (
                <a href="https://syncwork.de/">
                  {/* want google to follow the links to the homepage (Syncwork AG), <DynamicLink> + "https://" = rel="nofollow" */}
                  <span className="homepage-link">{menuItem.title}</span>
                </a>
              ) : (
                <DynamicLink to={menuItem.url}>
                  <span>{menuItem.title}</span>
                </DynamicLink>
              )
            ) : (
              <>
                {
                    menuItem.pages.length === 1 && !menuItem.pages[0].hashFragments.length ? (
                      // Kontakt
                      <DynamicLink key={menuItem.pages[0].id} to={menuItem.pages[0].slug}>
                        <span>{menuItem.pages[0].menuLabel}</span>
                      </DynamicLink>
                    ) : (
                      <>
                        <button
                          className="toggle"
                          onBlur={() => toggleActiveMenu(null)}
                          onClick={() => toggleActiveMenu(menuItem.id)}
                          type="button"
                        >
                          <span>{menuItem.title}</span>
                          <i className={menuItem.id === active ? 'icon icon-chevron-up' : 'icon icon-chevron-down'} />
                        </button>
                        <div className="menu-sub">
                          <div className={menuItem.id === active ? 'drop drop-active' : 'drop'}>
                            {
                              menuItem.pages.map(page => {
                                if (page.hashFragments.length) {
                                  // Link with hash (Unternehmen, Karriere, Aktuelles)
                                  return (
                                    page.hashFragments.map(hashFragment => {
                                      const url = (hashFragment.fragment) ? `/${page.slug}#${hashFragment.fragment}` : `/${page.slug}#root`;

                                      return (
                                        <DynamicLink
                                          key={hashFragment.id}
                                          to={url}
                                        >
                                          <span>{hashFragment.title}</span>
                                        </DynamicLink>
                                      );
                                    })
                                  );
                                }
                                return (
                                  <DynamicLink
                                    key={page.id}
                                    to={`/${page.slug}`}
                                  >
                                    {page.menuLabel}
                                  </DynamicLink>
                                );
                              })
                            }
                          </div>
                        </div>
                      </>
                    )
                  }
              </>
            )
            }
        </div>
      ))
      }
  </>
);

/**
 * @return {html}
 */
const MainMenu = () => {
  const location = useLocation();
  const mainMenu = useAppSelector(selectMainMenu);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);

  const toggleMobileMenu = (isActive?) => {
    const mobileMenuState = (typeof isActive === 'undefined') ? !activeMobileMenu : isActive;
    setActiveMobileMenu(mobileMenuState);
  };

  const toggleActiveMenu = (id) => {
    setTimeout(() => {
      if (id === activeMenu) {
        setActiveMenu(null);
      } else {
        setActiveMenu(id);
      }
    }, 100);
  };

  useEffect(() => {
    toggleMobileMenu(false);
  }, [location]);

  if (!mainMenu) {
    return <></>;
  }

  const theme = mainMenu.theme.toLowerCase();
  console.log(mainMenu);

  return (
    <nav className={`sw-main-menu ${activeMenu !== null ? 'sw-main-menu-active' : ''}`.trim()}>
      <div className="menu-container">
        <div className="menu-brand">
          {
            (theme === 'techbar') ? (
              <Link to="/">
                <Brand
                  iconComponent={(
                    <SvgLogoTechbar
                      color="#fff"
                      height="1.2rem"
                    />
                  )}
                  suffix={null}
                />
              </Link>
            ) : (
              <Link to="/">
                <Brand
                  iconComponent={(
                    <SvgIconSyncwork
                      color="#fff"
                      height="1.5rem"
                    />
                  )}
                  suffix={(mainMenu.subDomainKey !== 'www') ? mainMenu.title : null}
                />
              </Link>
            )
          }
        </div>

        <div className="menu-top-mobile">
          <div className="toggle">
            <button
              className={`hamburger hamburger--slider ${activeMobileMenu ? 'is-active' : ''}`.trim()}
              type="button"
              onClick={() => toggleMobileMenu()}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner" />
              </span>
            </button>
          </div>
          <div className={`menu-flyout ${activeMobileMenu ? 'is-active' : ''}`.trim()}>
            <MainMenuItems
              menuItems={mainMenu.menuItems}
              toggleActiveMenu={toggleActiveMenu}
              active={activeMenu}
            />
          </div>
        </div>

        <div className="menu-top-desktop">
          <MainMenuItems
            menuItems={mainMenu.menuItems}
            toggleActiveMenu={toggleActiveMenu}
            active={activeMenu}
          />
        </div>
      </div>
    </nav>
  );
};

export default MainMenu;
