import React from 'react';

import './FormControlWrapper.scss';

const FormControlWrapper = ({ helpText = null, children }) => (
  <div className="sw-form-control-wrapper">
    {children}
    {
      helpText && (
        <div className="help-text">
          {helpText}
        </div>
      )
    }
  </div>
);

export default FormControlWrapper;
