import React from 'react';
import { createPortal } from 'react-dom';

const Portal = ({
  children, id = 'root-portal', el = 'div',
}) => {
  const [container] = React.useState(() => document.createElement(el));

  React.useEffect(() => {
    container.setAttribute('id', id);
    container.classList.add(id);
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return createPortal(children, container);
};

export default Portal;
