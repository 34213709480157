// http://prozessdigitalisierung.prozessdigitalisierung.localhost:3000/prozessdigitalisierung-reifegrad
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import SectionWrapper from '../SectionWrapper';
import SelfCheckSegment from '../SelfCheckSegment';
import ReifegradEvaluationChart from '../ReifegradEvaluationChart';
import { camel2Kebab } from '../../utils';

import './SectionReifegrad.scss';
import Icon from '../Icon';

/**
 * @return {html}
 */
const SectionReifegrad = ({ component }) => {
  const { t } = useTranslation();
  const [activeSegment, setActiveSegment] = useState(0);
  const [evaluation, setEvaluation] = useState(null);
  // const [showResults, setShowResults] = useState(true);
  component.theme = 'PacificBlue'; // build in later, time permitting
  const theme = camel2Kebab(component.theme);

  const isAnswersInUrl = window.location.search.split('=').shift() === '?answers';

  const segmentQuestionsAnswered = (questions) => {
    let answered = true;
    questions.forEach(question => {
      const input = document.querySelector(`input[name="${question.uid}"]:checked`);
      if (!input) {
        document.getElementById(`${question.uid}-wrapper`).classList.add('error');
        answered = false;
      }
    });

    return answered;
  };

  const resetAnswers = () => {
    setEvaluation(null);
    setActiveSegment(0);
    // setShowResults(false);
  };

  const segmentNext = (questions) => {
    if (!segmentQuestionsAnswered(questions)) {
      return;
    }

    const next = activeSegment + 1;
    if (component.segments[next]) {
      setActiveSegment(next);
    } else {
      setActiveSegment(0);
    }
  };

  const segmentPrev = () => {
    const prev = activeSegment - 1;
    if (component.segments[prev]) {
      setActiveSegment(prev);
    } else {
      setActiveSegment(component.segments.length - 1);
    }
  };

  const questionPrio = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
  ];
  const grading = {
    0: 'schlecht',
    1: 'schlecht',
    2: 'mittel',
    3: 'mittel',
    4: 'gut',
    5: 'gut',
  };

  const plural = {
    Prozess: "Prozesse",
    Technologie: "Technologien",
  };

  const categorizeAnswers = () => {
    component.kategorienSchlecht = [];
    component.kategorienGut = [];
    component.dimensionsScores = [];

    component.segments.forEach((dimension, dimensionIndex) => {
      let dimensionScore = dimensionIndex;
      dimension.questions.forEach((question, questionIndex) => {
        const questionAnswer = parseInt(question.value, 10);
        const questionValue = questionAnswer * 100;

        dimensionScore += questionValue;

        component.kategorienSchlecht.push({
          uid: question.uid,
          score: questionValue + questionPrio[(dimensionIndex * 3) + questionIndex],
          grading: grading[questionAnswer],
        });

        component.kategorienGut.push({
          uid: question.uid,
          score: questionValue - questionPrio[(dimensionIndex * 3) + questionIndex],
          grading: grading[questionAnswer],
        });
      });

      component.dimensionsScores.push({
        uid: `reifegrad-dimension-${dimensionIndex}-schlecht`,
        score: dimensionScore,
      });
    });

    function compareScores(a, b) {
      return b.score - a.score;
    }

    component.kategorienGut.sort(compareScores);
    component.kategorienSchlecht.sort(compareScores).reverse();
    component.dimensionsScores.sort(compareScores).reverse();
  };

  const evaluateDimensions = (questions) => {
    if (!segmentQuestionsAnswered(questions)) {
      return;
    }

    const dimensions = [...component.segments];
    component.answers = [];
    dimensions.forEach(dimension => {
      dimension.questions.forEach((question) => {
        const inputValue = document.querySelector(`input[name="${question.uid}"]:checked`)?.getAttribute('value');
        question.value = question.value || inputValue;
        component.answers.push(inputValue);
      });
    });

    // console.log('setEvaluation: ', dimensions);
    setEvaluation({ dimensions });
    categorizeAnswers();
  };

  if (isAnswersInUrl) {
    const answers = window.location.search.split('=').pop().split('_');
    component.answers = [...answers];

    component.segments.forEach((dimension, i) => {
      dimension.questions = [];

      for (let j = 1; j < 6; j += 1) { // has to start from 1 or the question key won't match
        if (dimension[`question${j}`]) {
          const value = answers.shift();
          dimension.questions.push({
            uid: `reifegrad-dimension-${i}-question-${j}`,
            question: dimension[`question${j}`],
            value,
          });
        }
      }
    });
    categorizeAnswers();
  }

  const isShareWorking = !!navigator.share;

  function shareResult() {
    if (navigator.share) {
      navigator.share({
        title: 'Syncwork Reifegrad Evaluation',
        text: 'Unsere Ergebnisse',
        url: `${window.location.pathname}?answers=${component.answers.join('_')}`,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      console.log('Share not supported on this browser, do it the old way.');
      window.location.href = `${window.location.pathname}?answers=${component.answers.join('_')}`;
    }
  }

  function windowPrint() {
    window.print();
  }

  return (
    <SectionWrapper
      classes={`sw-section-reifegrad sw-section-reifegrad-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-self-check-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
          {
            evaluation ? (
              <div className="h6">{t('self-check.evaluation')}</div>
            ) : (
              <div>
                <ol className="segment-list">
                  {
                    component.segments.map((segment, i) => (
                      <li key={`reifegrad-dimension-label-${i}`} className={`h5 ${activeSegment === i ? 'active' : ''}`.trim()}>
                        {segment.title}
                      </li>
                    ))
                  }
                </ol>
              </div>
            )
          }
        </div>

        <div>
          {
            evaluation || isAnswersInUrl ? (
              // chart and evaluation
              <div className="evaluation-wrapper">
                <ReifegradEvaluationChart
                  key="self-check-evaluation"
                  segments={component.segments}
                />

                <div>
                  <div className="sw-button-group--fullwidth">
                    <Link
                      to={window.location.pathname}
                      onClick={resetAnswers}
                      className="btn btn-pacific-blue"
                    >
                      Zurück
                    </Link>

                    {/* isShareWorking && (
                      <button type="button" className="btn btn-pacific-blue" onClick={shareResult} style={{ margin: "0 1rem 0 auto" }}>
                        <Icon name="Share" />
                      </button>
                    ) */}

                    <button type="button" className="btn btn-pacific-blue" onClick={windowPrint}>
                      <Icon name="Print" />
                    </button>
                  </div>
                </div>

                {
                  // showResults && (
                  <div className="evaluation-questions">
                    <div className="strapi-markdown">

                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component.summary}
                      </ReactMarkdown>

                      <br className="Kategorie gut" />
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component[`${component.kategorienGut[0].uid}-${component.kategorienGut[0].grading}`]}
                      </ReactMarkdown>

                      <br className="Kategorie schlecht" />
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component[`${component.kategorienSchlecht[0].uid}-${component.kategorienSchlecht[0].grading}`]}
                      </ReactMarkdown>

                      <br className="Kategorie schlecht" />
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component[`${component.kategorienSchlecht[1].uid}-${component.kategorienSchlecht[1].grading}`]}
                      </ReactMarkdown>

                      <br className="Dimension schlecht" />
                      <ReactMarkdown className="Dimension schlecht" linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component[`${component.dimensionsScores[0].uid}`]}
                      </ReactMarkdown>
                      <br />

                      <br className="Handlungsaufruf" />
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {component.handlungsaufruf}
                      </ReactMarkdown>
                    </div>
                  </div>
                  // )
                }

                {/* }
                <div>
                  <ButtonGroup>
                    {
                      showResults ? (
                        <button type="button" className={`btn btn-${theme}`} onClick={() => setShowResults(false)}><i className="icon icon-x1 icon-chevron-up" /> {t('self-check.results-hide')}</button>
                      ) : (
                        <button type="button" className={`btn btn-${theme}`} onClick={() => setShowResults(true)}><i className="icon icon-x1 icon-chevron-down" /> {t('self-check.results-show')}</button>
                      )
                    }
                  </ButtonGroup>
                </div>
                */}

              </div>
            ) : (
              // questions
              <div>
                {
                  component.introduction && (
                    <div className="introduction strapi-markdown">
                      <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
                        {`${component.introduction} ${plural[component.segments[activeSegment].title] || component.segments[activeSegment].title}`}
                      </ReactMarkdown>
                    </div>
                  )
                }
                {
                  component.segments.map((segment, i) => {
                    const questions = [];

                    for (let j = 1; j < 6; j += 1) { // has to start from 1 or the question key won't match
                      if (segment[`question${j}`]) {
                        questions.push({
                          uid: `reifegrad-dimension-${i}-question-${j}`,
                          question: segment[`question${j}`],
                          value: null,
                        });
                      }
                    }

                    component.segments[i].questions = questions;

                    return (
                      <SelfCheckSegment
                        key={`reifegrad-dimension-${i}`}
                        answers={component.answers?.slice(i * 3, i * 3 + 3)}
                        activeSegment={activeSegment}
                        segment={segment}
                        active={activeSegment === i}
                        next={(component.segments.length - 1 !== i) ? segmentNext : null}
                        prev={(i !== 0) ? segmentPrev : null}
                        evaluation={(component.segments.length - 1 === i) ? evaluateDimensions : null}
                        theme={theme}
                        useScaleAnswers
                      />
                    );
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionReifegrad;
