/* eslint-disable max-len */
import * as React from 'react';

function SvgIconIdea({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M40.045 86.743a5.362 5.362 0 00-.083-.081C18.729 66.407 33.73 30.015 63.017 30.136c29.389-.062 44.192 36.204 23.085 56.655l-.035.034c-5.999 5.959-9.445 14.222-9.462 22.679l-.083 6.263H49.544v-6.203c0-8.507-3.462-16.825-9.498-22.82zM56.497 141.849c-3.79 0-6.953-3.063-6.953-6.953v-6.954H76.49v6.953c0 3.79-3.064 6.954-6.953 6.954H56.497z" />
      <path d="M191.95 142.031a6.087 6.087 0 00-4.55 5.044l-4.121 28.851c-.942 6.594-6.676 11.566-13.339 11.566h-22.157a6.09 6.09 0 00-5.906 4.611l-3.005 12.021L71.707 210l10.257-41.025a6.087 6.087 0 00-1.602-5.781l-9.233-9.234c9.788-.8 17.537-8.995 17.537-19.064l.031-6.855.003-.713.08-17.764a20.007 20.007 0 015.854-14.088C111.4 79.784 112.719 51.38 97.737 34.2c45.889-16.821 96.791 19.126 96.122 68.094v13.041c0 1.317.428 2.599 1.218 3.653l14.06 18.747-17.187 4.297z" />
    </svg>
  );
}

export default SvgIconIdea;
