/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDecreaseInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm129.929 82.17l-.027-.001h-20.819a2.61 2.61 0 010-5.217h14.57l-42.5-42.5a2.611 2.611 0 010-3.689 2.611 2.611 0 013.689 0l42.501 42.502V88.691a2.61 2.61 0 015.218 0v20.819a2.598 2.598 0 01-.763 1.898 2.6 2.6 0 01-1.841.762h-.028zM63 179.889l31.304-2.739V83.536a2.667 2.667 0 00-2.666-2.667H65.667A2.667 2.667 0 0063 83.536v96.353zm0 0V180h.01l-.01-.111zm41.737-3.651l31.304-2.739v-58.657a2.667 2.667 0 00-2.666-2.667h-25.972a2.667 2.667 0 00-2.666 2.667v61.396zm73.05-6.391l-31.304 2.738V146.14a2.667 2.667 0 012.666-2.666h25.972a2.667 2.667 0 012.666 2.666v23.707z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDecreaseInverse;
