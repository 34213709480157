/* eslint-disable max-len */
import * as React from 'react';

function SvgIconInternetInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm20 117.147a6.667 6.667 0 006.667 6.667h49.583v13.436H90.937v7.5h58.125v-7.5H123.75v-13.436h49.583a6.667 6.667 0 006.667-6.667v-74.48A6.667 6.667 0 00173.333 66H66.667A6.667 6.667 0 0060 72.667v74.48zM67.5 73.5h105v72.814h-105V73.5zm85.509 40.654l-3.739-14.903h-5.122l-3.904 14.659-3.217-14.659h-7.388l-3.277 14.903-3.739-14.903H117.5l-3.9 14.659-3.217-14.659h-7.391l-3.274 14.903-3.739-14.903h-5.122l-3.904 14.659-3.218-14.659h-4.41l5.097 21.338h4.672l4.234-15.953 4.252 15.953h4.568l4.496-18.518 4.421 18.518h4.673l4.237-15.953 4.249 15.953h4.571l4.497-18.518 4.421 18.518h4.672l4.234-15.953 4.249 15.953h4.571l5.183-21.338h-4.335l-3.278 14.903z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconInternetInverse;
