import React from 'react';

import Image from '../Image';

import './CardPersonnelGallery.scss';

/**
 * @return {html}
 */
const CardPersonnelGallery = ({ person }) => {
  const actualImage = person.galleryImage ?? person.image;

  return (
    <div className="sw-card-personnel-gallery">
      <Image
        alternativeText={actualImage?.alternativeText}
        aspectRatio="3:4"
        caption={actualImage?.caption}
        image={actualImage?.formats?.small || actualImage?.formats?.thumbnail || actualImage}
      />
      <div className="label">
        <div className="name">{person.name}</div>
        {
          person.position && (
            <div className="position">{person.position}</div>
          )
        }
        <div className="organisation">{person.organisation}</div>
      </div>
    </div>
  );
};

export default CardPersonnelGallery;
