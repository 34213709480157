/* eslint-disable max-len */
import * as React from 'react';

function SvgIconUnfulfilled({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M30.182 209.945V51.212C30.182 30 51.382 30 51.382 30h137.599s21.201 0 21.201 21.212v142.63l-180 16.103zm20-159.946v136.698l140-12.523V49.999h-16.268c-.141-.004-.222-.004-.222-.004H66.672s-.082 0-.222.004H50.181zm110 80.001h-80v-20h80v20z" />
    </svg>
  );
}

export default SvgIconUnfulfilled;
