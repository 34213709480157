import * as React from 'react';

interface IProps extends React.HTMLAttributes<HTMLButtonElement> {
  text: string;
  variant: 'default' | 'alabaster' | 'pacific-blue' | 'pacific-blue-dark' | 'red' | 'orange';
}

/**
 * @return {html}
 */
const ButtonStandard = ({ text, variant = 'default', ...props }: IProps) => {
  const classes = `btn btn-${variant}`.trim();

  return (<button className={classes} {...props} type="button">{text}</button>);
};

export default ButtonStandard;
