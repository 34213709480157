import React from 'react';

import CardQuote from '../CardQuote';
import Carousel from '../Carousel';
import SectionWrapper from '../SectionWrapper';
import { camel2Kebab } from '../../utils';

import './SectionQuoteCarousel.scss';

/**
 * @return {html}
 */
const SectionQuoteCarousel = ({ component }) => {
  const theme = camel2Kebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-quote-carousel sw-section-quote-carousel-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-quote-carousel-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div>
          <Carousel theme={theme}>
            {
              component.slides.map(slide => (<CardQuote key={`slide-${slide.id}`} card={slide} />))
            }
          </Carousel>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionQuoteCarousel;
