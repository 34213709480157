import React from 'react';

import { camel2Kebab } from '../../utils';
import CardEvent from '../CardEvent';
import CardEventCompact from '../CardEventCompact';
import SectionWrapper from '../SectionWrapper';

import './SectionEventList.scss';

interface IProps {
  component: any;
}

/**
 * @return {html}
 */
const SectionEventList = ({ component }: IProps) => {
  const theme = camel2Kebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-event-list sw-section-event-list-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-event-list-${component.id}`}
    >
      <div className="grid-1fr-1fr2fr grid-gap">
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        {
          component.layout === 'Default' && (
            <div className="grid-1x1fr-2x1fr">
              {
                component.events.map(event => (<CardEvent key={event.id} item={event} theme={theme} />))
              }
            </div>
          )
        }

        {
          component.layout === 'Series' && (
            <div className="grid-1x1fr-2x1fr-3x1fr">
              {
                [...component.events].reverse().map(event => (<CardEventCompact key={event.id} item={event} theme={theme} />))
              }
            </div>
          )
        }
      </div>
    </SectionWrapper>
  );
};

export default SectionEventList;
