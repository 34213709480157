/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPublicInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm46.046 148.05l.003-70.614H60v72.531l26.046-1.917zm46.479-3.423v-67.191h-26.112v69.111l26.112-1.92zm47.412-3.42v-63.771h-26.736v65.736l26.736-1.965zM180 93.12L119.754 60 60 93.108l120 .012z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconPublicInverse;
