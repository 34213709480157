import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getSubDomainKey } from '../../utils';
import ApiService from '../../services/ApiService';

const subDomainKey = getSubDomainKey();
const getTimestamp = () => new Date().getTime().toString();

/**
 * @return {html}
 */
const PageViewTracker = () => {
  const history = useHistory();

  function trackPageView() {
    ApiService.post(`/page-view-tracker/${subDomainKey}?${getTimestamp()}`);
  }

  useEffect(() => {
    trackPageView();
    history.listen(trackPageView); // Track all subsequent pageviews
  }, [history]);

  return (<></>);
};

export default PageViewTracker;
