import React from 'react';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import ButtonIcon from '../ButtonIcon/ButtonIcon';

import './CardIcon.scss';

/**
 * @return {html}
 */
const CardIcon = ({ card }) => (
  <div className="sw-card-icon">
    <div>
      <ButtonIcon name={card.icon} width="2rem" height="2rem" />
    </div>
    <div className={!card.content ? 'align-items-center' : ''}>
      {
        (card.title && !card.omitTitle) && (<h3 className="h6">{card.title}</h3>)
      }
      {
        card.content && (
          <div className="content strapi-markdown">
            <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
              {card.content}
            </ReactMarkdown>
          </div>
        )
      }
    </div>
  </div>
);

export default CardIcon;
