import React from 'react';

import Icon from '../Icon';
import SectionWrapper from '../SectionWrapper';
import iconList from './iconList';

import './IconPreview.scss';

/**
 * @return {html}
 */
const IconPreview = () => (
  <SectionWrapper classes="sw-icon-preview">
    <div className="icon-list">
      {
        iconList.map(iconName => (
          <div key={iconName}>
            <Icon name={iconName} width="4rem" height="4rem" />
            <div>{iconName}</div>
          </div>
        ))
      }

      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>

      <div><Icon name="Close" /></div>
      <div><Icon name="CloseInverse" width="4rem" height="4rem" /></div>
      <div><Icon name="Download" /></div>
      <div>-</div>
      <div>-</div>
      <div>-</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>
      <div>*************</div>

      <div><i className="icon icon-x1 icon-bars" /></div>
      <div><i className="icon icon-x1 icon-chevron-up" /></div>
      <div><i className="icon icon-x1 icon-chevron-down" /></div>
      <div><i className="icon icon-x1 icon-facebook" /></div>
      <div><i className="icon icon-x1 icon-facebook2" /></div>
      <div><i className="icon icon-x1 icon-whatsapp" /></div>
      <div><i className="icon icon-x1 icon-twitter" /></div>
      <div><i className="icon icon-x1 icon-youtube" /></div>
      <div><i className="icon icon-x1 icon-linkedin" /></div>
      <div><i className="icon icon-x1 icon-linkedin2" /></div>
      <div><i className="icon icon-x1 icon-xing" /></div>
      <div><i className="icon icon-x1 icon-xing2" /></div>

      <div><i className="icon icon-x2 icon-bars" /></div>
      <div><i className="icon icon-x2 icon-chevron-up" /></div>
      <div><i className="icon icon-x2 icon-chevron-down" /></div>
      <div><i className="icon icon-x2 icon-facebook" /></div>
      <div><i className="icon icon-x2 icon-facebook2" /></div>
      <div><i className="icon icon-x2 icon-whatsapp" /></div>
      <div><i className="icon icon-x2 icon-twitter" /></div>
      <div><i className="icon icon-x2 icon-youtube" /></div>
      <div><i className="icon icon-x2 icon-linkedin" /></div>
      <div><i className="icon icon-x2 icon-linkedin2" /></div>
      <div><i className="icon icon-x2 icon-xing" /></div>
      <div><i className="icon icon-x2 icon-xing2" /></div>

      <div><i className="icon icon-x3 icon-bars" /></div>
      <div><i className="icon icon-x3 icon-chevron-up" /></div>
      <div><i className="icon icon-x3 icon-chevron-down" /></div>
      <div><i className="icon icon-x3 icon-facebook" /></div>
      <div><i className="icon icon-x3 icon-facebook2" /></div>
      <div><i className="icon icon-x3 icon-whatsapp" /></div>
      <div><i className="icon icon-x3 icon-twitter" /></div>
      <div><i className="icon icon-x3 icon-youtube" /></div>
      <div><i className="icon icon-x3 icon-linkedin" /></div>
      <div><i className="icon icon-x3 icon-linkedin2" /></div>
      <div><i className="icon icon-x3 icon-xing" /></div>
      <div><i className="icon icon-x3 icon-xing2" /></div>

      <div><i className="icon icon-x4 icon-bars" /></div>
      <div><i className="icon icon-x4 icon-chevron-up" /></div>
      <div><i className="icon icon-x4 icon-chevron-down" /></div>
      <div><i className="icon icon-x4 icon-facebook" /></div>
      <div><i className="icon icon-x4 icon-facebook2" /></div>
      <div><i className="icon icon-x4 icon-whatsapp" /></div>
      <div><i className="icon icon-x4 icon-twitter" /></div>
      <div><i className="icon icon-x4 icon-youtube" /></div>
      <div><i className="icon icon-x4 icon-linkedin" /></div>
      <div><i className="icon icon-x4 icon-linkedin2" /></div>
      <div><i className="icon icon-x4 icon-xing" /></div>
      <div><i className="icon icon-x4 icon-xing2" /></div>
      <div><i className="icon icon-x4 icon-instagram" /></div>
      <div><i className="icon icon-x4 icon-instagram2" /></div>

      {
        /*
        <div><Icon name="ChevronDown"  width="4rem" height="4rem" /></div>
        <div><Icon name="ChevronDownInverse"  width="4rem" height="4rem" /></div>
        <div><Icon name="Facebook"  width="4rem" height="4rem" /></div>
        <div><Icon name="LinkedIn"  width="4rem" height="4rem" /></div>
        <div><Icon name="LinkedInInverse"  width="4rem" height="4rem" /></div>
        <div><Icon name="Menu"  width="4rem" height="4rem" /></div>
        <div><Icon name="MenuInverse"  width="4rem" height="4rem" /></div>
        <div><Icon name="Twitter"  width="4rem" height="4rem" /></div>
        <div><Icon name="WhatsApp"  width="4rem" height="4rem" /></div>
        <div><Icon name="Xing"  width="4rem" height="4rem" /></div>
        <div><Icon name="XingInverse"  width="4rem" height="4rem" /></div>
        <div><Icon name="YouTube"  width="4rem" height="4rem" /></div>
        <div><Icon name="YouTubeInverse"  width="4rem" height="4rem" /></div>
        */
      }

    </div>
  </SectionWrapper>
);

export default IconPreview;
