import React from 'react';
import { Link } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

import Icon from '../Icon';
import { formatUrl, isExternalLink } from '../../utils';

/**
 * @return {html}
 */
const ButtonLink = ({ text, to, variant = 'pacific-blue' }) => {
  const classes = `btn btn-${variant}`.trim();
  const isExternal = isExternalLink(to);
  const formattedTo = formatUrl(to);

  if (isExternal) {
    return (
      <Link to={{ pathname: formattedTo }} className={classes} target="_blank">
        <div>{text}</div>
        <div className="link-icon">
          <Icon name="ExternalLink" />
        </div>
      </Link>
    );
  }

  const isHashLink = to.includes('#');
  return isHashLink ? <NavHashLink smooth to={to} className={classes}>{text}</NavHashLink> : <Link to={formattedTo} className={classes}>{text}</Link>;
};

export default ButtonLink;
