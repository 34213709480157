import React from 'react';

import { useTranslation } from 'react-i18next';
import SelfCheckQuestion from '../SelfCheckQuestion';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

import './SelfCheckSegment.scss';

/**
 * @return {html}
 */

const SelfCheckSegment = ({
  answers = null, activeSegment = null, segment, active, next = null, prev = null, evaluation = null, theme, useScaleAnswers = false,
}) => {
  const { t } = useTranslation();

  if (answers) { console.log(answers, activeSegment * 3); }

  return (
    <div className={`SelfCheckSegment sw-self-check-segment sw-self-check-segment-${theme} ${useScaleAnswers ? 'sw-self-check-segment-use-scale-answers' : ''} ${active ? 'active' : 'collapsed'}`.trim()} data-id={segment.id}>
      <div className="questions">
        {
          segment.questions.map((question, i) => (<SelfCheckQuestion answer={answers ? answers[i] : null} key={`question-${i}`} question={question} theme={theme} useScaleAnswers={useScaleAnswers} id={segment.id} />))
        }
      </div>
      <ButtonGroup>
        {
          prev ? (<button type="button" className={`btn btn-${theme}`} onClick={() => prev()}><i className="icon icon-x1 icon-arrow-left" /> {t('common.step-prev')}</button>) : (<div>&nbsp;</div>)
        }
        {
          next && (<button type="button" className={`btn btn-${theme}`} onClick={() => next(segment.questions)}>{t('common.step-next')} <i className="icon icon-x1 icon-arrow-right" /></button>)
        }
        {
          evaluation && (<button type="button" className={`btn btn-${theme}`} onClick={() => evaluation(segment.questions, useScaleAnswers)}>{t('self-check.evaluation')} <i className="icon icon-x1 icon-arrow-right" /></button>)
        }
      </ButtonGroup>
    </div>
  );
};

export default SelfCheckSegment;
