/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCloseInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm34.034 31.966a8.533 8.533 0 10-12.068 12.068L107.932 120l-45.966 45.966a8.533 8.533 0 1012.068 12.068L120 132.068l45.966 45.966a8.534 8.534 0 0012.068-12.068L132.068 120l45.966-45.966a8.534 8.534 0 00-12.068-12.068L120 107.932 74.034 61.966z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconCloseInverse;
