/* eslint-disable max-len */
import * as React from 'react';

function SvgIconXing({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M162.698 30L100.23 139.432 140.483 210h42.052l-40.252-70.568L204.75 30h-42.052zM82.838 63.75H43.193l22.934 40.395L36 153.75h39.645l30.128-49.605L82.838 63.75z" />
    </svg>
  );
}

export default SvgIconXing;
