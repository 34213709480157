/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBudget({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M220.182 163.976V66.137S220.182 47 201.051 47H66.585s-18.495.014-19.102 18.225c.579-.043.919-.045.919-.045h134.466c19.131 0 19.131 19.632 19.131 19.632v79.958c0 .296-.017.577-.028.862l18.211-1.656zm-45.453-89.703l-136.357.092s-18.18 0-18.19 18.26V193l172.727-15.461V92.625s.001-18.26-18.18-18.352zm-83.934 66.023c1.746 3.772 4.216 6.835 7.408 9.191 3.191 2.358 6.904 3.535 11.137 3.535 1.613 0 3.292-.202 5.04-.606a19.15 19.15 0 004.989-1.919c1.578-.874 3.039-2.003 4.384-3.383 1.343-1.38 2.419-3.046 3.225-5l11.39 6.565a21.577 21.577 0 01-4.889 7.121 31.434 31.434 0 01-7.156 5.201 36.486 36.486 0 01-8.517 3.232c-3.023.74-5.98 1.111-8.869 1.111-3.965 0-7.677-.673-11.137-2.021-3.461-1.345-6.602-3.146-9.424-5.403a35.639 35.639 0 01-7.307-7.928 39.42 39.42 0 01-4.788-9.696H65.8l3.125-7.474h5.644a31.494 31.494 0 01-.302-4.444v-.707H67.21l3.024-7.473h4.938c.806-3.636 2.167-7.121 4.082-10.454a33.78 33.78 0 017.257-8.787c2.923-2.525 6.315-4.544 10.18-6.06 3.863-1.514 8.146-2.272 12.85-2.272 6.584 0 12.279 1.449 17.083 4.343 4.804 2.896 8.382 6.666 10.735 11.312l-10.684 7.373c-.942-2.02-2.084-3.72-3.427-5.1-1.345-1.38-2.789-2.475-4.334-3.283a19.46 19.46 0 00-4.838-1.768 22.982 22.982 0 00-4.938-.555c-2.621 0-4.989.404-7.106 1.212-2.117.808-3.981 1.903-5.594 3.282a20.505 20.505 0 00-4.132 4.848 23.836 23.836 0 00-2.62 5.909h25.801l-3.023 7.473h-24.09v1.111c0 1.414.102 2.762.303 4.041h23.585l-3.024 7.473H90.795" />
    </svg>
  );
}

export default SvgIconBudget;
