/* eslint-disable max-len */
import * as React from 'react';

function SvgIconFulfilled({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M194.064 42.47c-2.829-3.506-7.594-6.47-15.668-6.47H49.969s-19.787 0-19.787 19.799V203.95l168.002-15.028v-87.526l-18.667 15.664v53.504L48.848 182.252V54.666h15.184l.207-.005h99.887c.064 0 .122.005.184.005h15.207v.015l14.547-12.211zm-79.976 111.087l96.094-80.632-11.999-14.3-81.742 68.616-27.415-32.986-14.309 12.381 39.371 46.921" />
    </svg>
  );
}

export default SvgIconFulfilled;
