/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSap({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M150.906 156.278c3.522 0 6.653 2.7 6.653 6.376 0 3.73-3.131 6.473-6.653 6.473-3.523 0-6.654-2.743-6.654-6.473 0-3.676 3.131-6.376 6.654-6.376zM220.182 70l-99.022 98.978H20.182V70h200zm-69.276 87.323c-2.74 0-5.088 2.305-5.088 5.331 0 3.139 2.348 5.401 5.088 5.401 2.739 0 5.088-2.262 5.088-5.401 0-3.026-2.349-5.331-5.088-5.331zm.391 1.655c1.566 0 2.74.65 2.74 2.126 0 1.315-1.174 1.89-1.957 2.023l1.957 3.257h-1.174l-1.957-3.194h-1.174v3.194h-1.175v-7.406h2.74zm0 .944h-1.565v2.313h1.219c.782-.003 1.912-.096 1.912-1.198 0-.935-1.175-1.115-1.566-1.115zM47.579 87.554c-8.61 0-15.656 2.708-19.961 7.197-2.74 3.124-4.697 7.089-4.697 11.484 0 6.047 2.349 10.336 6.654 13.753 4.305 2.877 9.002 4.752 13.699 6.125 5.48 1.695 9.784 3.163 9.784 6.31 0 1.162-.391 2.219-1.174 3.068-1.565 1.405-3.522 1.938-6.653 2.004-5.48.102-9.394-.771-16.047-4.657l-6.263 12.074c6.654 3.742 14.482 5.941 22.31 5.941l1.174-.008c6.653-.125 12.524-2.101 16.83-5.694.39-.212.39-.407.782-.603l-1.957 5.256h18.004l2.74-9.111c3.131 1.049 6.654 1.659 10.567 1.659 3.914 0 7.045-.591 10.177-1.585l3.131 9.037h28.963v-18.9h6.653c15.264 0 24.266-7.832 24.266-20.967 0-14.622-8.61-21.334-27.788-21.334l-19.57-.004v46.818l-17.221-46.818h-16.83L70.28 127.753c-1.565-9.863-11.741-13.272-19.96-15.823-5.088-1.715-10.96-4.235-10.568-6.991 0-2.285 2.74-4.383 8.61-4.07l.118.006.234.012.233.013.233.014.349.021.108.008.216.015.108.008.216.016c3.282.261 6.719.934 12.667 3.769l7.045-11.925c-6.57-3.184-15.046-5.212-22.04-5.27l-.27-.002zM93.37 107.87l6.263 19.757c-1.957.673-3.914 1.057-6.263 1.057-2.348 0-4.305-.407-6.262-1.119l6.263-19.695zm46.576-6.392c5.871 0 10.567 1.922 10.567 7.86 0 6.105-4.696 8.019-10.567 8.019h-4.305v-15.879h4.305z" />
    </svg>
  );
}

export default SvgIconSap;
