/* eslint-disable max-len */
import * as React from 'react';

function SvgIconStartup({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M139.979 77.7c28.8-5.4 54.9 8.1 64.8 34.2-11.7-4.5-25.2-5.4-38.7-1.8 2.7 3.6 5.4 8.1 7.2 12.6.9 1.8.9 3.6 0 4.5l-45 45c-1.8 1.8-3.6 1.8-5.4.9-4.5-1.8-9-4.5-13.5-7.2-3.6 13.5-2.7 27 1.8 38.7-26.1-9.9-38.7-36.9-34.2-64.8-27-27.9-45.9-66.6-46.8-105.3 0-2.7 1.8-4.5 4.5-4.5 38.7.9 77.4 19.8 105.3 47.7zm-59.4 27.9c7.2 7.2 18 7.2 25.2 0 7.2-7.2 7.2-18 0-25.2-7.2-7.2-18-7.2-25.2 0-7.2 7.2-7.2 18 0 25.2z"
        clipRule="evenodd"
      />
      <path d="M188.58 140.704l18.9 18.9c3.6 3.6 3.6 9 0 12.6-3.6 3.6-9 3.6-12.6 0l-18.9-18.9c-3.6-3.6-3.6-9 0-12.6 3.6-3.6 9-3.6 12.6 0zM172.383 194.702l-18.9-18.9c-3.6-3.6-9-3.6-12.6 0-3.6 3.6-3.6 9 0 12.6l18.9 18.9c3.6 3.6 9 3.6 12.6 0 3.6-3.6 3.6-9 0-12.6zM170.576 158.692l26.1 26.1c3.6 3.6 3.6 9 0 12.6-3.6 3.6-9 3.6-12.6 0l-26.1-26.1c-3.6-3.6-3.6-9 0-12.6 3.6-3.6 9-3.6 12.6 0z" />
    </svg>
  );
}

export default SvgIconStartup;
