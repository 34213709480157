/* eslint-disable max-len */
import * as React from 'react';

function SvgIconStructureInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm137.885 124.182v12.16c0 5.542-5.543 5.542-5.543 5.542H160.25c-5.542 0-5.542-5.542-5.542-5.542v-12.16c0-5.543 5.542-5.543 5.542-5.543h3.149v-29.812s0-.887-.887-.887H122.84v34.841h3.148c5.543 0 5.543 5.543 5.543 5.543v12.159c0 5.542-5.543 5.542-5.543 5.542h-12.092c-5.542 0-5.542-5.542-5.542-5.542v-12.159c0-5.543 5.542-5.543 5.542-5.543h3.149V117.94H77.372s-.886 0-.886.887v37.928h3.149c5.542 0 5.542 5.542 5.542 5.542v12.161c0 5.542-5.542 5.542-5.542 5.542H67.542C62 180 62 174.458 62 174.458v-12.161c0-5.542 5.542-5.542 5.542-5.542h3.15v-39.067c0-5.543 5.484-5.543 5.484-5.543h40.869v-28.9h-3.149c-5.542 0-5.542-5.542-5.542-5.542v-12.16c0-5.543 5.542-5.543 5.542-5.543h12.092c5.543 0 5.543 5.542 5.543 5.542v12.16c0 5.543-5.543 5.543-5.543 5.543h-3.148v28.9h40.869c5.485 0 5.485 5.543 5.485 5.543v30.951h3.148c5.543 0 5.543 5.543 5.543 5.543z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconStructureInverse;
