/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMentoringInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm140 91.2v34.595l-54.545 4.936v-39.269s-.021-6.303-5.293-8.366c-2.553.912-5.616 3.096-5.616 8.366v40.248L60 166.661v-45.46c0-9.085 8.96-9.085 8.96-9.085h47.534c1.501 0 2.746.259 3.786.683 1.847-.681 3.446-.683 3.446-.683h47.289c8.985 0 8.985 9.084 8.985 9.084zm-87.323 24.025h-4.25v5.273h4.25v-5.273zm7.983-12.514c.192-.624.288-1.343.288-2.159 0-1.324-.237-2.464-.709-3.42-.473-.955-1.085-1.738-1.838-2.35a7.314 7.314 0 00-2.546-1.337c-.945-.28-1.902-.42-2.872-.42-.868 0-1.71.108-2.527.325a8.75 8.75 0 00-2.279.955c-.7.418-1.332.94-1.876 1.547a7.422 7.422 0 00-1.32 2.102l3.33 2.292c.205-.33.453-.681.747-1.05.293-.369.627-.702.996-.994a5.25 5.25 0 011.244-.726c.46-.191.97-.286 1.532-.286.46 0 .92.071 1.36.21.445.14.835.35 1.167.63.332.281.6.625.804 1.032.204.408.306.879.306 1.414 0 .713-.185 1.344-.555 1.891a6.038 6.038 0 01-1.359 1.433 11.53 11.53 0 01-1.647 1.051c-.561.293-1.033.554-1.416.783-1.277.739-2.12 1.605-2.527 2.598-.41.994-.613 2.115-.613 3.363h4.212c0-.637.134-1.178.402-1.624a4.552 4.552 0 011.034-1.184 9.246 9.246 0 011.397-.937 47.54 47.54 0 001.532-.878 14.502 14.502 0 001.646-1.185c.48-.401.905-.864 1.264-1.376a5.983 5.983 0 00.823-1.7zm44.44 7.523h4.345v-17.105H145.1v17.105zm0 10.149h4.345v-5.74H145.1v5.74zM147.273 74c9.037 0 16.363 7.312 16.363 16.33 0 9.02-7.326 16.331-16.363 16.331-9.038 0-16.364-7.311-16.364-16.33 0-9.02 7.326-16.331 16.364-16.331zm-54.546.126c9.038 0 16.364 7.284 16.364 16.268 0 8.984-7.326 16.267-16.364 16.267-9.037 0-16.363-7.283-16.363-16.267 0-8.984 7.326-16.268 16.363-16.268z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconMentoringInverse;
