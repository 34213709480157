/* eslint-disable max-len */
import * as React from 'react';

function SvgIconContractInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm31 41.627V180l98.182-8.804V70.444S169.182 60 157.618 60H82.564S71 60 71 71.627zm32.706 75.665v-5.366h32.765v5.366h-32.765zm-16.342-10.928c0-3.078 2.44-5.449 5.454-5.449s5.455 2.461 5.455 5.538a5.428 5.428 0 01-5.455 5.473c-3.014 0-5.454-2.485-5.454-5.562zm16.342.048v-5.366h49.162v5.366h-49.162zm0-16.488v-5.366h32.765v5.366h-32.765zm-16.342-10.839c0-3.077 2.44-5.538 5.454-5.538s5.455 2.461 5.455 5.538a5.428 5.428 0 01-5.455 5.473 5.428 5.428 0 01-5.454-5.473zm16.342-.041v-5.366h49.162v5.366h-49.162zm0-16.34V87.34h32.765v5.366h-32.765zM87.364 81.82c0-3.078 2.44-5.491 5.454-5.491s5.455 2.461 5.455 5.538a5.429 5.429 0 01-5.455 5.473c-3.014 0-5.454-2.443-5.454-5.52zm16.342.006v-5.367h49.162v5.367h-49.162z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconContractInverse;
