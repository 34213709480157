import React from 'react';

import { useTranslation } from 'react-i18next';
import FormControlRadioButton from '../FormControlRadioButton';

import './SelfCheckQuestion.scss';

/**
 * @return {html}
 */
const SelfCheckQuestion = ({
  question, answer, theme, useScaleAnswers, id,
}) => {
  const { t } = useTranslation();
  const wrapperId = `${question.uid}-wrapper`;

  const clearError = () => {
    document.getElementById(wrapperId).classList.remove('error');
  };

  return (
    <div role="button" id={wrapperId} className={`SelfCheckQuestion sw-self-check-question sw-self-check-question-${theme}`} onFocus={clearError}>
      <div className="question js-question">{question.question}</div>
      <div className="radio-button-group" id="radio-button-group">
        {
          useScaleAnswers &&
          (
          <>
            schwach ausgeprägt -
            {
              Array.from(Array(6), (e, i) => (
                <FormControlRadioButton checked={parseInt(answer, 10) === i} key={`form-control-radio-button-${i}`} id={`${question.uid}-${i}`} name={question.uid} value={i} label={i} data-id={id} />
              ))
            }
            - stark ausgeprägt
          </>
          )
        }
        {
          !useScaleAnswers &&
          (
          <>
            <FormControlRadioButton checked={false} id={`${question.uid}-yes`} name={question.uid} value="yes" label={t('self-check.yes')} />
            <FormControlRadioButton checked={false} id={`${question.uid}-partly`} name={question.uid} value="partly" label={t('self-check.partly')} />
            <FormControlRadioButton checked={false} id={`${question.uid}-no`} name={question.uid} value="no" label={t('self-check.no')} />
          </>
          )
        }
      </div>
    </div>
  );
};

export default SelfCheckQuestion;
