/* eslint-disable max-len, react/no-danger */
import * as React from 'react';

function SvgIconDownload({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="currentColor"
      viewBox="20 10 80 70"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-2{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}' }} />
      </defs>
      <polyline className="cls-2" points="25 47.1 25 71 75 71 75 47.1" />
      <polyline className="cls-2" points="50.52 21 49.98 58.89 62 46.96" />
      <line className="cls-2" x1="49.98" y1="58.89" x2="38" y2="47" />
    </svg>
  );
}

export default SvgIconDownload;
