/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSearch({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M170.898 157.604c10.591-13.421 16.913-30.366 16.913-48.789 0-43.528-35.287-78.815-78.815-78.815s-78.814 35.287-78.814 78.815 35.286 78.815 78.814 78.815c18.423 0 35.369-6.322 48.788-16.913l32.728 32.727a9.272 9.272 0 0013.113-13.113l-32.727-32.727zm-1.627-48.789c0 33.29-26.986 60.275-60.275 60.275-33.288 0-60.274-26.985-60.274-60.275 0-33.288 26.986-60.273 60.274-60.273 33.289 0 60.275 26.985 60.275 60.273z" />
    </svg>
  );
}

export default SvgIconSearch;
