import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { getSubDomainKey } from './utils';
import { GlobalEffects } from './global-effects';

import IconPreview from './components/IconPreview/IconPreview';
import JobPage from './components/JobPage/JobPage';
import MainMenu from './components/MainMenu/MainMenu';
import NewsItemPage from './components/NewsItemPage';
import Page from './components/Page/Page';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import './App.scss';

const subDomainKey = getSubDomainKey();
const useCareerDomainRedirect = subDomainKey !== 'karriere';

/**
 * @return {html}
 */
function App() {
  return (
    <HelmetProvider>
      <Router>
        <GlobalEffects />
        <ScrollToTop />
        <MainMenu />
        <div className="sw-app">
          <Switch>
            <Route exact path="/" component={Page} />
            {/* @todo: remove this route before deployment, it's only for development purposes */}
            <Route exact path="/icon-preview" component={IconPreview} />
            <Route exact path="/:slug">
              <Page />
            </Route>
            <Route exact path="/jobs/:slug">
              <JobPage useCareerDomainRedirect={useCareerDomainRedirect} />
            </Route>
            <Route exact path="/news/:slug">
              <NewsItemPage />
            </Route>
          </Switch>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
