/* eslint-disable max-len */
import * as React from 'react';

function SvgIconUnfulfilledInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm20 44.142v105.821l120-10.735V74.142C180 60 165.866 60 165.866 60H74.134S60 60 60 74.142zm13.334 90.323V73.333h10.845c.093-.003.147-.003.147-.003h71.348a3.308 3.308 0 00.148.003h10.845v82.783l-93.334 8.349zm20-37.798h53.333v-13.334H93.333v13.334z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconUnfulfilledInverse;
