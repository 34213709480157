import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Link } from 'react-router-dom';

import Image from '../Image';
import { formatUrl } from '../../utils';

import './CardEvent.scss';

function LinkOrAnchor(props) {
  const { destination, children } = props;
  if (destination.startsWith('http')) {
    return (
      <a href={destination}>
        {children}
      </a>
    );
  }
  return (
    <Link to={{ pathname: destination }}>
      {children}
    </Link>
  );
}

/**
 * @return {html}
 */
const CardEvent = ({ item, theme = 'default' }) => {
  const dateStart = item.dateStart ? new Date(item.dateStart).toLocaleDateString('de-DE') : null;
  const dateEnd = item.dateEnd ? new Date(item.dateEnd).toLocaleDateString('de-DE') : null;

  return (
    <div className={`sw-card-event sw-card-event-${theme}`}>

      <LinkOrAnchor destination={formatUrl(item.url)}>
        <Image
          alternativeText={item.image?.alternativeText}
          aspectRatio="21:9"
          caption={item.image?.caption}
          image={item.image?.formats.small || item.image?.formats.thumbnail}
        />
        <div className="details">
          <div className="font-weight-600">
            {
              dateStart ? (<span>{dateStart}</span>) : (<span>&nbsp;</span>)
            }

            {
              (dateStart && dateEnd) && (
                <span> - {dateEnd}</span>
              )
            }
          </div>
          <div className="h6">{item.title}</div>
        </div>
      </LinkOrAnchor>
      <div className="strapi-markdown">
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {item.teaser}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default CardEvent;
