/* eslint-disable max-len */
import * as React from 'react';

function SvgIconQuality({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M183.505 59.898l-6.409-4.657-2.3-7.078c-3.594-11.062-12.65-18.552-24.281-18.552h-7.443l-6.174-4.486a26.832 26.832 0 00-31.545 0l-6.175 4.486h-7.8c-11.631 0-23.194 7.49-26.788 18.552l-2.41 7.419-5.94 4.315A26.834 26.834 0 0046.492 89.9l2.269 6.982-2.445 7.524a26.848 26.848 0 009.753 30.018l6.4 4.65 2.304 7.088c2.024 6.23 6.222 11.258 11.543 14.52L56.331 220l22.972-15.208 14.002 12.025 14.645-46.507c8.864 4.464 19.302 4.846 27.523-1.127l.233-.169 13.061 43.028 14.002-16.445 22.973 13.086-20.311-49.282a26.782 26.782 0 009.54-13.24l2.414-7.428 5.931-4.31a26.85 26.85 0 009.753-30.018l-2.265-6.972 2.447-7.534a26.832 26.832 0 00-9.747-30.002zm-4.247 38.528c0 32.29-26.176 58.466-58.466 58.466-32.29 0-58.466-26.176-58.466-58.466 0-32.29 26.176-58.466 58.466-58.466 32.29 0 58.466 26.176 58.466 58.466zm-9.695-.424c0-26.85-21.767-48.617-48.618-48.617-26.85 0-48.617 21.767-48.617 48.617 0 26.851 21.767 48.617 48.617 48.617 26.851 0 48.618-21.766 48.618-48.617zm-16.99-8.976l-19.458 14.079 7.466 22.829-19.404-14.155-19.404 14.155 7.466-22.829-19.459-14.08 24.019.046 7.378-22.857 7.378 22.858 24.018-.046" />
    </svg>
  );
}

export default SvgIconQuality;
