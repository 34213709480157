/* eslint-disable max-len */
import * as React from 'react';

function SvgIconQuestionMark({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M86.51 185.613a4 4 0 014-4h23.463a4 4 0 014 4V220H86.511v-34.387zm92.687-110.815c0 5.938-.709 11.175-2.125 15.716-1.418 4.547-3.451 8.672-6.095 12.379a52.518 52.518 0 01-9.353 10.014c-3.592 2.969-7.653 5.842-12.188 8.623a353.434 353.434 0 01-11.339 6.398c-3.781 2.043-7.227 4.311-10.345 6.815-3.118 2.503-5.669 5.38-7.653 8.623-1.984 3.246-2.976 7.189-2.976 11.822h-31.18c0-9.084 1.51-17.246 4.535-24.478 3.021-7.233 9.257-13.535 18.708-18.916 2.834-1.668 6.329-3.568 10.487-5.702 4.155-2.13 8.22-4.681 12.189-7.65 3.968-2.964 7.321-6.44 10.063-10.43 2.736-3.986 4.109-8.576 4.109-13.77 0-3.895-.758-7.324-2.267-10.292-1.515-2.964-3.499-5.468-5.953-7.51-2.458-2.039-5.341-3.569-8.644-4.59a34.069 34.069 0 00-10.063-1.53c-4.159 0-7.937.695-11.338 2.087-3.401 1.39-6.475 3.154-9.212 5.284a44.886 44.886 0 00-7.37 7.233 90.481 90.481 0 00-3.216 4.214c-1.318 1.84-3.866 2.383-5.741 1.114l-18.498-12.52c-1.586-1.074-2.203-3.129-1.35-4.845 2.253-4.539 5.052-8.641 8.397-12.303A59.648 59.648 0 0184.668 29.32c5.195-3.06 10.816-5.377 16.865-6.954C107.578 20.79 113.814 20 120.24 20c7.18 0 14.266 1.022 21.259 3.06 6.989 2.043 13.274 5.285 18.85 9.735 5.571 4.452 10.106 10.154 13.605 17.108 3.494 6.954 5.243 15.256 5.243 24.895z" />
    </svg>
  );
}

export default SvgIconQuestionMark;
