import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';
import { camel2Kebab, formatUrl, isExternalLink } from '../../utils';

import './ButtonCTA.scss';

/**
 * @return {html}
 */
const ButtonCTA = ({
  text,
  to,
  theme = 'pacific-blue',
  icon = null,
  iconSize = '32',
}) => {
  const themeName = camel2Kebab(theme);
  const classes = `btn btn-${themeName}`.trim();
  const isExternal = isExternalLink(to);
  const formattedTo = formatUrl(to);

  if (isExternal) {
    return (
      <div className="sw-button-cta">
        <Link to={{ pathname: formattedTo }} className={classes} target="_blank">
          <div>{text}</div>
          {
            icon && (
              <div className="link-icon">
                <Icon name={icon} height={iconSize} width={iconSize} />
              </div>
            )
          }
        </Link>
      </div>

    );
  }

  return (<Link to={formattedTo} className={classes}>{text}</Link>);
};

export default ButtonCTA;
