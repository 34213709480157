/* eslint-disable max-len */
import * as React from 'react';

function SvgIconCalendar({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M210.182 185.249V70.704s0-16.364-16.364-16.364h-16.364V38h-24.545v16.34H87.455V38H62.909v16.34H46.545s-16.363 0-16.363 16.364v130.909l180-16.364zm-16.364-14.914L46.545 183.796V87.067h147.273v83.268zM87.455 103.432H62.909v16.374h24.546v-16.374zm0 32.707H62.909v16.374h24.546v-16.374zm44.999-32.708h-24.545v16.375h24.546l-.001-16.375zm0 32.708h-24.545v16.374h24.546l-.001-16.374zm45-32.708h-24.545v16.375h24.545v-16.375zm0 32.708h-24.545v16.374h24.545v-16.374z" />
    </svg>
  );
}

export default SvgIconCalendar;
