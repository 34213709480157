/* eslint-disable max-len */
import * as React from 'react';

function SvgIconOutlookInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm136.25 56.505H127.5v-18.75a3.741 3.741 0 00-1.357-2.887c-.855-.713-2.003-1.02-3.083-.795l-60 11.25A3.74 3.74 0 0060 79.005v82.5c0 1.8 1.282 3.353 3.06 3.683l60 11.25a3.737 3.737 0 003.083-.795 3.754 3.754 0 001.357-2.888v-18.75h48.75c2.07 0 3.75-1.68 3.75-3.75v-60c0-2.07-1.68-3.75-3.75-3.75zm-82.5 60c-10.335 0-18.75-10.095-18.75-22.5s8.415-22.5 18.75-22.5 18.75 10.095 18.75 22.5-8.415 22.5-18.75 22.5zm33.75-52.5h37.823l-23.04 17.918-14.783-9.398v-8.52zm45 52.5h-45v-35.092l12.982 8.257a3.78 3.78 0 002.018.585c.81 0 1.628-.262 2.303-.787L172.5 97.92v48.585zm-78.75-7.496c6.213 0 11.25-6.716 11.25-15 0-8.284-5.037-15-11.25-15s-11.25 6.716-11.25 15c0 8.284 5.037 15 11.25 15z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconOutlookInverse;
