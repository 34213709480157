/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMilestoneInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm101.818 100.915L180 169.085 60 180l38.182-38.191s5.454-4.8 10.442-.155l5.922 5.619 16.363-16.358c.918-.919 1.835-1.523 2.727-1.902V100.28l-30-18.461L139.091 60v69.13c1.66.719 2.727 1.785 2.727 1.785z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconMilestoneInverse;
