/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDialogInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm140 92.622a9.999 9.999 0 01-8.816 9.929l-3.966.474-19.962 30.552v-28.781l-10.856.964v.053c0 7.445-7.417 8.102-7.417 8.102l-10.245.908-15.069 22.472v-21.138l-36.253 3.211C60 150.025 60 142.58 60 142.58V96.974c0-7.445 7.445-7.445 7.445-7.445h36.212v-9.083A7.446 7.446 0 01111.103 73h61.452A7.445 7.445 0 01180 80.445v42.177zm-68.323-5.994a5.428 5.428 0 1010.855-.001 5.428 5.428 0 00-10.855.001zm-32.365 5.427a5.428 5.428 0 10.001-10.855 5.428 5.428 0 000 10.855zm18.888 0A5.428 5.428 0 1098.2 111.2a5.428 5.428 0 00-.001 10.855zm76.4 4.345V78.524h-65.486v11.005h19.84c7.446 0 7.446 7.445 7.446 7.445v32.962l16.368-1.462v15.518l10.805-16.482 11.027-1.11z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDialogInverse;
