/* eslint-disable max-len */
import * as React from 'react';

function SvgIconAccompany({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M30.182 206.615v-80.802c0-17.349 17.256-17.349 17.256-17.349h63.983c17.256 0 17.256 17.349 17.256 17.349v72.119l-98.495 8.683zM169.03 83.643c-13.636 0-24.69-11.113-24.69-24.822S155.395 34 169.031 34c13.636 0 24.69 11.113 24.69 24.822 0 13.708-11.054 24.821-24.69 24.821zm-89.734 16.815c-17.742 0-32.124-14.459-32.124-32.295 0-17.836 14.382-32.295 32.124-32.295 17.74 0 32.124 14.46 32.124 32.295 0 17.836-14.383 32.294-32.124 32.294v.001zm57.345 96.772v-71.102s-.013-11.917-8.369-18.963c1.5-14.97 17.13-14.981 17.13-14.981h47.522c17.257 0 17.257 17.348 17.257 17.348v81.216l-73.54 6.482" />
    </svg>
  );
}

export default SvgIconAccompany;
