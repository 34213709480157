/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSpring({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M200.122 186.097c.734 8.388-5.402 15.793-13.746 16.66l-.092.009v.001L57.412 214.042c-8.406.647-15.786-5.595-16.523-14.014-.733-8.388 5.402-15.793 13.746-16.66l.093-.009v-.001l128.871-11.275c8.407-.647 15.786 5.595 16.523 14.014zm-.324-36.509c.734 8.388-5.402 15.793-13.746 16.66l-.093.008.001.002-128.872 11.274c-8.407.648-15.786-5.594-16.523-14.013-.734-8.389 5.402-15.794 13.746-16.66l.092-.009v-.001l128.872-11.275c8.407-.647 15.786 5.595 16.523 14.014zm-.325-36.51c.734 8.389-5.401 15.794-13.746 16.66l-.092.009v.001L56.764 141.023c-8.407.648-15.787-5.594-16.523-14.014-.734-8.388 5.402-15.793 13.746-16.659l.092-.009v-.002l128.872-11.274c8.406-.648 15.786 5.594 16.522 14.013zm.649-36.509c.734 8.388-5.402 15.793-13.746 16.66l-.092.009v.001L57.412 104.514c-8.406.647-15.786-5.595-16.523-14.014-.733-8.388 5.402-15.793 13.746-16.66l.093-.008v-.002l128.871-11.275c8.407-.647 15.786 5.595 16.523 14.014zm-.324-36.51c.734 8.39-5.402 15.794-13.746 16.66l-.093.01h.001L57.088 68.006c-8.407.647-15.786-5.595-16.523-14.014-.734-8.388 5.402-15.793 13.746-16.66l.092-.009v-.001l128.872-11.275c8.407-.647 15.786 5.595 16.523 14.014z" />
    </svg>
  );
}

export default SvgIconSpring;
