/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExternalLink({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M136.546 30h65.453a8.182 8.182 0 018.182 8.182v65.453a8.182 8.182 0 01-16.363 0V57.934l-84.214 84.212a8.15 8.15 0 01-5.784 2.397 8.183 8.183 0 01-5.785-13.967l84.212-84.212h-45.701a8.182 8.182 0 010-16.364z" />
      <path d="M161.087 119.995a8.182 8.182 0 0116.364 0v81.817a8.181 8.181 0 01-8.181 8.181H38.363a8.182 8.182 0 01-8.182-8.181V70.906a8.182 8.182 0 018.182-8.182h81.816a8.182 8.182 0 110 16.363H46.544V193.63h114.543v-73.635z" />
    </svg>
  );
}

export default SvgIconExternalLink;
