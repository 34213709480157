/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExperience({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M210.182 154.349V49c0-5.523-4.478-10-10-10h-160c-5.523 0-10 4.477-10 10v121.13l115.354-10.113a24.644 24.644 0 01-.809-6.274c0-13.58 10.99-24.588 24.545-24.588 13.557 0 24.546 11.008 24.546 24.588 0 .691-.036 1.373-.092 2.049l16.456-1.443zM54.727 129.155h65.455v8.204H54.727v-8.204zm130.909-57.274H54.727v-8.296h130.909v8.296zm0 32.676H54.727v-8.188h130.909v8.188zm-33.985 69.89l-10.182 26.452 10.664-7.548 5.325 5.507 7.428-20.925a24.445 24.445 0 01-12.12-5.991l-1.115 2.505zm17.621-2.472c10.059 0 18.214-8.169 18.214-18.246 0-10.077-8.155-18.245-18.214-18.245s-18.213 8.168-18.213 18.245c0 10.077 8.154 18.246 18.213 18.246zm16.236.202a24.439 24.439 0 01-11.155 5.625l5.648 19.855 5.627-6.817 9.359 5.157-9.479-23.82z" />
    </svg>
  );
}

export default SvgIconExperience;
