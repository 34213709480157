/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMenuInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm29 41.917c-5.569 0-10.083 4.514-10.083 10.083S63.43 92.083 69 92.083h102c5.569 0 10.083-4.514 10.083-10.083S176.569 71.917 171 71.917H69zm0 38c-5.569 0-10.083 4.514-10.083 10.083S63.43 130.083 69 130.083h102c5.569 0 10.083-4.514 10.083-10.083s-4.514-10.083-10.083-10.083H69zm0 38c-5.569 0-10.083 4.514-10.083 10.083S63.43 168.083 69 168.083h102c5.569 0 10.083-4.514 10.083-10.083s-4.514-10.083-10.083-10.083H69z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconMenuInverse;
