import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import getSubDomainKey from './utils/getSubDomainKey';
import './i18n';

// Store
import store from './state/store';
import { fetchMainMenu } from './state/store/slices/mainMenuSlice';

import './index.scss';

const subDomainKey = getSubDomainKey();

store.dispatch(fetchMainMenu(subDomainKey));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
