/* eslint-disable max-len */
import * as React from 'react';

function SvgIconPhone({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M70.991 22.22c-5.298 3.64-15.105 11.19-21.015 16.796C6.608 80.153 118.115 241.58 172.489 217.58c7.487-3.305 18.259-9.439 23.666-12.922 5.408-3.482 5.447-4.313-.359-12.687-5.807-8.373-17.463-24.288-23.389-32.172-7.764-10.324-5.738-7.938-16.523-1.056-35.541 22.691-91.518-58.46-56.802-82.504 10.511-7.282 11.996-4.537 5.195-15.5-5.194-8.373-15.791-24.993-21.488-33.44-5.699-8.446-6.493-8.717-11.795-5.078h-.003" />
    </svg>
  );
}

export default SvgIconPhone;
