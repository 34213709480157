/* eslint-disable max-len */
import * as React from 'react';

function SvgIconInternet({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M164.087 87.877l5.608 22.354 4.916-22.354h6.503l-7.774 32.006h-6.857l-6.373-23.928-6.351 23.928h-7.008l-6.632-27.776-6.745 27.776h-6.856l-6.374-23.928-6.356 23.928h-7.008l-6.632-27.776-6.745 27.776h-6.851l-6.379-23.928-6.35 23.928h-7.009L59.17 87.877h6.614l4.827 21.988 5.855-21.988h7.684l5.608 22.354 4.91-22.354h11.088l4.826 21.988 5.85-21.988h7.684l5.608 22.354 4.916-22.354h11.081l4.826 21.988 5.856-21.988h7.684z" />
      <path
        fillRule="evenodd"
        d="M40.181 169.72c-5.523 0-10-4.477-10-10V48c0-5.523 4.478-10 10-10h160c5.523 0 10 4.477 10 10v111.72c0 5.523-4.477 10-10 10h-74.375v20.155h37.969v11.25H76.587v-11.25h37.969V169.72H40.181zm158.75-120.47h-157.5v109.22h157.5V49.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconInternet;
