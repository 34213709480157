/* eslint-disable max-len */
import * as React from 'react';

function SvgIconMilestone({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M210.182 193.627l-57.273-57.255s-1.6-1.599-4.091-2.677V30L95.636 62.728l45 27.692v43.1c-1.338.568-2.714 1.474-4.091 2.853L112 160.91l-8.883-8.429c-7.48-6.968-15.662.232-15.662.232L30.182 210l180-16.373z" />
    </svg>
  );
}

export default SvgIconMilestone;
