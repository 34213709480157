import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import PageHeader from '../PageHeader';
import PageContent from '../PageContent';
import PageFooter from '../PageFooter';
import {
  getSubDomainKey,
  isMainWebsite,
  isPrerender,
  person2Team,
} from '../../utils';

// Services
import ApiService from '../../services/ApiService';

// Store
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './Page.scss';

interface IPageData {
  meta: any,
  header: {
    title: string;
    subTitle: string;
    image: any
  };
  contactPerson: any,
  team: any;
}

const subDomainKey = getSubDomainKey();

const Page = () => {
  // @ts-ignore
  let { slug } = useParams();
  // const { hash } = useLocation();
  const mainMenu = useAppSelector(selectMainMenu);
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [data, setData] = useState<IPageData>({
    meta: {},
    header: {
      title: '',
      subTitle: '',
      image: null,
    },
    contactPerson: null,
    team: null,
  });
  const setDataAndPossiblyScroll = (data2) => {
    setData(data2);
    scrollToHash(scrolledRef, hash);
  };

  const getRedirectUrl = (currentUrl, forceSubDomainKey) => {
    if (subDomainKey === forceSubDomainKey) {
      return currentUrl;
    }

    const colonAndDoubleSlash = '://';
    let redirectUrl = currentUrl.replace(`${colonAndDoubleSlash}${subDomainKey}.`, colonAndDoubleSlash);

    if (!isMainWebsite(forceSubDomainKey)) {
      redirectUrl = redirectUrl.replace(colonAndDoubleSlash, `${colonAndDoubleSlash}${forceSubDomainKey}.`);
    }

    return redirectUrl;
  };

  const getPageData = (pageSlug) => {
    // eslint-disable-next-line consistent-return
    ApiService.get(`/pages?slug=${pageSlug}`)
      .then(response => {
        if (!response.data.length) {
          ApiService.get(`/pages?slug=${mainMenu.notFoundPage.slug}`)
            .then(notFoundPageResponse => {
              setDataAndPossiblyScroll(notFoundPageResponse.data[0]);
            });
        } else {
          const responseData = response.data[0];

          if (responseData.forceMenu) {
            ApiService.get(`/menus?id=${responseData.forceMenu}`)
              .then(response2 => {
                const forceSubDomainKey = response2.data[0].subDomainKey;

                if (subDomainKey === forceSubDomainKey || isPrerender()) {
                  setDataAndPossiblyScroll(responseData);
                } else {
                  window.location.href = getRedirectUrl(window.location.href, forceSubDomainKey);
                }
              });
          } else {
            setDataAndPossiblyScroll(responseData);
          }
        }
      });
  };

  useEffect(() => {
    // wait for mainMenu request to complete
    if (!mainMenu) {
      return;
    }

    if (!slug) {
      slug = mainMenu.homePage.slug;
    }

    getPageData(slug);
  }, [mainMenu, slug]);

  // show loader as long there is no site menu
  if (!mainMenu) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  // page data exists
  if (data.contactPerson) {
    data.team = person2Team(data.contactPerson);
  }

  return (
    <>
      <CookieConsentBox googleTagManagerId={mainMenu.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={data.header} mainMenu={mainMenu} meta={data.meta} />
      <div className="sw-page">
        <PageHeader data={data} />
        <PageContent data={data} />
        <PageFooter team={data.team} />
      </div>
    </>
  );
};

export default Page;
