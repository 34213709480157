import React from 'react';

import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import CardContent from '../CardContent';
import SectionWrapper from '../SectionWrapper';
import Carousel from '../Carousel';
import Icon from '../Icon';
import { formatUrl, isExternalLink } from '../../utils';

import './SectionContentCard.scss';

interface IProps {
  component: any;
}

const CardCarouselButtonIcon = ({
  to = null, name, title, ...rest
}) => {
  if (!to) {
    return (<></>);
  }

  const isExternal = isExternalLink(to);
  const formattedTo = formatUrl(to);

  return (
    <div className="link-icon">
      {
        isExternal && (
          <Link to={{ pathname: formattedTo }} title={title} target="_blank">
            <Icon name={name} {...rest} />
          </Link>
        )
      }

      {
        !isExternal && (
          <Link to={formattedTo} title={title}>
            <Icon name={name} {...rest} />
          </Link>
        )
      }
    </div>
  );
};

const CardCarouselContent = ({ card }) => (
  <div className="sw-card-carousel-content">
    <div className="header">
      {
        card.icon && (
          <div>
            <Icon name={card.icon} width="3rem" height="3rem" />
          </div>
        )
      }
      <div>
        <h3 className="h6">{card.title}</h3>
      </div>
    </div>
    <div className="content">
      <div className="strapi-markdown">
        <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
          {card.content}
        </ReactMarkdown>
      </div>
      <CardCarouselButtonIcon name="ExternalLink" to={card.linkUrl} title={card.linkText} />
    </div>
  </div>
);

const CardCarousel = ({ component }: IProps) => (
  <Carousel>
    {
      component.cards.map(c => (<CardCarouselContent key={c.id} card={c} />))
    }
  </Carousel>
);

const CardCarouselSectionWrapper = ({ component }: IProps) => (
  <SectionWrapper
    classes="sw-section-content-card-carousel"
    collapseBottom={component.collapseBottom}
    collapseTop={component.collapseTop}
    id={component.anchorId || `section-content-card-${component.id}`}
  >
    <CardCarousel component={component} />
  </SectionWrapper>
);

/**
 * @return {html}
 *
 * Available variants
 * ==================
 *
 * TitleOneColumn
 * TitleTwoColumn
 * TitleThreeColumn
 * NoTitleThreeColumn
 * NoTitleFourColumn
 * Carousel
 */
const SectionContentCard = ({ component }: IProps) => {
  let sectionGridClasses = 'grid-1fr-1fr2fr grid-gap';
  let cardGridClasses;

  switch (component.variant) {
    case 'OneColumn':
      cardGridClasses = 'grid-1x1fr';
      break;
    case 'TwoColumn':
      cardGridClasses = 'grid-1x1fr-2x1fr';
      break;
    case 'ThreeColumn':
      cardGridClasses = 'grid-1x1fr-2x1fr-3x1fr';
      break;
    case 'FullWidthThreeColumn':
      component.title = false;
      sectionGridClasses = 'grid-1fr';
      cardGridClasses = 'grid-1x1fr-2x1fr-3x1fr';
      break;
    case 'FullWidthFourColumn':
      component.title = false;
      sectionGridClasses = 'grid-1fr';
      cardGridClasses = 'grid-1x1fr-2x1fr-3x1fr-4x1fr';
      break;
    case 'Carousel':
      return (
        <CardCarouselSectionWrapper component={component} />
      );
    default:
      cardGridClasses = 'grid-1x1fr-2x1fr';
  }

  return (
    <SectionWrapper
      classes="sw-section-content-card"
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-content-card-${component.id}`}
    >
      <div className={`${sectionGridClasses} ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>

        <div className={cardGridClasses}>
          {
            component.cards.map(contentCard => (<CardContent key={contentCard.id} card={contentCard} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionContentCard;
