/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTeamEventsInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm74.647 51.57c-.782-1.068-1.21-2.278-1.368-3.504l-5.315 7.264-13.39 5.341c-.035-.002-.068-.011-.1-.021-.036-.01-.072-.022-.11-.022h-.019c-.595.004-15.467.093-15.662.12-1.739-.164-3.466.726-4.233 2.404l-14.068 30.824a4.218 4.218 0 107.677 3.504l6.797-14.893V132.459l-.008 26.955v4.467l-.001.714v.511a5.062 5.062 0 005.063 5.063 5.062 5.062 0 005.064-5.063v-30.177h3.692v30.177a5.062 5.062 0 005.063 5.063 5.062 5.062 0 005.063-5.063l-.013-67.027 13.438-5.36a4.21 4.21 0 001.842-1.429l3.85-5.262-3.262-4.458zm9.534-6.976a4.211 4.211 0 00-5.895-.914 4.216 4.216 0 00-.915 5.895l8.57 11.715a4.2 4.2 0 001.843 1.429l13.437 5.36V103.173l-.013 58.452v2.989l-.001.492a5.063 5.063 0 005.064 5.063 5.062 5.062 0 005.064-5.063v-30.178h3.692v30.178a5.062 5.062 0 005.063 5.063 5.062 5.062 0 005.063-5.063l-.009-52.519 6.798 14.893a4.219 4.219 0 107.676-3.504l-14.069-30.824c-.767-1.676-2.49-2.569-4.231-2.404-.098-.014-3.898-.033-7.758-.05l-.472-.001c-3.386-.014-6.702-.025-7.489-.026h-.174l-13.389-5.34-7.855-10.737zm-27.495 4.273c0-5.453-4.418-9.867-9.867-9.867a9.862 9.862 0 00-9.865 9.867 9.861 9.861 0 009.865 9.863c5.448 0 9.867-4.415 9.867-9.863zm66.359 0A9.862 9.862 0 00153.18 69c-5.448 0-9.867 4.415-9.867 9.867 0 5.448 4.419 9.863 9.867 9.863a9.861 9.861 0 009.865-9.863z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconTeamEventsInverse;
