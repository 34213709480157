const isPrerender = (subDomainKey = null) => {
  if (!subDomainKey) {
    const hostSubDomain = window.location.host.split('.')[0];
    return isPrerender(hostSubDomain);
  }

  return subDomainKey === 'prerender';
};

export default isPrerender;
