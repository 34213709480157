const formatUrl = (url) => {
  const isK8sProductionEnvironment = process.env.REACT_APP_K8S_ENV === 'production';
  const safeUrl = url ?? '';

  if (!isK8sProductionEnvironment) {
    return safeUrl.replace(/(syncwork\.de)($|[^v])/, '$1v$2')
      .replace(/[/]{2}(techbar.berlin)/, '//beta.$1');
  }

  return safeUrl;
};

export default formatUrl;
