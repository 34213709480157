/* eslint-disable max-len */
import * as React from 'react';

function SvgIconExclamationMark({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M100.839 148.998h39.239V25a5 5 0 00-5-5h-29.239a5 5 0 00-5 5v123.998zM140.078 216.567L100.839 220v-37.749h39.239v34.316z" />
    </svg>
  );
}

export default SvgIconExclamationMark;
