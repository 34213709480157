import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../../services/ApiService';
import Statuses from '../../types/Statuses';

interface IMainMenu {
  mainMenu: any;
  status: string;
  error: string;
}

const initialState: IMainMenu = {
  mainMenu: null,
  status: Statuses.idle,
  error: null,
};

export const fetchMainMenu = createAsyncThunk('navigation/fetchMainMenu', async (subDomainKey: string) => {
  const baseUrl = '/menus';
  const url = `${baseUrl}?subDomainKey=${subDomainKey}`;
  const response = await ApiService.get(url);
  const responseData = response.data[0];

  if (responseData.useMenuItemsFromAnotherMenu) {
    const secondUrl = `${baseUrl}?id=${responseData.useMenuItemsFromAnotherMenu}`;
    const secondResponse = await ApiService.get(secondUrl);
    const secondResponseData = secondResponse.data[0];

    responseData.menuItems = secondResponseData.menuItems;
    delete responseData.useMenuItemsFromAnotherMenu;
  }

  return responseData;
});

export const mainMenuSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMainMenu.pending, (state) => {
        state.status = Statuses.loading;
      })
      .addCase(fetchMainMenu.fulfilled, (state, action) => {
        state.status = Statuses.succeeded;
        // Add fetched mainMenu to the store
        state.mainMenu = action.payload;
      })
      .addCase(fetchMainMenu.rejected, (state, action) => {
        state.status = Statuses.failed;
        state.error = action.error.message;
      });
  },
});

export const selectMainMenu = state => state.navigation.mainMenu;
export const selectMainMenuTheme = state => state.navigation.mainMenu.theme;

export default mainMenuSlice.reducer;
