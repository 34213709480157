/* eslint-disable max-len */
import * as React from 'react';

function SvgIconFacebook({ title, titleId, ...props }) {
  // Source (modified with Incscape so that the F really cuts a hole into the circle):
  // https://commons.wikimedia.org/wiki/File:Facebook_icon_(black).svg

  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 30 30"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M 15 0 A 15 15 0 0 0 0 15 A 15 15 0 0 0 15 30 A 15 15 0 0 0 30 15 A 15 15 0 0 0 15 0 z M 17.300781 6.0996094 C 18.400781 6.0996094 19.399219 6.1992188 19.699219 6.1992188 L 19.699219 9.0996094 L 18 9.0996094 C 16.7 9.0996094 16.400391 9.6996094 16.400391 10.599609 L 16.400391 12.599609 L 19.5 12.599609 L 19.099609 15.800781 L 16.400391 15.800781 L 16.400391 23.900391 L 13.199219 23.900391 L 13.199219 15.800781 L 10.5 15.800781 L 10.5 12.599609 L 13.199219 12.599609 L 13.199219 10.300781 C 13.199219 7.6007812 14.900781 6.0996094 17.300781 6.0996094 z " />
    </svg>
  );
}

export default SvgIconFacebook;
