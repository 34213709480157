import React from 'react';

import './Loader.scss';

/**
 * @return {html}
 */
const SampleComponent = () => (
  <div className="sw-loader">
    <div className="loader">Loading...</div>
  </div>
);

export default SampleComponent;
