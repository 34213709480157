/* eslint-disable max-len */
import * as React from 'react';

function SvgIconContract({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M40.182 217.556V40.947C40.182 22 59.027 22 59.027 22h122.31c18.845 0 18.845 17.02 18.845 17.02v164.189l-160 14.347zm53.298-62.047v8.744h53.395v-8.744H93.48zm-17.743-17.944c-4.911 0-8.888 3.865-8.888 8.88 0 5.015 3.977 9.065 8.888 9.065 4.912 0 8.89-3.905 8.89-8.92 0-5.014-3.978-9.025-8.89-9.025zm17.743.213v8.745h80.116v-8.745H93.48zm0-26.868v8.745h53.395v-8.745H93.48zM75.737 92.966c-4.911 0-8.888 4.01-8.888 9.024 0 5.015 3.977 8.92 8.888 8.92 4.912 0 8.89-3.905 8.89-8.92 0-5.013-3.978-9.024-8.89-9.024zm17.743.214v8.744h80.116V93.18H93.48zm0-26.628v8.745h53.395v-8.744H93.48zM75.737 48.608c-4.911 0-8.888 3.933-8.888 8.948 0 5.015 3.977 8.997 8.888 8.997 4.912 0 8.89-3.905 8.89-8.92 0-5.014-3.978-9.025-8.89-9.025zm17.743.213v8.746h80.116V48.82H93.48" />
    </svg>
  );
}

export default SvgIconContract;
