import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import SectionWrapper from '../SectionWrapper';
import { camel2Kebab } from '../../utils';

import './SectionText.scss';

/**
 * @return {html}
 */
const SectionText = ({ component }) => {
  const color = component.color ? `color-${camel2Kebab(component.color)}` : '';
  const backgroundColor = component.backgroundColor ? `color-bg-${camel2Kebab(component.backgroundColor)}` : '';
  const wrapperClasses = `${color} ${backgroundColor}`;

  return (
    <SectionWrapper
      classes={`sw-section-text ${wrapperClasses}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-text-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div className="strapi-markdown">
          <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm]}>
            {component.richText}
          </ReactMarkdown>
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionText;
