import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

const ShareIconLinkedIn = ({ sharedUrl }) => {
  let linkedInShareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(sharedUrl)}`;
  const isForMobileScreen = useCheckMobileScreen();

  if (isForMobileScreen) linkedInShareUrl += '&mini=true';

  return (<ButtonIcon to={linkedInShareUrl} name="LinkedIn" title="LinkedIn" height="2rem" width="2rem" />);
};

export default ShareIconLinkedIn;
