/* eslint-disable max-len */
import * as React from 'react';

function SvgIconHeartPlus({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M199.597 54.008c-17.595-23.757-51.378-26.79-75.456-6.776A61.435 61.435 0 00120 51.011a61.157 61.157 0 00-4.142-3.779c-24.077-20.015-57.86-16.981-75.455 6.776-15.755 21.272-13.187 51.945 4.808 72.498h-.03l.386.403a61.561 61.561 0 003.018 3.152l72.18 75.405 71.712-76.463a62.429 62.429 0 001.83-1.951l.512-.546h-.03c17.995-20.553 20.563-51.226 4.808-72.498zm-42.472 68.868H131.25v25.876h-22.5v-25.876H82.874v-22.5h25.876V74.501h22.5v25.875h25.875v22.501" />
    </svg>
  );
}

export default SvgIconHeartPlus;
