/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTestTubesInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm76.005 35.649V60H70v5.649h5.724v97.332A17.307 17.307 0 0093.037 180a17.309 17.309 0 0017.313-17.271v-97.08h5.655zm54 0V60H124v5.649h5.655v91.146l.003.252a17.308 17.308 0 0017.31 17.016 17.308 17.308 0 0017.316-17.268V65.649h5.721zM82.003 66h22.002v51.999H82.003V66zm54 0h22.002v28.188h-22.002V66z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconTestTubesInverse;
