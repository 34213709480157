import * as React from 'react';

function SvgIconDataTheft({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 400 400"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g>
        <g>
          <path d="M235.283,165.471c-1.206,0.52-2.596,0.52-3.985,0.175c-1.906-0.345-3.463-1.385-4.336-3.12
            l-24.959,24.271c9.013,2.255,16.471,9.363,18.726,18.725h54.261l-39.006-40.396L235.283,165.471z"
          />
          <path d="M215.003,171.543l7.106-6.762c-3.461-0.871-6.582-2.255-9.538-4.161
            c-14.214-9.363-6.757-20.806,7.808-16.125c7.456,2.256,9.188,4.511,8.663,9.883c-0.166,1.386-0.691,2.946-0.691,4.336v2.426
            c0.351,1.216,1.391,2.251,3.297,2.601c1.215,0.345,2.08,0.17,2.945,0l1.565-1.214c1.38-1.731,1.555-5.027,2.08-7.803
            c1.556-8.492,0.166-14.909-10.577-19.241c-3.646-1.216-7.457-2.081-11.618-2.775c12.133-6.413,22.361-2.251,33.979,3.295
            c3.121-1.04,4.162-3.295,2.605-6.591c-12.659-8.318-25.832-10.573-40.397-5.372c10.229-9.533,21.148-10.404,33.979-7.282
            c4.853,1.04,5.544-5.202,2.256-6.242c-16.644-3.467-28.087-3.292-42.301,6.417c9.703-11.268,14.389-11.268,28.952-10.058
            c2.946-0.345,3.986-5.026,0-5.892c-18.899-3.986-26.696,3.466-40.571,14.389c-7.632,1.561-15.079,3.292-22.71,5.026
            c0,13.004,0,26.008,0,39.007c9.012,0.521,17.859,1.216,26.697,1.735C202.868,165.471,208.762,169.462,215.003,171.543z"
          />
          <polygon points="286.433,205.522 286.433,194.08 266.667,194.08 277.586,205.522" />
          <rect x="103.875" y="116.587" width="66.229" height="44.898" />
          <path d="M219.164,207.253c-1.905-10.918-11.618-19.24-23.054-19.24h-44.382v19.24v27.568v55.643
            c0,7.465,6.066,13.532,13.515,13.532h115.638c7.457,0,13.349-6.067,13.349-13.532v-83.211H219.164z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconDataTheft;
