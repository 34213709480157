/* eslint-disable max-len */
import * as React from 'react';

function SvgIconTarget({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M186.03 30l-28.979 28.982 2.073 13.118-51.629 51.637a16.73 16.73 0 00-5.984-1.1c-9.289 0-16.84 7.558-16.84 16.839 0 9.284 7.55 16.846 16.84 16.846 9.276 0 16.834-7.562 16.834-16.846 0-2.73-.654-5.311-1.813-7.594l51.172-51.18 13.142 2.076 28.986-28.983-20.549-3.249L186.03 30z" />
      <path d="M152.651 111.367a58.071 58.071 0 017.232 28.108c0 32.188-26.181 58.366-58.366 58.366S43.15 171.662 43.15 139.475c0-32.183 26.182-58.36 58.367-58.36 9.948 0 19.32 2.51 27.531 6.916l8.901-8.904a70.111 70.111 0 00-36.432-10.162c-38.885 0-70.517 31.63-70.517 70.51 0 38.888 31.632 70.523 70.517 70.523 38.875 0 70.516-31.634 70.516-70.523a70.127 70.127 0 00-10.511-36.98l-8.871 8.872z" />
      <path d="M131.919 132.08c.578 2.379.916 4.844.916 7.39 0 17.276-14.053 31.325-31.327 31.325-17.275 0-31.334-14.049-31.334-31.325 0-17.275 14.06-31.331 31.334-31.331 2.294 0 4.524.266 6.678.738l9.664-9.667a43.156 43.156 0 00-16.342-3.218c-23.974 0-43.48 19.507-43.48 43.479 0 23.977 19.506 43.485 43.48 43.485 23.974 0 43.473-19.508 43.473-43.485a43.307 43.307 0 00-3.458-16.992l-9.604 9.601z" />
    </svg>
  );
}

export default SvgIconTarget;
