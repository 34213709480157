import config from '../App.config';

const apiBaseUrl = () => {
  let baseUrl = '';

  if (config.api.host) {
    if (config.api.port) baseUrl = [config.api.scheme, '://', config.api.host, ':', config.api.port].join('');
    else baseUrl = [config.api.scheme, '://', config.api.host].join('');
  }

  if (config.api.relativeUrl) baseUrl += config.api.relativeUrl;

  return baseUrl;
};

export default apiBaseUrl;
