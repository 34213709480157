import React from 'react';

import SectionWrapper from '../SectionWrapper';
import ImageFeature from '../ImageFeature/ImageFeature';
import { camel2Kebab } from '../../utils';

import './SectionFeatureImage.scss';

/**
 * @return {html}
 */
const SectionFeatureImage = ({ component }) => {
  const theme = camel2Kebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-feature-image sw-section-feature-image-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-feature-image-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div>
          <ImageFeature image={component.featureImage} />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionFeatureImage;
