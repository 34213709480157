import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';

const ShareIconTwitter = ({ sharedUrl }) => {
  const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(sharedUrl)}`;

  return (<ButtonIcon to={twitterShareUrl} name="Twitter" title="Twitter" height="2rem" width="2rem" />);
};

export default ShareIconTwitter;
