import React from 'react';

import CardPersonnelGallery from '../CardPersonnelGallery';
import SectionWrapper from '../SectionWrapper';
import { camel2Kebab } from '../../utils';

import './SectionPersonnelGallery.scss';

/**
 * @return {html}
 */
const SectionPersonnelGallery = ({ component }) => {
  const theme = camel2Kebab(component.theme);

  return (
    <SectionWrapper
      classes={`sw-section-personnel-gallery sw-section-personnel-gallery-${theme}`}
      collapseBottom={component.collapseBottom}
      collapseTop={component.collapseTop}
      id={component.anchorId || `section-personnel-gallery-${component.id}`}
    >
      <div className={`grid-1fr-1fr2fr grid-gap ${!component.title ? 'untitled' : ''}`.trim()}>
        <div>
          {
            component.title && (
              <>
                <h2 className="h5">{component.title}</h2>
                {
                  component.subTitle && (<div className="h6">{component.subTitle}</div>)
                }
              </>
            )
          }
        </div>
        <div className="grid-personnel-gallery">
          {
            component.people.map(person => (<CardPersonnelGallery key={person.id} person={person} />))
          }
        </div>
      </div>
    </SectionWrapper>
  );
};

export default SectionPersonnelGallery;
