/* eslint-disable max-len */
import * as React from 'react';

function SvgIconDatenmanagementInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm115.883 63.42c4.778.07 24.114-.194 24.114-7.21 0-6.906-18.286-7.136-23.691-7.204l-.423-.006-.206.003-.219.002h-.003c-5.407.07-23.684.301-23.684 7.205 0 6.905 18.277 7.137 23.684 7.205l.428.006zm-33.79 28.604h-10.968v2.955h10.968v4.365l7.119-6.131-7.119-5.548v4.359zm33.334-25.88l.422.005c4.169.07 18.436-.133 24.085-4.677l.066 19.802-.272.233c-5.794 4.384-19.759 4.577-23.876 4.511l-.259-.004-.212-.003c-4.632-.069-17.86-.267-23.426-4.504h-.124l-.033-19.045-.027 18.899V91.496c5.472 4.39 19.021 4.582 23.656 4.647zm-.016 22.809l.438.006c4.169.07 18.436-.133 24.085-4.68l.066 19.8a11.6 11.6 0 01-.272.236c-5.794 4.381-19.759 4.574-23.876 4.507l-.434-.006c-4.596-.065-17.882-.252-23.463-4.501h-.124l-.033-19.044-.027 18.9v-19.868c5.466 4.388 18.991 4.583 23.64 4.65zm-.032 22.26l.47.008c4.169.069 18.436-.133 24.085-4.682l.066 19.804-.273.232c-5.793 4.383-19.759 4.578-23.876 4.509l-.455-.007c-4.617-.067-17.869-.258-23.442-4.502h-.123l-.033-19.044-.027 18.899v-19.863c5.453 4.376 18.93 4.577 23.608 4.646zm-71.68-39.946l-.016.001c-5.405.068-23.683.3-23.683 7.205 0 6.906 18.287 7.137 23.687 7.205l.423.005c4.78.069 24.115-.195 24.115-7.21 0-6.896-18.23-7.134-23.666-7.205l-.449-.006-.372.005H83.7zm.379 17.146l-.423-.006c-4.635-.065-18.184-.257-23.655-4.647v19.863l.028-18.899.03 19.046h.125c5.566 4.235 18.789 4.434 23.423 4.504l.474.007c4.116.065 18.082-.128 23.874-4.511 0 0 .252-.213.274-.234l-.064-19.802c-5.651 4.549-19.918 4.751-24.086 4.679zm0 22.81a53.172 53.172 0 00-.464-.007c-4.674-.07-18.16-.269-23.614-4.649v19.864l.028-18.898.03 19.043h.125c5.584 4.252 18.876 4.439 23.468 4.503l.43.006c4.115.068 18.081-.125 23.873-4.509 0 0 .252-.211.274-.233l-.064-19.801c-5.651 4.546-19.918 4.749-24.086 4.681z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconDatenmanagementInverse;
