/* eslint-disable max-len */
import * as React from 'react';

function SvgIconBirdInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40.004 30.008c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10V206.05L40.473 220.008h-.47v-190zm92.16 42.292l-1.084-.3L60 168l101.763-8.387a24.413 24.413 0 002.849-7.599c.368-1.894.573-4.132.615-6.716l.006-.459c.006-.695.001-1.413-.016-2.156l-.013-.499-.007-.252-.034-.33-.098-.98-.095-.967-.121-1.269-.115-1.246-.11-1.223-.078-.902-.075-.889-.071-.876-.068-.863-.086-1.13-.061-.833-.057-.82-.053-.806-.067-1.055-.046-.776-.029-.511-.027-.504-.026-.499-.036-.737-.022-.484-.031-.715-.018-.47-.017-.464-.023-.685-.013-.449-.017-.664-.01-.435-.008-.429-.004-.212-.008-.629-.005-.615-.001-.403v-.425l.001-.446.004-.44.006-.433.008-.428.009-.421.012-.416.013-.409.015-.403.009-.199.018-.394.02-.388.022-.382.012-.188.025-.373.026-.366.029-.361.031-.354.032-.348.035-.342.036-.336.038-.33.01-.084.01-.078.041-.321.011-.08.01-.078.044-.311.009-.06.014-.094.047-.302.049-.296.025-.146.052-.287.053-.281.056-.275.028-.135.058-.265.061-.26.062-.253.064-.247.066-.242.038-.133.03-.102.07-.229c.07-.226.144-.442.221-.65H180l-14.803-9.803c-.185-2.248-1.398-4.66-3.641-7.236l-.21-.238-.216-.24c-.255-.279-.522-.56-.801-.844l-.242-.243a40.686 40.686 0 00-.502-.49l-.26-.246a8.421 8.421 0 00-.132-.124l-.268-.247-.274-.249a50.01 50.01 0 00-.423-.374l-.289-.251a46.542 46.542 0 00-.294-.252l-.301-.252-.306-.254c-3.146-2.584-7.592-4.996-13.339-7.238l-.569-.22a103.141 103.141 0 00-3.283-1.19l-.624-.213c-.314-.106-.631-.212-.951-.317l-.646-.21c-.432-.14-.871-.278-1.315-.417l-.67-.206c-.337-.103-.677-.206-1.021-.308l-.691-.204-.7-.203a121.05 121.05 0 00-.353-.101l-.712-.201zm15.338 22.676c0-1.71 1.413-3.096 3.155-3.096 1.743 0 3.155 1.386 3.155 3.096 0 1.709-1.412 3.095-3.155 3.095-1.742 0-3.155-1.386-3.155-3.095z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconBirdInverse;
