/* eslint-disable max-len */
import * as React from 'react';

function SvgIconOutlook({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M97.681 126.014c0 12.426-7.555 22.5-16.875 22.5s-16.875-10.074-16.875-22.5c0-12.427 7.556-22.5 16.875-22.5 9.32 0 16.875 10.073 16.875 22.5z" />
      <path
        fillRule="evenodd"
        d="M131.431 69.758h73.125a5.627 5.627 0 015.625 5.625v90a5.627 5.627 0 01-5.625 5.625h-73.125v28.125a5.626 5.626 0 01-5.625 5.625c-.348 0-.697-.034-1.035-.102l-90-16.875a5.617 5.617 0 01-4.59-5.523V58.508a5.61 5.61 0 014.59-5.524l90-16.875c1.62-.338 3.342.124 4.624 1.192a5.61 5.61 0 012.036 4.332v28.125zm-78.75 56.25c0 18.607 12.623 33.75 28.125 33.75 15.503 0 28.125-15.143 28.125-33.75 0-18.608-12.622-33.75-28.125-33.75-15.502 0-28.125 15.142-28.125 33.75zm135.484-45h-56.734v12.78l22.174 14.096 34.56-26.876zm-56.734 78.75h67.5V86.88l-41.546 32.321a5.627 5.627 0 01-3.454 1.182 5.663 5.663 0 01-3.026-.878l-19.474-12.386v52.639z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconOutlook;
