/* eslint-disable max-len */
import * as React from 'react';

function SvgIconHeartPlusInverse({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        d="M40 30c0-5.523 4.477-10 10-10h140c5.523 0 10 4.477 10 10v176.043L40.469 220H40V30zm82.761 41.822c16.052-13.344 38.574-11.321 50.304 4.517 10.503 14.181 8.791 34.63-3.206 48.332h.02l-.341.364c-.397.442-.804.875-1.22 1.3l-47.808 50.976-48.12-50.27a41.178 41.178 0 01-2.012-2.102l-.257-.268h.02c-11.997-13.702-13.71-34.15-3.206-48.332 11.73-15.838 34.252-17.86 50.304-4.517.958.797 1.88 1.637 2.761 2.519a41.124 41.124 0 012.761-2.52zm4.739 50.429h17.25v-15H127.5v-17.25h-15v17.25H95.25v15h17.25v17.25h15v-17.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconHeartPlusInverse;
