import React from 'react';

import { apiBaseUrl, isExternalLink } from '../../utils';

import './Image.scss';

const imagePlaceholder = {
  name: 'PLACEHOLDER',
  url: '/images/placeholder.png',
};

/**
 * @return {html}
 */
const Image = ({
  image = imagePlaceholder,
  aspectRatio = '16:9',
  elevated = true,
  caption = '',
  objectFit = true,
  alternativeText = '',
  overlayComponent = null,
}) => {
  const split = aspectRatio.split(':');
  const paddingBottom = `${(parseInt(split[1], 10) / parseInt(split[0], 10)) * 100}%`;
  const url = (image.name === 'PLACEHOLDER' || isExternalLink(image.url)) ? image.url : apiBaseUrl() + image.url;

  return (
    <div className={`sw-image ${elevated ? 'sw-image-elevated' : ''}`.trim()}>
      <div
        className="aspect-ratio"
        style={{
          paddingBottom,
        }}
        title={alternativeText}
      >
        {
          overlayComponent && (
            <div className="overlay">
              {overlayComponent}
            </div>
          )
        }

        {
          objectFit ? (
            <div className="aspect-ratio-object">
              <img alt={alternativeText} title={caption} src={url} />
            </div>
          ) : (
            <div
              className="aspect-ratio-inside"
              style={{
                backgroundImage: `url(${url})`,
              }}
            />
          )
        }

      </div>
    </div>
  );
};

export default Image;
