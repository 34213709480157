/* eslint-disable max-len */
import * as React from 'react';

function SvgIconKnowledge({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M74.33 216.563l-.177-32.247c-.097-17.912-5.763-33.143-17.322-46.561C46.094 125.295 40.181 109.4 40.181 93c0-38.047 31.262-69 69.685-69 36.665 0 67.211 28.354 69.538 64.55.357 5.524 1.761 10.127 4.557 14.919l16.221 27.815-17.869 9.532v17.6c0 8.097-4.762 14.944-13.202 17.861l-10.545 3.642c-10.66 3.689-15.403 7.628-15.371 12.782l.106 17.749-68.971 6.113zm1.038-83.489a18.135 18.135 0 007.772 2.47l.345 3.934 6.119-.536-.345-3.932a18.11 18.11 0 007.224-3.781l3.05 2.555 3.948-4.697-3.075-2.575a18.027 18.027 0 002.387-7.726l4.053-.355-.534-6.108-4.105.359a18.066 18.066 0 00-3.748-7.075l2.683-3.194-4.705-3.94-2.706 3.218a18.113 18.113 0 00-7.6-2.37l-.367-4.21-6.12.536.37 4.21a18.13 18.13 0 00-7.074 3.649l-3.225-2.701-3.948 4.697 3.2 2.68a18.054 18.054 0 00-2.463 7.618l-4.102.358.535 6.108 4.053-.355a18.094 18.094 0 003.696 7.197l-2.58 3.068 4.705 3.94 2.557-3.042zm36.359-30.443a30.31 30.31 0 0016.991 5.409l.428 4.908 5.941-.52-.431-4.909a30.293 30.293 0 0015.794-8.277l3.815 3.203 3.833-4.567-3.842-3.223a30.296 30.296 0 005.224-16.95l5.121-.45-.521-5.94-5.163.454a30.289 30.289 0 00-8.233-15.564l3.413-4.07-4.567-3.83-3.436 4.091a30.29 30.29 0 00-16.718-5.224l-.468-5.37-5.941.52.471 5.37a30.302 30.302 0 00-15.557 8.048l-4.092-3.436-3.833 4.568 4.068 3.413a30.297 30.297 0 00-5.407 16.758l-5.163.451.52 5.941 5.118-.449a30.285 30.285 0 008.088 15.785l-3.223 3.842 4.567 3.833 3.203-3.815zm17.407-9.443c-7.703 0-14.291-6.146-15.002-14.276-.751-8.577 5.319-16.113 13.557-16.832a14.49 14.49 0 011.271-.055c7.703 0 14.289 6.145 15.003 14.277.75 8.575-5.32 16.109-13.558 16.83-.422.037-.846.056-1.271.056zm-44.455 32.066c-3.872 0-7.184-3.085-7.543-7.167-.377-4.303 2.676-8.085 6.817-8.447a7.24 7.24 0 01.64-.028c3.873 0 7.185 3.085 7.543 7.165.378 4.305-2.675 8.087-6.818 8.45a7.572 7.572 0 01-.639.028" />
    </svg>
  );
}

export default SvgIconKnowledge;
