import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const scrollToHash = (scrolledRef, hash) => {
  if (hash && !scrolledRef.current) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      scrolledRef.current = true;
    }
  }
};

/**
 * This component is only used to listen for certain effects that occur in the app and,
 * if necessary, to trigger a certain behaviour.
 * @returns {null}
 *
 */
const GlobalEffects = () => {
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  useEffect(() => scrollToHash(scrolledRef, hash));
  return null;
};

export { GlobalEffects, scrollToHash };
