import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { scrollToHash } from '../../global-effects';

// Components
import CookieConsentBox from '../CookieConsentBox';
import PageViewTracker from '../PageViewTracker';
import HeaderHelmet from '../HeaderHelmet';
import Loader from '../Loader';
import NewsItemPageContent from '../NewsItemPageContent';
import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import { person2Team } from '../../utils';

// Services
import ApiService from '../../services/ApiService';

// Store
import { selectMainMenu } from '../../state/store/slices/mainMenuSlice';
import { useAppSelector } from '../../state/hooks';

import './NewsItemPage.scss';

const buildData = (newsItem) => {
  const header = {
    title: newsItem.title,
    subTitle: newsItem.subTitle,
    image: newsItem.image,
    imageOverlay: newsItem.imageOverlay,
    theme: 'Syncwork',
  };

  return {
    header,
    ...newsItem,
  };
};

const NewsItemPage = () => {
  // @ts-ignore
  const { slug } = useParams();
  const mainMenu = useAppSelector(selectMainMenu);
  const scrolledRef = useRef(false);
  const { hash } = useLocation();

  const [data, setData] = useState(null);
  const setDataAndPossiblyScroll = (data2) => {
    setData(data2);
    scrollToHash(scrolledRef, hash);
  };

  useEffect(() => {
    ApiService.get(`/news-items?slug=${slug}`).then(response => {
      if (response.data.length) {
        setDataAndPossiblyScroll(buildData(response.data[0]));
      }
    });
  }, [slug]);

  if (!data) {
    return (<></>);
  }

  // show loader as long there is no site menu
  if (!mainMenu) {
    return (
      <div className="sw-page">
        <Loader />
      </div>
    );
  }

  // convert any instances of team === {} to null, this occurs when a team is deleted
  if (isEmpty(data.team)) {
    data.team = null;
  }

  if (data.contactPerson) {
    data.team = person2Team(data.contactPerson);
  }

  return (
    <>
      <CookieConsentBox googleTagManagerId={mainMenu.googleTagManagerId} />
      <PageViewTracker />
      <HeaderHelmet header={data.header} mainMenu={mainMenu} meta={data.meta} />
      <div className="sw-page">
        <PageHeader data={data} />
        <NewsItemPageContent data={data} />
        <PageFooter team={data.team} />
      </div>
    </>
  );
};

export default NewsItemPage;
