import React from 'react';

import ButtonIcon from '../ButtonIcon/ButtonIcon';

const ShareIconFacebook = ({ sharedUrl }) => {
  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(sharedUrl)}`;

  return (<ButtonIcon to={facebookShareUrl} name="Facebook" title="Facebook" height="2rem" width="2rem" />);

  // useEffect(() => {
  //   const scriptElement = document.createElement('script');
  //
  //   scriptElement.appendChild(
  //     document.createTextNode(`(function(d, s, id) {
  //               var js, fjs = d.getElementsByTagName(s)[0];
  //               if (d.getElementById(id)) return;
  //               js = d.createElement(s); js.id = id;
  //               js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
  //               fjs.parentNode.insertBefore(js, fjs);
  //               }(document, 'script', 'facebook-jssdk'));`),
  //   );
  //
  //   document.body.appendChild(scriptElement);
  //   return () => {
  //     document.body.removeChild(scriptElement);
  //   };
  // });

  // const handleOnClick = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //
  //   if (!FB) return;
  //
  //   // For programmers maintaining this: ask Jonas Dygd (Jonas.Dygd@syncwork.de) if you need rights to the Facebook App
  //
  //   FB.init({
  //     appId: '4052113971566285',
  //     status: true,
  //     xfbml: true,
  //     version: 'v2.7',
  //   });
  //
  //   FB.ui({
  //     method: 'share',
  //     href: sharedUrl,
  //   }, response => { });
  // };

  // return (<ButtonIcon name="Facebook" title="Facebook"  onClick={handleOnClick} />);
};

export default ShareIconFacebook;
