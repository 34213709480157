/* eslint-disable max-len */
import * as React from 'react';

function SvgIconXml({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M53.958 115.18l7.392 11.004 7.35-11.004H75L64.668 130.3l9.996 14.7h-6.3l-7.014-10.584L54.294 145h-6.342l9.996-14.7-10.332-15.12h6.342zM102.32 145v-19.572l-7.644 14.574h-3.234l-7.644-14.574V145h-5.796v-29.82h6.216l8.82 16.968 8.904-16.968h6.174V145h-5.796zM114.342 115.18V145h21v-5.082h-15.204V115.18h-5.796z" />
      <path
        fillRule="evenodd"
        d="M157.533 31.061l45.857 47.65c.62.646.967 1.506.967 2.4v115.055c0 7.628-6.223 13.834-13.871 13.834H86.691c-7.649 0-13.872-6.21-13.872-13.842V165H45.12c-5.722 0-10.378-4.654-10.378-10.375v-51.942c0-5.72 4.656-10.375 10.378-10.375H72.82V43.866C72.819 36.221 79.042 30 86.691 30h68.347c.941 0 1.842.383 2.495 1.061zm36.008 47.4l-37.645-39.116v39.117h37.645zM41.665 154.625a3.458 3.458 0 003.455 3.452h93.475a3.458 3.458 0 003.455-3.452v-51.942a3.458 3.458 0 00-3.455-3.452H45.12a3.458 3.458 0 00-3.455 3.452v51.942zm148.82 48.452c3.832 0 6.949-3.1 6.949-6.911V85.385h-45a3.462 3.462 0 01-3.461-3.462v-45H86.691c-3.832 0-6.95 3.114-6.95 6.943v48.442h58.854c5.722 0 10.378 4.654 10.378 10.375v51.942c0 5.721-4.656 10.375-10.378 10.375H79.742v31.158c0 3.815 3.117 6.919 6.949 6.919h103.794z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconXml;
