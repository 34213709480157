import React from 'react';

import Icon from '../Icon';

import './CardIconCaption.scss';

/**
 * @return {html}
 */
const CardIconCaption = ({ card }) => (
  <div className="sw-card-icon-caption">
    <div className="icon">
      <Icon name={card.icon} width="4rem" height="4rem" />
    </div>
    <div className="caption">
      {card.title}
    </div>
  </div>
);

export default CardIconCaption;
