/* eslint-disable max-len */
import * as React from 'react';

function SvgIconSecurity({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      viewBox="0 0 240 240"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M40.181 109.526c0 79.393 80 106.506 80 106.506s80-27.113 80-106.506V43l-79.999-20-80 20v66.526" />
    </svg>
  );
}

export default SvgIconSecurity;
